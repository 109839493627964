import store from '~/store'

export default async (to, from, next, vm) => {
  if (to.params.event) {
    try {
      store.dispatch('permission/isAttendee', { event: to.params.event, to: to.name }).then((data) => {
        if(to.name === 'event-lobby.splash-screen') {
          if (data.is_splash_screen) {
            next()
          } else if(data.is_lobby) {
            next({ name: 'event-lobby.lobby', params: { event: to.params.event } })
          }
          else {
            next({ name: 'event-lobby.schedule', params: { event: to.params.event } })
          }
        }
        else if(to.name === 'event-lobby.lobby') {
          if(data.role == 'attendee' && data.is_waiting){
            store.dispatch('permission/showWaitingPopup', data)
            return false
          }
          else {
            if(data.is_lobby){
              next()
            }
            else {
              next({ name: 'event-lobby.schedule', params: { event: to.params.event } })
            }
          }
        } else if(to.name == 'event.event-steps' && data.skip_steps){
          if (data.is_splash_screen) {
            next({ name: 'event-lobby.splash-screen', params: { event: to.params.event } })
          } else if(data.is_lobby) {
            next({ name: 'event-lobby.lobby', params: { event: to.params.event } })
          }
          else {
            next({ name: 'event-lobby.schedule', params: { event: to.params.event } })
          }
        } else if(data.role == 'attendee'){
          if (data.is_waiting) {
            if (to.name == 'event.detail') {
              next()
            } else {
              store.dispatch('permission/showWaitingPopup', data)
              return false
            }
          } else {
              next()
          }
        } else {
          next()
        }
      })
    } catch (e) {
      console.log(e)
    }
  } else {
    next()
  }
}
