import axios from 'axios'
import mediaHandler from '~/plugins/media/mediaHandler'
import {isEmpty} from "lodash";
const state = {
  singlePublicEvent: {
    schedule: {},
    attachments: {},
    sponsors: {}
  }
}

const getters = {
  singlePublicEvent: state => state.singlePublicEvent,
}

const mutations = {
  SET_SINGLE_EVENT (state, event) {
    state.singlePublicEvent = event
  }
}

const actions = {
  getPublicEvent: (context, { id, callback }) => {
    return axios.get(`public-event/${id}`)
      .then(({ data }) => {
        data.media = mediaHandler.getAllMediaForModel('Event', data.attachments)
        const backgroundImages = data.advanced_graphics_choice['attachments'] ?? []
          if(isEmpty(backgroundImages)){
            data.advanced_graphics_choice.background_image = {}
          } else {
            data.advanced_graphics_choice.background_image = mediaHandler.getAllMediaForModel('EventGraphicChoice', backgroundImages)
          }
        context.commit('SET_SINGLE_EVENT', data)
        if (typeof callback === 'function') {
          callback(data.data)
        }
        return data.data
      })
      .catch((error) => {
        console.log(error)
      });
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
