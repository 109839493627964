import awsParameters from '~/plugins/config/awsParameters'
import axios from 'axios'
import Vue from 'vue'
import EventBus from '~/plugins/event-bus';

const bucketName = awsParameters.aws.bucket_name
const bucketRoot = awsParameters.aws.bucket_root // "file-store/dev"
const presignedUrl = awsParameters.aws.presigned_url
/**
 * Generate presigned URL
 * Create a request object to generate a pre-signed upload url for our S3 bucket
 */
const generatePresignedUrl = (ownerId, mimeType, fileName, uploadingBlogMedia) => {
  const sanitizedFilename = (fileName) => {
    return Date.now() + '-' + encodeURI(fileName.replace(/[^a-zA-Z0-9.]/g, '-'))
  }

  const url = uploadingBlogMedia ? 'blogs' : `${ownerId}`
  const objectName = `${bucketRoot}/${url}/${sanitizedFilename(fileName)}` // Create unique filename prefixing with time
  
  return {
    url: presignedUrl,
    config: {
      method: 'get',
      mode: 'cors',
      headers: {
        bucket: bucketName,
        payloadkey: objectName,
        payloadmime: mimeType
      }
    },
    objectName: objectName
  }
}
/**
 * **
 * Request a secure S3 upload url - we get a payload specific url returned from AWS
 *
 * @param ownerId - media owner userId
 * @param mimeType - payload mimetime
 * @param fileName _ payload filename
 *
 * @returns {Promise<{msg: string, success: boolean, error}|{success: boolean, s3ObjectName: string, url: string}>}
 * Success : url = upload url, s3ObjectName is the generated filename and path for s3 to store in our media item
 */
const getUploadUrl = async (ownerId, mimeType, fileName, uploadingBlogMedia) => {
  //
  // Get presigned url endpoint
  const upLoadObject = generatePresignedUrl(ownerId, mimeType, fileName, uploadingBlogMedia)
  //
  // Request upload url from AWS
  // Calls our lambda functions and gets a secure upload url for our S3 bucket
  try {
    const resp = await axios(upLoadObject.url, upLoadObject.config)
    return {
      success: true,
      url: resp.data.uploadURL,
      s3ObjectName: upLoadObject.objectName
    }
  } catch (error) {
    return {
      success: false,
      msg: 'upload url request failed',
      error: error
    }
  }
}
/**
 *
 * @param uploadResp - {url : S3 secure upload url, s3ObjectName}
 * @param file - file object to be saved
 * @returns {Promise<{msg: string, success: boolean, error}|{resp: AxiosResponse<any>, success: boolean}>}
 */
const uploadFileToS3 = async ({ uploadResp, fileObject }) => {
  //
  // Create eventbus to handle upload progress
  const uploadProgress = new Vue()

  try {
    const resp = await axios(uploadResp.url, {
      method: 'put',
      mode: 'cors',
      data: fileObject,
      onUploadProgress: (progressEvent) => {
        // TODO: How to get ref from the function arguments
        // uploadProgress.$emit('upload-progress', { progress: progressEvent })
        EventBus.$emit('upload-progress', { progress: progressEvent })
      }
    })
    return {
      success: true,
      s3ObjectName: uploadResp.s3ObjectName,
      progressEvent: uploadProgress,
      msg: '',
      error: null,
      resp
    }
  } catch (error) {
    return {
      success: false,
      s3ObjectName: '',
      progressEvent: '',
      msg: 'media upload failed',
      error: error,
      resp: null
    }
  }
}
/**
 *  Generate presigned url
 *  request secure upload url from aws
 *  upload asset to s3 bucket with secure url
 * @param ownerId
 * @param mimeType
 * @param fileName
 * @param file
 * @returns {Promise<{msg: string, success: boolean, error}|{resp: AxiosResponse<*>, success: boolean}|{success: boolean, s3ObjectName: string, url: string}>}
 */
export const uploadToS3 = async ({ ownerId, mimeType, fileName, fileObject, uploadingBlogMedia }) => {
  const uploadUrlResponse = await getUploadUrl(ownerId, mimeType, fileName, uploadingBlogMedia)
  if (!uploadUrlResponse.success) {
    return uploadUrlResponse
  }
  return uploadFileToS3({ uploadResp: uploadUrlResponse, fileObject: fileObject })
}
