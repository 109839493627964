import Vue from 'vue'
import axios from 'axios'

const state = {
  joined: false,
  attendees: {},
  attendeeCurrentRoom: '',
  rooms: {},

  mediaDevices: {
    camera: null,
    microphone: null
  },
  videoCall: {
    sessionId: null,
    bubbleId: null,
    bubbleJoined: 0,
    bubbleOwner: null,
    bubblePrivate: 0,
    bubbleUsers: [],
    localMediaStream: {}
  },
  webrtcToken: null,
}

const getters = {
  joined: state => state.joined,
  attendeeCurrentRoom: state => state.attendeeCurrentRoom,
  webrtcToken: state => state.webrtcToken,
  attendees: state => state.attendees,
  rooms: state => state.rooms,
  getRoomUsers: (state) => (roomId) => {
    return state.rooms[roomId]
  },
  getRoomUser: (state) => (userId) => {
    return state.attendees[userId]
  },
  getVideoCallParameters: (state) => {
    return state.videoCall
  },
  getVideoCallBubbleId: (state) => {
    return state.videoCall.bubbleId
  },
  getVideoCallBubbleOwner: (state) => {
    return state.videoCall.bubbleOwner
  },
  getVideoCallUsers: (state) => {
    return state.videoCall.bubbleUsers
  },
  getVideoCallLocalMediaStream: (state) => {
    return state.videoCall.localMediaStream
  },
  getMediaDeviceCamera: (state) => {
    return state.mediaDevices.camera
  },
  getMediaDeviceMicrophone: (state) => {
    return state.mediaDevices.microphone
  }
}

const mutations = {
  setJoined: (state, flag) => {
    state.joined = flag
  },
  setWebrtcToken: (state, webrtcToken) => {
    state.webrtcToken = webrtcToken
  },
  setAttendee: (state, attendee) => {
    const userId = attendee.id
    if(!state.attendees.hasOwnProperty(userId)){
      Vue.set(state.attendees, userId, attendee);
    }
  },
  setAttendeeCurrentRoom: (state, currentRoom) => {
    state.attendeeCurrentRoom = currentRoom
  },
  removeAttendeeFromRoom: (state, attendee) => {
    const room = attendee.room
    const userId = parseInt(attendee.user)
    if(!state.rooms.hasOwnProperty(room)) {
      Vue.set(state.rooms, room, []);
    }
    if(state.rooms[room].includes(userId)){
      const index = state.rooms[room].indexOf(userId);
      state.rooms[room].splice(index, 1)
    }
  },
  setRoomAttendee: (state, attendee) => {
    const room = attendee.room
    if(!state.rooms.hasOwnProperty(room)) {
      Vue.set(state.rooms, room, []);
    }
    if(!state.rooms[room].includes(attendee.user)){
      state.rooms[room].push(attendee.user)
    }
  },
  setAttendeeStatus: (state, data) => {
    if(state.attendees.hasOwnProperty(data.userId)){
      state.attendees[data.userId]['user_status'] = data.status
    }
  },
  setVideoCallBubbleId: (state, bubbleId) => {
    state.videoCall.bubbleId = bubbleId;
  },
  setVideoCallBubbleOwner: (state, userId) => {
    state.videoCall.bubbleOwner = userId
  },
  setVideoCallSessionId: (state, sessionId) => {
    state.videoCall.sessionId = sessionId
  },
  setVideoCallJoined: (state, value) => {
    state.videoCall.bubbleJoined = value
  },
  setVideoCallUser: (state, userId) => {
    if(!state.videoCall.bubbleUsers.includes(userId)){
      state.videoCall.bubbleUsers.push(userId)
    }
  },
  setMediaDeviceCamera: (state, deviceId) => {
    state.mediaDevices.camera = deviceId
  },
  setMediaDeviceMicrophone: (state, deviceId) => {
    state.mediaDevices.microphone = deviceId
  },
  setVideoCallClearParameters: (state) => {
    state.videoCall.sessionId = null,
    state.videoCall.bubbleId = null,
    state.videoCall.bubbleJoined = 0,
    state.videoCall.bubbleOwner = null,
    state.videoCall.bubblePrivate = 0,
    state.videoCall.bubbleUsers = []
    state.videoCall.localMediaStream = {}
    state.videoCall.peerConnections = {}
  },
  setVideoCallLocalMediaStream: (state, mediaStream) => {
    state.videoCall.localMediaStream = mediaStream
  },
  storeAttendee: (state, attendee) => {
    const userId = attendee.id
    if(!state.attendees.hasOwnProperty(userId) || (state.attendees[userId].is_network_visible != attendee.is_network_visible)){
      Vue.set(state.attendees, userId, attendee);
    }
  },
  removeVideoCallUser: (state, user) => {
    var index = state.videoCall.bubbleUsers.findIndex(c => c = user);
    state.videoCall.bubbleUsers.splice(index, 1);
  },
  removeUser: (state, userObj) => {
    if(state.attendees.hasOwnProperty(userObj.user)){
      Vue.delete(state.attendees, userObj.user);
    }

    for (const room in state.rooms) {
      if(userObj.user in state.rooms[room]){
        try {
          Vue.delete(state.rooms[room].userObj.user)
        } catch (err) {console.log(err) }
      }
    }


  }
}

const actions = {
  setJoined: (context, flag) => {
    context.commit('setJoined', flag)
  },
  setAttendeeCurrentRoom: (context, currentRoom) => {
    context.commit('setAttendeeCurrentRoom', currentRoom)
  },
  setWebrtcToken: (context, webrtcToken) => {
    context.commit('setWebrtcToken', webrtcToken)
  },
  async setAttendee (context, attendee) {
    try {
      const resp = await axios.get('webrtc/user/' + attendee.user + '/info-query')
      context.commit('setAttendee', resp.data.user)
    } catch (err) {
      console.log(err)
    }
  },
  storeAttendee: (context, attendee) => {
    context.commit('storeAttendee', attendee)
  },
  removeAttendeeFromRoom: (context, attendee) => {
    context.commit('removeAttendeeFromRoom', attendee)
  },
  setRoomAttendee: (context, attendee) => {
    context.commit('setRoomAttendee', attendee)
  },
  setAttendeeStatus: (context, data) => {
    context.commit('setAttendeeStatus', data)
  },
  setVideoCallBubbleId: (context, bubbleId) => {
    context.commit('setVideoCallBubbleId', bubbleId);
  },
  setVideoCallBubbleOwner: (context, userId) => {
    context.commit('setVideoCallBubbleOwner', userId)
  },
  setVideoCallSessionId: (context, sessionId) => {
    context.commit('setVideoCallSessionId', sessionId)
  },
  setVideoCallJoined: (context, value) => {
    context.commit('setVideoCallJoined', value)
  },
  setVideoCallUser: (context, user) => {
    context.commit('setVideoCallUser', user)
  },
  setMediaDeviceCamera: (context, deviceId) => {
    context.commit('setMediaDeviceCamera', deviceId)
  },
  setMediaDeviceMicrophone: (context, deviceId) => {
    context.commit('setMediaDeviceMicrophone', deviceId)
  },
  setVideoCallClearParameters: (context) => {
    context.commit('setVideoCallClearParameters')
  },
  setVideoCallLocalMediaStream: (context, mediaStream) => {
    context.commit('setVideoCallLocalMediaStream', mediaStream)
  },
  storeAttendee: (context, attendee) => {
    context.commit('storeAttendee', attendee)
  },
  removeVideoCallUser: (context, user) => {
    context.commit('removeVideoCallUser', user)
  },
  removeUser: (context, user) => {
    context.commit('removeUser', user)
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
