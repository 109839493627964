<template>
  <div class="text-center">
    <v-dialog
      v-model="welcome_tour_dialog"
      width="750"
      persistent
    >
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-stepper v-model="welcome_step">
          <v-stepper-items>
            <v-stepper-content step="1" class="pa-0">
              <v-card class="event-modal__wrap ra">
                <div class="ra__form-heading">
                  <v-card-title class="ra__modal-title">
                    <h3 class="ra__modal-title--heading">
                      {{ hasEvent ? 'Welcome to '+ eventName : $t('welcome_to_reattendance') }}
                    </h3>
                  </v-card-title>
                </div>
                <div class="welcome__modal-img">
                  <RaMediaDisplay
                    v-if="hasEvent"
                    location="primary"
                    :media="eventImage"
                    :sizes="sizes"
                  />
                  <v-img v-else src="/img/dashboard/welcome_popup.jfif"></v-img>
                </div>
                <v-card-actions>
                  <v-btn
                    large
                    depressed
                    class="btn-primary welcome__modal-btngroup"
                    @click="welcome_step = 2"
                  >
                    {{ $t("lets_setup") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="2" class="pa-0">
              <v-card>
                <div class="ra__form-heading">
                  <v-card-title class="ra__modal-title">
                    <h3 class="ra__modal-title--heading">
                      {{ $t('timezone_setup') }}
                    </h3>
                  </v-card-title>
                </div>
                <v-card-text class="timezone__description">
                  <h4 class="timezone__description--heading">{{ $t("set_your_timezone") }}</h4>
                  {{ $t("choose_timezone_paragraph") }}
                </v-card-text>
                <v-container>
                  <v-row align="center" class="justify-center">
                    <v-col cols="12" sm="8">
                      <timezone-autocomplete
                        v-model="current_user.time_zone_id"
                        required
                      />
                    </v-col>
                  </v-row>
                </v-container>

                <v-card-actions>
                  <v-btn
                    v-if="!is_open_from_dashboard_btn"
                    large
                    depressed
                    class="btn-primary welcome__modal-btngroup"
                    :disabled="!hasTimeZoneID"
                    @click="welcome_step = 3"
                  >
                    {{ $t("next_step") }}
                  </v-btn>
                  <v-btn
                    v-else
                    large
                    depressed
                    :loading="isSubmitting"
                    :disabled="!hasTimeZoneID"
                    @click="finishTour"
                    class="btn-primary welcome__modal-btngroup"
                  >
                    {{ $t("finish") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
            <v-stepper-content step="3" class="pa-0">
              <v-card>
                <div class="ra__form-heading">
                  <v-card-title class="ra__modal-title">
                    <h3 class="ra__modal-title--heading">
                      Business Card
                    </h3>
                  </v-card-title>
                </div>
                <v-card-text class="timezone__description pa-0">
                  <h4 class="timezone__description--heading pb-0">{{ 'My Public Business Card Details:' }}</h4>
                </v-card-text>
                <v-container class="pt-0 px-5 px-md-8">
                  <v-form ref="businessForm" v-model="isFormValid" >
                    <v-row>
                      <v-col cols="12" style="min-height: 180px" class="d-flex align-center">
                        <RaUserProfileImage />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="current_user.business_card.first_name"
                          label="First Name"
                          counter="50"
                          maxLength="50"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="current_user.business_card.last_name"
                          label="Last Name"
                          counter="50"
                          maxLength="50"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="current_user.business_card.job_title"
                          label="Job Title"
                          counter="50"
                          maxLength="50"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="current_user.business_card.email_address"
                          label="Email"
                          :rules="emailRules"
                          counter="200"
                          maxLength="200"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="current_user.business_card.mobile_number"
                          label="Phone"
                          counter="50"
                          maxLength="50"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="current_user.business_card.company_name"
                          label="Company"
                          counter="50"
                          maxLength="50"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="current_user.business_card.tagline"
                          label="Tagline"
                          counter="100"
                          maxLength="100"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="current_user.business_card.company_website"
                          :rules="urlRules"
                          label="Website"
                          counter="200"
                          maxLength="200"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model="current_user.business_card.bio"
                          rows="2"
                          auto-grow
                          label="Biography"
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
                <v-card-actions>
                    <v-btn
                      large
                      depressed
                      class="btn-primary welcome__modal-btngroup"
                      @click="welcome_step = 4"
                    >{{ $t("next_step") }}
                    </v-btn>
                  </v-card-actions>
              </v-card>
            </v-stepper-content>
            <v-stepper-content step="4" class="pa-0">
              <v-card>
                <div class="ra__form-heading">
                  <v-card-title class="ra__modal-title">
                    <h3 class="ra__modal-title--heading">
                      {{ $t('networking') }}
                    </h3>
                  </v-card-title>
                </div>
                <v-card-text class="timezone__description">
                  <h4 class="timezone__description--heading">{{ $t('would_you_like_network_with_others') }}</h4>
                </v-card-text>
                <v-container>
                  <v-col>
                    <div class="d-flex align-center">
                      <p class="text-grey1 pr-3">{{ $t('can_i_network_with_others') }}</p>
                      <v-switch id="v-step-14" v-model="current_user.is_network_visible" inset color="#199ED9" class="mt-2" />
                    </div>
                  </v-col>
                  <v-col>
                    <p v-if="current_user.is_network_visible">
                      {{ $t('networking_switch_on') }}
                    </p>
                    <p v-else>
                      {{ $t('networking_switch_off') }}
                    </p>
                  </v-col>
                </v-container>
                <v-card-actions>
                  <v-btn
                    large
                    v-if="hasEvent && !skipAllowMarking"
                    depressed
                    class="btn-primary welcome__modal-btngroup"
                    @click="welcome_step = 5"
                    :loading="isBusy"
                  >{{ $t("next_step") }}
                  </v-btn>
                  <v-btn
                    v-else
                    large
                    depressed
                    :loading="isSubmitting"
                    @click="finishTour"
                    class="btn-primary welcome__modal-btngroup"
                  >
                    {{ $t("finish") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
            <v-stepper-content step="5" class="pa-0">
              <v-card>
                <div class="ra__form-heading">
                  <v-card-title class="ra__modal-title">
                    <h3 class="ra__modal-title--heading">
                      {{ $t('last_step') }}
                    </h3>
                  </v-card-title>
                </div>
                <v-card-text>
                  <v-container class="px-0" fluid>
                    <h4 class="welcome__modal-headline">{{ $t("create_own_event", { event: eventName }) }}</h4>
                    <v-radio-group
                      v-model="current_user.welcome_polls.create_own_event"
                      required
                    >
                      <v-radio
                        :label="'Yes'"
                        :value="true"
                      ></v-radio>
                      <v-radio
                        :label="'No'"
                        :value="false"
                      ></v-radio>
                    </v-radio-group>
                  </v-container>
                </v-card-text>
                <v-card-actions class="welcome__modal-btngroup--spacing">
                  <v-btn
                    large
                    depressed
                    :loading="isSubmitting"
                    class="btn-primary"
                    @click="finishTour('last')"
                    :disabled="hasCheckedLastStep"
                  >
                    {{ $t("finish") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import axios from 'axios'
import TimezoneAutocomplete from './TimezoneAutocomplete'
import RaMediaDisplay from '~/components/media/RaMediaDisplay'
import mediaHandler from '~/plugins/media/mediaHandler'
import RaUserProfileImage from '~/components/media/RaUserProfileImage'
export default {
  name: 'WelcomeModal',
  components: {  RaMediaDisplay, TimezoneAutocomplete, RaUserProfileImage },
  props: {
    isFromDashboard: {
      default: false
    }
  },
  data () {
    return {
      e1: 1,
      valid: '',
      welcome_step: 1,
      model: null,
      preferences: [
        { label: this.$t('like_to_host_event'), value: 'event_owner' },
        { label: this.$t('like_to_attend_event'), value: 'event_attendee' },
        { label: this.$t('like_to_host_and_attend_event'), value: 'both' }
      ],
      events_per_year: [
        { label: this.$t('just_one_event'), value: 'one_event' },
        { label: this.$t('multiple_event_per_year'), value: 'multiple_event' },
        { label: this.$t('dont_know_yet'), value: 'none' }
      ],
      current_user: {
        welcome_polls: {}
      },
      isSubmitting: false,
      welcome_tour_dialog: false,
      is_open_from_dashboard_btn: false,
      isBusy: false,
      isFormValid: false,
      emailRules: [
        (v) =>  !v || /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/.test(v) || 'E-mail must be valid'
      ],
      urlRules: [
        (value) => !value || /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(value) || "URL is not valid",
      ],
      sizes: '100vw'
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('events', ['singleEvent']),
    // welcomeTourDialog () {
    //   return !this.current_user.is_tour_completed
    // },
    hasTimeZoneID () {
      return this.current_user.time_zone_id > 0
    },
    hasUserPreference () {
      return !this.current_user.welcome_polls.preference > 0
    },
    hasCheckedLastStep () {
      return (this.current_user.welcome_polls.create_own_event === undefined)
    },
    isFirstNameNotSet () {
      return (this.current_user.first_name === null || this.current_user.first_name === '' || this.current_user.first_name === '-')
    },
    isLastNameNotSet () {
      return (this.current_user.last_name === null || this.current_user.last_name === '' || this.current_user.last_name === '-')
    },
    hasEvent () {
      return this.singleEvent && this.singleEvent.id
    },
    skipAllowMarking () {
      return this.singleEvent && this.singleEvent.skip_allow_marketing
    },
    eventName () {
     return this.hasEvent ? this.singleEvent.name : ''
    },
    eventImage () {
      return mediaHandler.getAllMediaForModel('Event', this.singleEvent.attachments)
    }
  },
  methods: {
    ...mapActions('events', ['getEvent']),
    ...mapActions('auth', ['fetchUser']),
    finishTour (step) {
      const user = this.current_user
      user.is_tour_completed = true
      this.isSubmitting = true
      axios.put(`user/${this.current_user.id}`, user).then(response => {
        this.welcome_tour_dialog = false
        this.fetchUser()
        this.$root.$emit('welcome-modal-close')
        // if (step =='last') {
        //   this.storeWelcomeSalesForce(user)
        // }
      }).catch(error => {
        console.error(' Dashboard Error ' + error)
      }).finally(response => {
        this.isSubmitting = false
        this.showLoader(false)
      })
    },
    validateNameDetails () {
      if (this.$refs.form.validate()) {
        this.welcome_step = 3
      }
    },
    // storeWelcomeSalesForce (user) {
    //   const address = `${user.timezone.location_name},${user.timezone.timezone.country_name}`
    //   const userData = {first_name:user.first_name,last_name:user.last_name,email:user.email,address:address,welcome_polls:user.welcome_polls }
    //   axios.post('salesforce/add-welcome-data',userData).then(response => {
    //   }).catch(error => {
    //     console.error(' Dashboard Error ' + error)
    //   })
    // }
  },
  async created() {
    this.current_user = JSON.parse(JSON.stringify(this.user))
    this.current_user.time_zone_id = this.user.time_zone_id > 0 ? this.user.time_zone_id : null
    this.welcome_tour_dialog = this.user.is_tour_completed && (!this.isFirstNameNotSet || !this.isLastNameNotSet) ? false : true

    this.$root.$on('show-timezone-popup', (payload) => {
      this.welcome_tour_dialog = payload.showTimezone
      this.welcome_step = payload.step
      this.is_open_from_dashboard_btn = payload.openDialog
    })
    if (this.$route.params && this.$route.params.event) {
      await this.getEvent({id: this.$route.params.event})
    }
  },
  beforeDestroy(){
    this.$root.$off('show-timezone-popup', (payload) => {
      this.welcome_tour_dialog = payload.showTimezone
      this.welcome_step = payload.step
      this.is_open_from_dashboard_btn = payload.openDialog
    })
  }
}
</script>

<style scoped>

</style>
