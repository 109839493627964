import axios from 'axios'
import * as types from '../mutation-types'
import Bugsnag from '@bugsnag/js'

// state
const state = {
  user: null,
  token: localStorage.token,
  userToken: localStorage.userToken
}

// getters
const getters = {
  user: state => state.user,
  token: state => state.token,
  userToken: state => state.userToken,
  check: state => state.user !== null
}

// mutations
const mutations = {
  UPDATE_USER_MEDIA_ATTACHMENT: (state, attachments) => {
    state.user = { ...state.user, attachments: attachments }
  },
  DELETE_USER_MEDIA_ATTACHMENT: (state) => {
    state.user = { ...state.user, attachments: null }
  },
  [types.SAVE_TOKEN] (state, { token, token_id }) {

    state.token = token
    localStorage.token = token

    let d = new Date();
    d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = "token_id=" + token_id + ";" + expires + ";path=/"
  },

  /**
   * @description mutation for saving the user token state
   * @param state
   * @param token
   */
  [types.SAVE_USER_TOKEN] (state, token){
    state.userToken = token
    localStorage.userToken = token
  },

  [types.SWITCHED_USER_LOGOUT] (state) {
    state.user = null
    state.userToken = null

    delete localStorage.userToken
  },


  [types.FETCH_USER_SUCCESS] (state, { user }) {
    window.Intercom('update', {
      appId: window.config.appId,
      name: user.name,
      email: user.email,
      id: user.id
    })
    state.user = user
  },

  [types.FETCH_USER_FAILURE] (state) {
    state.token = null
    delete localStorage.token
  },

  [types.LOGOUT] (state) {
    state.user = null
    state.token = null
    delete localStorage.token
    document.cookie = "token_id=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;"
    document.cookie = "token=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;"
  },

  [types.UPDATE_USER] (state, { user }) {
    state.user = user
  }
}

// actions
const actions = {
  async userAttachment ({ commit, dispatch }, attachment) {
    try {
      const resp = await axios.put('/user/upload-profile', attachment)
      commit('UPDATE_USER_MEDIA_ATTACHMENT', resp.data.attachments)
      const notification = {
        type: 'success',
        message: 'User profile image updated'
      }
      dispatch('notifications/addNotification', notification, { root: true })
      return true
    } catch (err) {
      Bugsnag.notify(err)
      const notification = {
        type: 'error',
        message: `Error add profile image : ${err.message}`
      }
      dispatch('notifications/addNotification', notification, { root: true })
      return false
    }
  },

  async deleteAttachment ({ commit, dispatch }) {
    try {
      const resp = await axios.put('/user/upload-profile')
      commit('DELETE_USER_MEDIA_ATTACHMENT', resp.data.attachments)
      dispatch('notifications/addNotification', {
        type: 'success',
        message: 'User profile image deleted'
      }, { root: true })
      return true
    } catch (err) {
      Bugsnag.notify(err)
      dispatch('notifications/addNotification', {
        type: 'error',
        message: `Error deleting profile image : ${err.message}`
      }, { root: true })
      return false
    }
  },

  saveToken ({ commit, dispatch }, payload) {
    commit(types.SAVE_TOKEN, payload)
  },

  async fetchUser ({ commit, getters }) {
    try {
      const { data } = await axios.get('/user')

      commit(types.FETCH_USER_SUCCESS, { user: data })
      const { token, token_id } = data;
      if(!getters.userToken || getters.userToken == ''){
        commit(types.SAVE_TOKEN, {token, token_id});
      }
    } catch (e) {
      commit(types.FETCH_USER_FAILURE)
    }
  },

  updateUser ({ commit }, payload) {
    commit(types.UPDATE_USER, payload)
  },

  async logout ({ commit }) {
    try {
      await axios.post('/logout')
    } catch (e) { }

    commit(types.LOGOUT)
  },

  async fetchOauthUrl (ctx, { provider }) {
    const { data } = await axios.post(`/oauth/${provider}`)

    return data.url
  },

  /**
   * @description For saving the switch user token.
   * @param commit
   * @param token
   */
  saveUserToken({commit}, token){
    commit(types.SAVE_USER_TOKEN, token)
  },

  /**
   * @description That will remove the userToken(Switched User Token) and bring back the Admin user.
   * @param commit
   * @returns {Promise<void>}
   */
  async switchUserLogout ({ commit }) {
    try {
      await axios.post('/logout').then(()=> {
        commit(types.SWITCHED_USER_LOGOUT)
      })
    } catch (e) { }


  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
