<template>
  <v-dialog
    v-if="show"
    v-model="show"
    max-width="350"
  >
    <v-card>
      <v-card-title class="text-h5">
        Oh! Seems like you need to upgrade.
      </v-card-title>

      <v-card-text>
        {{ message }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="green darken-1"
          text
          @click="goToUpgrade()"
        >
          Upgrade
        </v-btn>

        <v-btn
          color="green darken-1"
          text
          @click="closePopup"
        >
          No
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'RaUpgradePlan',
  data () {
    return {
      show: false,
      message: ''
    }
  },
  methods: {
    goToUpgrade () {
      this.closePopup()
      this.$router.push({ name: 'pricing' })
    },
    closePopup () {
      this.show = false
    }
  },
  created () {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'permission/SHOW_MESSAGE') {
        this.message = state.permission.message
        this.show = true
      }
    })
  }
}
</script>

<style scoped>

</style>
