import moment from 'moment'
import Vue from 'vue'

/**
 *  To convert the datetime format
 * @param dateTime
 * @returns {string}
 */
function dateTimeConverter (dateTime, format) {
  return moment.utc(dateTime).format(format)
}

/**
 *  Convert the DateTime Object to user readable format
 * @param dateTime
 * @returns {string}
 */
const convertDateTimeForDisplay = (dateTime) => {
  return dateTimeConverter(dateTime, 'YYYY-MM-DD HH:mm:ss')
}

const getDateOnly = (dateTime) => {
  return dateTimeConverter(dateTime, 'Do MMMM, YYYY')
}

const getTimeOnly = (dateTime) => {
  return dateTimeConverter(dateTime, 'h:mm a')
}

// :TODO Need to set the user time zone concept here
const convertDateTimeInUserTimezone = (dateTime) => {
  return dateTimeConverter(dateTime, 'Do MMM, YYYY')
}

const checkEventInProgress = (schedule) => {
  return moment().isBetween(moment(schedule.start_date_time), moment(schedule.end_date_time),undefined, '[]')
}

const addDays = (date,days) => {
  date = new Date(date)
  date.setDate(date.getDate() + days)
  return date
}


// Vue.filter('getDateOnly', function (value) {
//   if (!value) return ''
//   return getDateOnly(value)
// })
//
// Vue.filter('getTimeOnly', function (value) {
//   if (!value) return ''
//   return getTimeOnly(value)
// })
// Vue.filter('convertDateTimeForDisplay', function (value) {
//   if (!value) return ''
//   return convertDateTimeForDisplay(value)
// })

export {
  convertDateTimeForDisplay,
  getDateOnly,
  getTimeOnly,
  convertDateTimeInUserTimezone,
  checkEventInProgress,
  addDays
}
