const originalImageKey = 'original'

function isValidMediaObject (locationMedia) {
  // TODO : Validate the passed media object
  return true
}

const getSource = (locationMedia) => {
  if (!isValidMediaObject(locationMedia)) {
    return ''
  }
  // We'll use the large size image as a fallback if sourceset is not supported by the browser
  return locationMedia.images.large.x1.url
}

const getAltText = (locationMedia) => {
  if (!isValidMediaObject(locationMedia)) {
    return ''
  }
  return locationMedia.altText || ''
}
const getLazyLoadSource = (locationMedia) => {
  if (!isValidMediaObject(locationMedia)) {
    return ''
  }
  // Intsy wintsy image for progressive load
  return locationMedia.images.lls.x1.url
}

const getSourceSet = (locationMedia) => {
  if (!isValidMediaObject(locationMedia)) {
    return ''
  }
  // Iterate through image sizes and return a string of {url+' '+width+'w'}
  const srcset = []
  const densities = ['x1', 'x2']
  Object.entries(locationMedia.images).forEach(size => {
    if (size[0] === originalImageKey) {
      return // Not interested in original image size
    }
    const urls = size[1]
    densities.forEach(density => {
      if (urls[density]) {
        srcset.push(`${urls[density].url} ${urls[density].width}w`)
      }
    })
  })
  return srcset.join(', ')
}

const isEmbedded = (locationMedia) => {
  return locationMedia.video.isEmbedded
}

const getVideoUrl = (locationMedia) => {
  const videoUrl = locationMedia.video.url.replace(/%/g, "%25")
  return locationMedia.video.hasOwnProperty('autoplay') && locationMedia.video.autoplay ? `${videoUrl}?autoplay=1` : videoUrl
}

const getProvider = (locationMedia) => {
  return locationMedia.video.provider
}

export default {
  isEmbedded,
  getVideoUrl,
  getProvider,
  getSource,
  getAltText,
  getSourceSet,
  getLazyLoadSource
}
