function page(path) {
  return () => import(/* webpackChunkName: '' */ `~/pages/${path}`).then(m => m.default || m)
}

const permissionCheck = (to, from, next) => {
  const permissions = sessionStorage.authorization && JSON.parse(sessionStorage.authorization).permissions
  const access = permissions.includes(to?.meta?.permission ?? 'event.edit')
  if (access) {
    next();
  } else {
    next({ name: 'dashboard' });
  }
}

export default [
  { path: '/dashboard', name: 'dashboard', component: page('dashboard.vue') },
  { path: '/termsConditions', name: 'termsConditions', component: page('termsConditions.vue') },
  { path: '/helpModal', name: 'helpModal', component: page('helpModal.vue') },
  { path: '/blogAdmin', name: 'blogAdmin', component: page('blogAdmin.vue') },
  { path: '/adminView', name: 'adminView', component: page('adminView.vue') },
  { path: '/adminViewDetails', name: 'adminViewDetails', component: page('adminViewDetails.vue') },
  { path: '/event-announcement', name: 'event-announcement', component: page('event-announcement.vue') },
  { path: '/media-library', name: 'medialibrary', component: page('myMedia.vue') },
  { path: '/sales', name: 'sales', component: page('billing/mySales.vue') },
  { path: '/billing', name: 'billing', component: page('billing/myBilling.vue') },
  { path: '/event-subscription', name: 'eventSubscribe', component: page('billing/subscriptionPurchase.vue'), props: route => ({ plan: route.query.plan, currency: route.query.currency, subscriptionType: route.query.subscriptionType }) },
  { path: 'test-component', name: 'test.component', component: page('testComponent.vue') },
  { path: 'modal-media', name: 'modal', component: page('MediaModal.vue') },
  { path: '/login', name: 'login', component: page('auth/login.vue') },
  { path: '/pre-logout', name: 'pre-logout', component: page('auth/pre-logout.vue') },
  { path: '/register', name: 'register', component: page('auth/register.vue') },
  { path: '/password/reset', name: 'password.request', component: page('auth/password/email.vue') },
  { path: '/password/reset/:token', name: 'password.reset', component: page('auth/password/reset.vue') },
  { path: '/email/verify/:id', name: 'verification.verify', component: page('auth/verification/verify.vue') },
  { path: '/email/resend', name: 'verification.resend', component: page('auth/verification/resend.vue') },
  { path: '/team-member', name: 'teamMember', component: page('user/teamMembers.vue') },
  { path: '/my-account', name: 'myAccount', component: page('user/myAccount.vue') },
  { path: '/business-card-detail', name: 'business-card-detail', component: page('businessCardDetails.vue') },
  { path: '/profile-test', name: 'profileTest', component: page('user/profileTest.vue') },
  { path: '/team-member-invite-landing/:link', name: 'teamMemberInviteLanding', component: page('user/teamMemberLanding.vue') },
  { path: '/collected-business-cards', name: 'collectedBusinessCards', component: page('user/collectedBusinessCards.vue') },
  { path: '/archive_events', name: 'archive_events', component: page('archiveEvents.vue') },
  { path: '/purchase', name: 'purchaseTicket', component: page('user/purchaseTicket.vue'), props: route => ({ ticket: route.query.ticket, plan: route.query.plan }) }, // In this Route is used for diffrent pages(Public Purchase ticket page and Subscription Table)
  { path: '/ticket-sales', name: 'ticketSales', component: page('billing/ticketSales.vue'), props: route => ({ ticketId: route.query.ticketid }) },
  { path: '/joining/:user/:eventId/:ticket', name: 'attendeeInviteLanding', component: page('user/attendeeInviteLanding.vue') },
  { path: '/pricing', name: 'pricing', component: page('billing/pricingTable.vue') },
  { path: '/admin/users', name: 'adminUsersList', component: page('user/admin/adminUserList.vue') },
  { path: '/admin/user/:userId?', name: 'adminUser', component: page('user/admin/adminUser.vue') },
  { path: '/admin/messages', name: 'messageList', component: page('user/admin/whatsNewPopup/messageList.vue') },
  { path: '/admin/message/:action/:messageId?', name: 'addMessage', component: page('user/admin/whatsNewPopup/addMessage.vue') },
  { path: '/admin/blogs', name: 'blogManagement', component: page('user/admin/blogs/index.vue') },
  { path: '/admin/blog/:blog?', name: 'addBlog', component: page('user/admin/blogs/blogAdmin.vue') },
  { path: '/admin/add/blogs-categories/:id?', name: 'blogCategories-from', component: page('user/admin/blogs/blogCategories.vue') },
  { path: '/admin/list/blog-categories', name: 'blogsCategories', component: page('user/admin/blogs/blogCategoriesList.vue') },
  { path: '/admin/financials', name: 'financials', component: page('user/admin/financials/index.vue') },
  { path: '/admin/upcomingUserEvents', name: 'upcomingUserEvents', component: page('user/admin/events/index.vue') },
  { path: '/admin/event-details/:eventId?', name: 'upcomingEventDetail', component: page('user/admin/events/eventDetail.vue') },
  { path: '/admin/event-graphics-template', name: 'eventGraphicsTemplates', component: page('user/admin/eventGraphicsTemplates.vue') },
  { path: '/admin/add/event-graphics-template/:id?', name: 'eventGraphicsTemplates-form', component: page('user/admin/eventGraphicsTemplatesForm.vue') },
  { path: '/multi-ticket-sales', name: 'multiTicketSales', component: page('billing/multiTicketSales.vue') },
  { path: '/admin/timezone-update', name: 'timezoneUpdate', component: page('user/admin/timezoneUpdate.vue') },

  {
    path: '/public/:eventId',
    name: 'public-event-page',
    component: page('publicEventPage.vue'),
    props: route => ({
      showMainImage: route.query.showMainImage,
      showDescription: route.query.showDescription,
      showSchedule: route.query.showSchedule,
      showTickets: route.query.showTickets,
      isExternalPage: route.query.isExternalPage
    })
  },
  { path: '/multi-ticket-sales', name: 'mutliTicketSales', component: page('billing/mutliTicketSales.vue') },
  { path: '/search-event', name: 'searchEvent', component: page('searchEvent.vue') },
  { path: '/home', name: 'homepage', component: page('homepage.vue') },
  { path: '/pulse', name: 'pulse', component: page('pulse.vue') },
  { path: '/view-subscription-receipt/:invoiceId', name: 'subscriptionReceipt', component: page('receipt.vue') },
  { path: '/view-ticket-receipt/:invoiceId', name: 'ticketReceipt', component: page('ticketReceipt.vue') },
  { path: '/view-invoice/:invoiceId', name: 'invoice', component: page('invoice.vue') },
  { path: '/view-seat-receipt/:id', name: 'seatReceipt', component: page('seatReceipt.vue') },


  {
    path: '/events',
    name: 'events',
    component: page('events/events.vue'),
    beforeEnter: permissionCheck,
    children: [
      { path: ':event', name: 'event.dashboard', component: page('events/eventDashboard.vue') },
      { path: ':event/event-host', name: 'event.host', component: page('events/eventHost/index.vue') },
      { path: ':event/detail', name: 'event.detail', component: page('events/eventDetail/eventDetail.vue'), meta: { permission: 'event.view' } },
      { path: ':event/polls', name: 'event.poll', component: page('events/eventPoll/index.vue') },
      {
        path: ':event/event-steps', name: 'event.event-steps', redirect: to => {
          return { name: 'event.detail', params: { event: to.params.event } }
        }, meta: { permission: 'event.view' }
      },
      { path: ':event/survey', name: 'event.survey', component: page('events/eventSurvey/index.vue') },
      { path: ':event/email-list', name: 'event.email', component: page('events/email/email_list.vue') },
      { path: ':event/email-details', name: 'email_details', component: page('events/email/email_details.vue') },
      { path: ':event/email_delivery_report/:email_template', name: 'email_delivery_report', component: page('events/email/email_delivery_report.vue') },
      { path: ':event/analytics', name: 'event.analytics', component: page('events/eventAnalytics/index.vue') },
      { path: ':event/user-info-analytics', name: 'userInfoAnalytics', component: page('events/eventAnalytics/userInfoAnalytics.vue') },
      { path: ':event/event-sales', name: 'event.event-sales', component: page('events/eventSales/index.vue') }

    ]
  },
  {
    path: '/event-lobby/:event',
    name: 'event-lobby',
    component: page('lobby/index.vue'),
    redirect: { name: 'event-lobby.lobby' },
    children: [
      { path: 'lobby', name: 'event-lobby.lobby', component: page('lobby/lobby.vue') },
      { path: 'floor', name: 'event-lobby.floor', component: page('lobby/floor.vue') },
      { path: 'schedule', name: 'event-lobby.schedule', component: page('lobby/schedule.vue') },
      { path: 'session-stage', name: 'event-lobby.session-stage', component: page('lobby/sessionStage.vue') },
      { path: 'roulette', name: 'event-lobby.roulette', component: page('lobby/networking.vue') },
      { path: 'roulette/openroom/:roomname', name: 'event-lobby.roulette-callroom', component: page('lobby/networking.vue') },
      { path: 'networking', name: 'event-lobby.networking', component: page('lobby/networking.vue') },
      { path: 'networking/openroom/:roomname', name: 'event-lobby.networking-room', component: page('lobby/networking.vue') },
      { path: 'networking/callroom/:roomname', name: 'event-lobby.networking-callroom', component: page('lobby/singleCall.vue') },
      { path: 'video-call', name: 'event-lobby.video-call', component: page('lobby/videoCall.vue') },
      { path: 'expo', name: 'event-lobby.expo', component: page('lobby/expo.vue') },
      { path: 'meeting', name: 'event-lobby.meeting', component: page('lobby/meeting.vue') },
      { path: 'poster-event', name: 'event-lobby.poster-event', component: page('lobby/posterEvent.vue') },
      { path: 'poster-presentation/:poster', name: 'event-lobby.poster-presentation', component: page('lobby/posterPresentation.vue') },
      { path: 'event-help', name: 'event-lobby.event-help', component: page('lobby/eventHelp.vue') },
      { path: 'event-help/openroom/:roomname', name: 'event-lobby.event-help-openroom', component: page('lobby/eventHelp.vue') },
      { path: 'splash-screen', name: 'event-lobby.splash-screen', component: page('lobby/splashScreen.vue') },
      { path: 'expo-company/:company', name: 'event-lobby.expo-company', component: page('lobby/company.vue') },
      { path: 'expo-company/:company/talkus', name: 'event-lobby.expo-company-preroom', component: page('lobby/companyTalk.vue') },
      { path: 'expo-company/:company/talkus/openroom/:roomname', name: 'event-lobby.expo-company-room', component: page('lobby/companyTalk.vue') },
      { path: 'timeline-schedule', name: 'timeline-schedule', component: page('lobby/timeLineSchedule.vue') }

    ]
  },
  {
    path: '/settings',
    component: page('settings/index.vue'),
    children: [
      { path: '', redirect: { name: 'settings.profile' } },
      { path: 'profile', name: 'settings.profile', component: page('settings/profile.vue') },
      { path: 'password', name: 'settings.password', component: page('settings/password.vue') }
    ]
  },

  { path: '*', component: page('errors/404.vue') },

  // external pages link
  {
    path: '/book-a-demo',
    name: 'book_a_demo',
    component: {},
    beforeEnter(to, from, next) {
      window.location.href = 'https://reattendance.com/book-a-demo/'
    }
  },
  {
    path: '/contact-us',
    name: 'contact_us',
    component: {},
    beforeEnter(to, from, next) {
      window.location.href = '/contact-us/'
    }
  },
  { path: '/seat-sales', name: 'seatSales', component: page('billing/seatSales.vue') },
  { path: '/download-qr-code/:link/:eventId', name: 'downloadQrCode', component: page('user/downloadQrCode.vue') },
  { path: '/admin/all-invoices', name: 'allInvoices', component: page('allInvoices.vue') },


]
