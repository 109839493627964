const state = {
  refCount: 0,
  isLoading: false
}

const getters = {
  isDataLoading: state => state.isLoading
}

const mutations = {
  set: (state, isLoading) => {
    if (isLoading) {
      state.refCount++
      state.isLoading = true
    } else if (state.refCount > 0) {
      state.refCount--
      state.isLoading = (state.refCount > 0)
    }
  }
}

const actions = {
  showLoader: async ({ commit, dispatch }, isLoading) => {
    commit('set', isLoading)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
