export default {
  install: (app, options) => {
    app.prototype.can = function (value, userPermissions = null) {
      if (userPermissions == 0 && (sessionStorage.authorization && JSON.parse(sessionStorage.authorization).permissions == 0)) {
        return false
      }
      const permissions = userPermissions || (sessionStorage.authorization && JSON.parse(sessionStorage.authorization).permissions)
      let _return = false
      if (!Array.isArray(permissions)) {
        return false
      }
      if (value.includes('|')) {
        value.split('|').forEach(function (item) {
          if (permissions.includes(item.trim())) {
            _return = true
          }
        })
      } else if (value.includes('&')) {
        _return = true
        value.split('&').forEach(function (item) {
          if (!permissions.includes(item.trim())) {
            _return = false
          }
        })
      } else {
        _return = permissions.includes(value.trim())
      }
      return _return
    }
    app.prototype.is = function (value) {
      if (sessionStorage.authorization && JSON.parse(sessionStorage.authorization).role == 0) {
        return false
      }
      const roles = [JSON.parse(sessionStorage.authorization).role]
      let _return = false
      if (!Array.isArray(roles)) {
        return false
      }
      if (value.includes('|')) {
        value.split('|').forEach(function (item) {
          if (roles.includes(item.trim())) {
            _return = true
          }
        })
      } else if (value.includes('&')) {
        _return = true
        value.split('&').forEach(function (item) {
          if (!roles.includes(item.trim())) {
            _return = false
          }
        })
      } else {
        _return = roles.includes(value.trim())
      }
      return _return
    }
  }
}
