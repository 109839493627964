import axios from 'axios'
const state = {
  visitorsInfo: {}
}

const getters = {
  visitorsInfo: state => state.visitorsInfo
}

const mutations = {
  set: (state, visitorsInfo) => {
    state.visitorsInfo = visitorsInfo
  }
}

const actions = {
  setVisitorsInfo: (context, { eventId, path }) => {
    axios.post(`event/${eventId}/visitor-log`, path).then(({ data }) => {
      context.commit('set', data)
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
