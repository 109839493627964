<template>
  <v-app>
    <v-spacer />
    <Navbar v-if="!isExternalPage"/>
    <v-main :class="{'externalPagePad' : isExternalPage}">
      <RaGlobalSnackbar />
      <RaUpgradePlan />
      <RaLoadingSpinner />
      <div class="overlay__global" ref="global_overlay" v-if="showOverlay">
        <v-skeleton-loader type="image" :dark="$vuetify.theme.dark" height="100vh" style="background:#000"></v-skeleton-loader>
      </div>
      <v-container fluid :class="containerClasses">
        <router-view />
      </v-container>
    </v-main>
    <cookie-law ></cookie-law>
    <Footer v-if="!isExternalPage"/>
  </v-app>
</template>

<script>
import Navbar from '~/components/Navbar'
import Footer from '~/components/Footer'
import RaLoadingSpinner from '~/components/general/RaLoadingSpinner'
import CookieLaw from 'vue-cookie-law'
export default {
  name: 'MainLayout',

  components: {
    RaLoadingSpinner,
    Navbar,
    Footer,
    CookieLaw
  },
  data () {
    return {
      showOverlay: false
    }
  },
  computed: {
    isExternalPage(){
      return (this.$route.query && this.$route.query.isExternalPage && this.$route.query.isExternalPage === 'true') || this.$route.name == 'attendeeInviteLanding'
    },
    containerClasses () {
      return this.$route.name === 'public-event-page' ? 'pa-0' : '';
    }
  },
  created () {
    this.$root.$on('global-overlay', (status) => {
      this.showOverlay = status
    })
    window.Intercom('boot', {
      app_id: window.config.appId
      // Website visitor so may not have any user related info
    })
  },
  watch: {
    isExternalPage: {
      handler: function (val) {
        if(val){
          window.Intercom('update', {
            "hide_default_launcher": true
          });
        }
      },
      immediate: true
    }
  }
}
</script>
