/**
 * Converts a number of bytes to a size string e.g. 7.1 Mb or 28 Kb
 * @param byteSize
 * @returns {string}
 */
const bytesPerKb = 1024
const getFormattedSize = (byteSize) => {
  const sizes = ['bytes', 'Kb', 'Mb', 'Gb', 'Tb']

  if (isNaN(byteSize) || byteSize == 0) {
    return 0
  }
  const i = parseInt(Math.floor(Math.log(byteSize) / Math.log(bytesPerKb)))

  // eslint-disable-next-line eqeqeq
  if (i == 0) {
    return `${byteSize} ${sizes[i]}`
  }

  return `${(byteSize / Math.pow(bytesPerKb, i)).toFixed(1)} ${sizes[i]}`
}

export default {
  getFormattedSize
}
