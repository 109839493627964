import axios from 'axios'
import {accountRestrict} from "../../plugins/config/accountRestrict";

const state = {
  message: '',
  event: {},
  pausedResolve: null,
  pausedPromise: Promise.resolve(),
  payload: {}
}

const getters = {
  event: state => state.event,
  pausedResolve: state => state.pausedResolve
}

const mutations = {
  SHOW_MESSAGE (state, message) {
    state.message = message
  },
  SET_EVENT_DATA (state, data) {
    state.event = data
  },
  SHOW_WAITING_POPUP (state, data) {
    state.event = data
  },
  SHOW_ACCOUNT_RESTRICT_POPUP (state, data) {
    state.payload = data
  },
  PAUSE_PROMISE (state){
    if (!state.pausedResolve) {
      state.pausedPromise = new Promise(resolve => state.pausedResolve = resolve)
    }
  },
  RESUME_PROMISE (state) {
    if (state.pausedResolve) {
      state.pausedResolve()
      state.pausedResolve = null
    }
  }
}

const actions = {
  async fetchPermission ({ commit, state }, data) {
    try {
      return await axios.post('/event/' + data.event + '/permissions', data).then((data) => {
        sessionStorage.setItem('authorization', JSON.stringify(data.data))
      }).catch(() => {
        sessionStorage.setItem('authorization', JSON.stringify([]))
      })
    } catch (e) {
      console.log(e)
    }
  },
  async isAttendee ({ commit, state }, { event, to }) {
    try {
      return await axios.get('/event/' + event + '/check-is-attendee',  { params: {to :to} }).then((data) => {
        commit('SET_EVENT_DATA', data.data)
        return data.data
      })
    } catch (e) {
      console.log(e)
    }
  },
  showPlanDialog ({ commit }, message) {
    commit('SHOW_MESSAGE', message)
  },
  showWaitingPopup ({ commit }, payload) {
    commit('SHOW_WAITING_POPUP', payload)
  },
  showAccountRestrictPopup({commit}, payload){
    commit('SHOW_ACCOUNT_RESTRICT_POPUP', accountRestrict(payload.user, payload.route))
  },
  pause({ commit }) {
    commit('PAUSE_PROMISE')
  },
  resume({commit}) {
    commit('RESUME_PROMISE')
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
