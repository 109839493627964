const dismissible = ['dashboard']
let userP, routeP
const getUserLabel = () => {
  return userP == 'event-owner' ? 'event_owner' : 'normal_user'
}

const getRouteLabel = () => {
  return routeP == 'dashboard' ? 'dashboard' : 'layout'
}

const modalTitle = () => {
  return `account_restricted.${getUserLabel()}_${getRouteLabel()}.title`
}

const modalContent = () => {
  return `account_restricted.${getUserLabel()}_${getRouteLabel()}.content`
}

const buttonText = () => {
  return userP === "event-owner" ? 'account_restricted.make_payment' : 'account_restricted.dashboard'
}

const buttonLink = () => {
  return userP === "event-owner" ? 'billing': 'dashboard'
}

export const accountRestrict = (user,route) => {
  userP = user
  routeP = route
  return {
    dismissible: dismissible.includes(route),
    title: modalTitle(),
    content: modalContent(),
    button_text: buttonText(),
    redirect_url: buttonLink()
  }
}
