<template>
  <v-list-item id="v-step-12" @click="checkForTicketForSale()">
    <v-icon left>mdi-account-box</v-icon>
    <v-list-item-content>
      <v-list-item-title>{{ $t("public_event.public_event_page") }}</v-list-item-title>
      <v-dialog
        v-model="show"
        max-width="480">
        <v-card>
          <div class="ra__form-heading">
            <v-card-title class="ra__modal-title">
              Event Not Ready for Public Page
            </v-card-title>
            <v-btn icon @click="show = false" aria-label="Close Announcement">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div class="pt-3 px-3 px-lg-8">
            You currently do not have any tickets associated with this event which are set with <b>Ticket Sale Type: For Sale</b>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="show = false"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showPublicEventPageOptions" max-width="480">
        <v-card>
          <v-card-title class="headline">
            Public Event Page
          </v-card-title>
          <v-card-actions>
            <v-spacer />
              <div class="d-flex flex-column align-end">
                <v-btn color="red darken-1" text @click="redirectToPublicPage">
                  View public event page on Reattendance
                </v-btn>
                <v-btn color="red darken-1" text @click="hostPublicEventPage = true">
                  Host Public Event Details on External Site
                </v-btn>
                <v-btn color="blue darken-1" text @click="showPublicEventPageOptions = false">
                  Cancel
                </v-btn>
              </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="hostPublicEventPage"
        max-width="600">
        <v-card>
          <div class="ra__form-heading">
            <v-card-title class="ra__modal-title">
              Host Public Event Details on External Page for {{ common_event_data.name }}
            </v-card-title>
            <v-btn icon @click="hostPublicEventPage = false" aria-label="Close popup window">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div class="pt-3 px-3 px-lg-8">
            <p>If you would like to host the public event details on an external website, you can set your parameters here and then insert the script into your site. This will then pull the live data from Reattendance onto your own website dynamically, Any changes you make in Reattendance are then immediately reflected in your own site.</p>
          </div>
          <div class="pt-3 px-3 px-lg-8">
            <p>Choose the parameters to include:</p>
          </div>

          <div class="px-3 px-lg-8">
            <v-switch
              v-model="showMainImage"
              value inset color="#199ED9"
              required
              label="Show Main Image"
            />
          </div>
          <div class="px-3 px-lg-8">
            <v-switch
              v-model="showDescription"
              value inset color="#199ED9"
              required
              label="Show Description"
            />
          </div>
          <div class="px-3 px-lg-8">
            <v-switch
              v-model="showSchedule"
              value inset color="#199ED9"
              required
              label="Show Schedule"
            />
          </div>
          <div class="px-3 px-lg-8">
            <v-switch
              v-model="showTickets"
              value inset color="#199ED9"
              required
              label="Show Tickets"
            />
          </div>
          <div class="pt-3 px-3 px-lg-8">
            <div class="ra__copy-box">
              <div>
                <div class="ra__label-block">
                  <label class="ra__fields-label ra-fields--text-input__label"
                    for="external-script"
                  >
                    Scripts For Your Website:
                  </label>
                </div>
                <p>1. Copy the following script into the scripts area of your website (usually the Body Scripts section). This loads the code ready for you to use.</p>
                <v-text-field
                  v-model="externalScriptToCopy"
                  name="external-script"
                  flat
                  solo
                  class="ra__input"
                  readonly
                />
              </div>
              <div class="copy__btn-script">
                <v-tooltip
                  v-model="showExternalScript"
                  bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      height="1px" >
                    </v-btn>
                  </template>
                  <span>Copied!</span>
                </v-tooltip>
                <v-btn                
                  class="btn-primary"
                  small
                  elevation="2"
                  @click.stop.prevent="copyLink(externalScriptToCopy);showExternalScript = !showExternalScript">{{ $t('Copy')}}
                </v-btn>
              </div>
            </div>
            <div class="ra__copy-box">
              <div>
                <p>2. Copy the following code and paste it into your HTML, where you would like to actually display the widget (for example, in the middle of the page).</p>
                <v-text-field
                  v-model="externalDivScriptToCopy"
                  name="external-script"
                  flat
                  solo
                  class="ra__input"
                  readonly
                />
              </div>
              <div class="copy__btn-div">
                <v-tooltip
                  v-model="showExternalDivScript"
                  bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      height="1px" >
                    </v-btn>
                  </template>
                  <span>Copied!</span>
                </v-tooltip>
                <v-btn                
                  class="btn-primary"
                  small
                  elevation="2"
                  @click.stop.prevent="copyLink(externalDivScriptToCopy);showExternalDivScript = !showExternalDivScript">{{ $t('Copy')}}
                </v-btn>
              </div>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import RaTextField from '~/components/forms/RaTextField'
export default {
  name: 'PublicEventPageItem',
  components: { RaTextField },
  props: {
    fromMobile: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      show: false,
      showPublicEventPageOptions: false,
      hostPublicEventPage: false,
      showExternalScript: false,
      isExternalPage: true,
      showMainImage: false,
      showDescription: false,
      showSchedule: false,
      showTickets: false,
      showExternalDivScript: false
    }
  },
  computed: {
    ...mapGetters('events', ['common_event_data']),
    eventId(){
      return this.$route.params.event;
    },
    externalScriptToCopy(){
      return '<script>window.onload = function(e) { '+ this.functionStr +' let contentBox = document.getElementById("' + `${process.env.MIX_EXTERNAL_DIV_ID}` +'");if(contentBox){ contentBox.innerHTML=' + `'${this.objectString}'; return;}` + this.endFunctionStr + '<\/script>';
    },
    objectString(){
      return `<object type="text/html" style="height: 100%; width: 100%;" data="${process.env.MIX_APP_URL}public/${this.eventId}?isExternalPage=${this.isExternalPage}&showMainImage=${this.showMainImage}&showDescription=${this.showDescription}&showSchedule=${this.showSchedule}&showTickets=${this.showTickets}"></object>`
    },
    functionStr(){
      return `const wait = () => new Promise((resolve) => {setTimeout(resolve, 1000)}); let attempts = 10; var tryDownload = async function(attempts) { attempts > 0 ? attempts -= 1 : "";`
    },
    endFunctionStr(){
      return `else{await wait(); tryDownload(attempts);} };tryDownload(attempts);}`
    },
    externalDivScriptToCopy(){
      return `<div id="${process.env.MIX_EXTERNAL_DIV_ID}" style="height: 1000px"><p>Powered by <a href="https://reattendance.com">Reattendance</a></p></div>`
    }
  },
  methods: {
    checkForTicketForSale(){
      axios
      .get(`event/${this.eventId}/get-tickets-for-sale`)
      .then(response => {
        if(response.data.ticketsForSaleCount > 0){
          this.showPublicEventPageOptions = true
        }
        else{
          this.show = true
        }
      })
      .catch((error) => {
          console.log(error)
      })
      if(this.fromMobile){
        this.$root.$emit('collapse-menu')
      }
    },
    redirectToPublicPage(){
      this.$router.push({ name: 'public-event-page', params: { eventId: this.eventId } })
    },
    async copyLink (copyLink) {
      await navigator.clipboard.writeText(copyLink);
      setTimeout(() => {
        this.showExternalScript = false
        this.showExternalDivScript = false
       }, 1500)
    },
  },
}
</script>
