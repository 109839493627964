<template>
  <footer>
    <div class="footer__wrapper">
      <div class="footer__menu">
        <a class="footer__menu-link" :href="url('terms-and-conditions')">{{ $t('terms_&_conditions') }}</a>
        <a class="footer__menu-link" :href="url('privacy-policy')">{{ $t('privacy_policy') }}</a>
        <a class="footer__menu-link" :href="url('contact-us')">{{ $t('contact') }}</a>
        <a class="footer__menu-link" :href="url('blogs')">{{ $t('blog') }}</a>
        <a class="footer__menu-link" :href="url('kb')">{{ $t('Knowledge Base') }}</a>
        <a class="footer__menu-link" href="javascript:void(0);">{{ $t('affiliates') }}</a>
      </div>
      <div class="footer__address">
        <p class="footer__address-tag">Reattendance Ltd - Registered Office: CS Innovation Centre, Mewburn Road, Banbury, England OX16 9PA - Registered Company Number: 08070386</p>
        <p class="footer__address-copyright">&copy; {{ currentYear }} · Reattendance · Serving The Event Industry</p>
      </div>
    </div>
  </footer>
 
  
</template>

<script>

export default {
  data () {
    return {
      currentYear: window.currentYear
    }
  },
  methods : {
    url (path) {
      return window.config.appUrl + path
    }
  }
}
</script>

