<template>
  <div name="snackbars">
    <v-snackbar v-model="show" :multi-line="true" :color="activeColor" :timeout="timeout" :right="true" :top="true" :transition="'scroll-x-transition'">
      <span v-html="text"></span>
      <template #action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="show = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  name: 'RaGlobalSnackbar',
  data () {
    return {
      show: false,
      color: '',
      colors: {
        success: 'green darken-1',
        warning: 'orange lighten-1',
        error: 'deep-orange darken-4'
      },
      text: '',
      timeout: '-1'
    }
  },
  computed: {
    activeColor () {
      return this.colors[this.color]
    }
  },
  created () {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'snackbar/SHOW_MESSAGE') {
        this.text = state.snackbar.text
        this.color = state.snackbar.color
        this.timeout = state.snackbar.timeout
        this.show = true
      }
    })
  }
}
</script>

<style scoped>

</style>
