<template>
  <div class="camera">
    <div
      class="camera__video-container"
      :class="{
        'camera__video-container--visible': isCapturing,
      }"
    >
      <video ref="video" autoplay class="camera__video" playsinline />
      <div class="camera-action-btn">
        <v-btn
          class="camera__take-photo mx-2"
          fab
          dark
          large
          color="cyan"
          @click="onTakePhotoClick"
        >
          <v-icon dark>
            mdi-camera
          </v-icon>
        </v-btn>
        <v-btn
          class="mx-2"
          fab
          dark
          large
          color="cyan"
          @click="onCancelPhotoClick"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import CameraPhoto, { FACING_MODES } from 'jslib-html5-camera-photo'

export default {
  name: 'RaCameraCapture',
  data () {
    return {
      cameraPhoto: null,
      videoElement: null,
      isCapturing: false,
      imageSrc: '',
      isMobile: false,
      isTurningOn: false
    }
  },
  mounted: function () {
    this.cameraPhoto = new CameraPhoto(this.$refs.video)
    this.onTurnOnCameraClick()
  },
  methods: {
    async onTurnOnCameraClick () {
      this.isLoading = true
      try {
        await this.cameraPhoto.startCamera(FACING_MODES.USER)
        this.isCapturing = true
      } catch (e) {
        console.error(e)
      }
      this.isLoading = false
    },

    async closeVideo () {
      await this.cameraPhoto.stopCamera()
    },
    async onTakePhotoClick () {
      this.imageSrc = this.cameraPhoto.getDataUri({
        sizeFactor: 1
      })
      this.isCapturing = false
      await this.closeVideo()
      this.$emit('image-captured', this.imageSrc)
    },
    async onCancelPhotoClick () {
      this.isCapturing = false
      await this.closeVideo()
      this.$emit('cancel-capture')
    }
  }
}
</script>

<style lang="scss" scoped>

.camera {
  z-index: 10;
  &__video-container {
    display: none;
    background: #000;
    &--visible {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  &__video {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
  &__take-photo {
    // z-index: 2;
    // position: absolute;
    // bottom: 10%;
    // left: 50%;
    // right: 50%;
    // transform: translate(-50%,-50%);
    // cursor: pointer;
  }
  .camera-action-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 15%;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
}
</style>
