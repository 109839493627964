import axios from 'axios'
import mediaHandler from '~/plugins/media/mediaHandler'
import {isEmpty} from "lodash";
const state = {
  events: [],
  modal: {},
  singleEvent: {
    schedule: {},
    attachments: {},
    sponsors: {}
  },
  team_members: [],
  dashboard_events: {
    eventWhichCompleted: [],
    eventsIAttendee: [],
    eventIOwns: [],
    eventICanEdit: [],
    eventsCount: 0
  },
  eventIndicators: { // TODO : We might read this from the event directly, or use a indicators object
    isPublished: false,
    isOwner: true // TODO : if the logged in user is the owner of the event
  },
  event_builder_data: {},
  common_event_data: {},
  pinnedPlayer: {},
  whatsNewMessage: [],
  is_event_calling: false,
  event_promise: {},
  eventEmailListData: {}
}

const getters = {
  events: state => state.events,
  singleEvent: state => state.singleEvent,
  team_members: state => state.team_members,
  dashboard_events: state => state.dashboard_events,
  event_builder_data: state => state.event_builder_data,
  common_event_data: state => state.common_event_data,
  eventIndicators: state => state.eventIndicators,
  pinnedPlayer: state => state.pinnedPlayer,
  whatsNewMessage: state => state.whatsNewMessage,
  is_event_calling: state => state.is_event_calling,
  eventEmailListData: state => state.eventEmailListData
}

const mutations = {
  set: (state, response) => {
    state.events = response.data
  },
  SET_STORE_MODAL (state, modals) {
    state.modal = modals
  },
  SET_SINGLE_EVENT (state, event) {
    state.singleEvent = event
  },
  SET_TEAM_MEMBERS (state, data) {
    state.team_members = data
  },
  SET_DASHBOARD_EVENTS (state, data) {
    state.dashboard_events = data
  },
  SET_EVENT_BUILDER_DATA (state, data) {
    state.event_builder_data = data
  },
  GET_COMMON_EVENT_DATA (state, data) {
    state.common_event_data = data
  },
  SET_PINNED_PLAYER: (state, data) => {
    state.pinnedPlayer = data
  },
  GET_NEW_MESSAGE: (state, data) => {
    state.whatsNewMessage = data
  },
  SET_EVENT_PROMISE: (state, data) => {
    state.event_promise = data
  },
  SET_EVENT_LOADING: (state, status) => {
    state.is_event_calling = status
  },
  GET_EVENT_EMAIL_LIST_DATA: (state, data) => {
    state.eventEmailListData = data
  }
}

const actions = {
  getPinnedPlayers: async (context, eventId) => {
    return axios.get(`/event/${eventId}/get-pinned-player`)
      .then(({ data }) => {
        data.forEach((element) => {
          element.media = mediaHandler.getAllMediaForModel('EventPlayer', element.attachments)
        })
        context.commit('SET_PINNED_PLAYER', data)
      })
  },
  getTeamMembers: (context) => {
    axios.get(`event/${state.singleEvent.id}/team-members`)
      .then(({ data }) => {
        context.commit('SET_TEAM_MEMBERS', data)
      })
  },
  // Get All the Events
  getEvents: (context) => {
    return axios.get('event')
      .then(({ data }) => {
        context.commit('set', data)
      })
  },
  getDashboardEvents: (context) => {
    return axios.get('event/dashboard-events')
      .then(({ data }) => {
        const transformedDashBoardEvents = transformDashBoardEvents(data)
        context.commit('SET_DASHBOARD_EVENTS', transformedDashBoardEvents)
      })
  },
  deleteEvent: (context) => {
    axios.delete(`/event/${state.singleEvent.id}/delete`)
      .then(({ data }) => {
        state.events = state.events.filter(event => event !== state.singleEvent)
      })
  },
  getEvent: async (context, {id, callback}) => {
    if (state.is_event_calling) {
      return state.event_promise
    } else {
      context.commit('SET_EVENT_LOADING', true)
      const promise = await axios.get(`event/${id}`)
        .then(({data}) => {
          data.media = mediaHandler.getAllMediaForModel('Event', data.attachments)
          const backgroundImages = data.advanced_graphics_choice['attachments'] ?? []
          if(isEmpty(backgroundImages)){
            data.advanced_graphics_choice.background_image = {}
          } else {
            data.advanced_graphics_choice.background_image = mediaHandler.getAllMediaForModel('EventGraphicChoice', backgroundImages)
          }
          context.commit('SET_SINGLE_EVENT', data)
          context.commit('SET_EVENT_LOADING', false)
          if (typeof callback === 'function') {
            callback(data)
          }

          return data
        })
      context.commit('SET_EVENT_PROMISE', promise)
      return state.promise
    }
  },
  restoreEvent: (context) => {
    axios.post(`/event/${state.singleEvent.id}/restore`)
      .then(({ data }) => {
        context.commit('set', data)
      })
  },
  assignToTeam: () => {
    axios.post(`/event/${state.singleEvent.id}/team`)
      .then(({ data }) => {
        // TODO assign team function to backend
        state.modal.hide('assign-team-modal')
      })
  },
  getEventBuilderInfo: (context, eventId) => {
    axios.get(`event/${eventId}/event-builder`).then(({ data }) => {
      context.commit('SET_EVENT_BUILDER_DATA', data)
    })
  },
  getCommonEventData: async (context, eventId) => {
    return await axios.get(`event/${eventId}/common-info`).then(({data}) => {
      context.commit('GET_COMMON_EVENT_DATA', data)
    })
  },
  getWhatsNewMessage: (context) => {
    axios.get('/admin/whats-new-message/for-user').then(({ data }) => {
      context.commit('GET_NEW_MESSAGE', data.data)
    })
  },
  getEventEmailListData: (context, eventId) => {
    axios.get(`event/${eventId}/email-list-data`).then(({ data }) => {
      context.commit('GET_EVENT_EMAIL_LIST_DATA', data)
    })
  }
}
//
// Apply transformations
// Process each event object, add media collection to event
const transformDashBoardEvents = (events) => {
  const model = 'Event'
  const attachmentsKey = 'attachments'
  const eventCollections = ['eventIOwns', 'eventICanEdit', 'eventsIAttendee', 'eventWhichCompleted']
  eventCollections.forEach(collection => {
    // Loop through events collection
    events[collection].forEach(event => {
      // Get attachments for this event
      const attachments = ((attachmentsKey in event) && event[attachmentsKey].length) ? event[attachmentsKey] : []
      // Transform attachments into media object, append to event
      event.media = mediaHandler.getAllMediaForModel(model, attachments)
    })
  })
  return events
}

export default {
  state,
  getters,
  actions,
  mutations
}
