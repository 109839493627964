<template>
  <div class="user-cropper">
    <div class="user-cropper__crop-area">
      <vue-cropper v-if="hasImageToCrop"
                   ref="cropper"
                   :guides="false"
                   :zoomable="true"
                   :zoom-on-wheel="true"
                   :zoom-on-touch="true"
                   :scalable="false"
                   :rotatable="false"
                   :crop-box-resizable="false"
                   :crop-box-movable="false"
                   :min-crop-box-width="200"
                   drag-mode="move"
                   :view-mode="1"
                   :aspect-ratio="aspectRatio"
                   :toggle-drag-move-on-dblclick="false"
                   :moveable="true"
                   :src="imgSrc"
                   @ready="onCropImageReady"
      />
      <div
        class="text-center"
      > 
          <v-btn
            class="btn-outline-primary profile__btngroup1" 
            icon
            :disabled="!cropperIsReady"
            @click="onZoom('in')"
          >
            <v-icon large>mdi-magnify-plus</v-icon>
          </v-btn>
          <v-btn
            class="btn-outline-primary profile__btngroup1" 
            icon
            :disabled="!cropperIsReady"
            @click="onZoom('out')"
          >
            <v-icon large>mdi-magnify-minus</v-icon>
          </v-btn> 
      </div>
      <div
        class="text-center"
      > 
          <v-btn
            class="btn-outline-primary profile__btngroup1"
            depressed
            large
            outlined
            @click="onNewPhoto"
          >
            + New Photo
          </v-btn>
          <v-btn
            class="btn-primary profile__btngroup1"
            depressed
            large
            :disabled="!cropperIsReady"
            @click="onSave"
          >
            Save
          </v-btn> 
      </div>
    </div>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  name: 'ProfileCropper',
  components: { VueCropper },
  props: {
    // Attachment object passed in v-model binding
    imgSrc: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      aspectRatio: 1,
      zoomRatio: 0.1,
      cropperIsReady: false
    }
  },
  computed: {
    hasImageToCrop () {
      return !!this.imgSrc
    }
  },
  methods: {
    onZoom (inOut) {
      this.$refs.cropper.relativeZoom(inOut === 'in' ? this.zoomRatio : -this.zoomRatio)
    },
    onCropImageReady () {
      this.cropperIsReady = true
    },
    async onSave () {
      // Add crop details to data and emit
      const imageBlob = await new Promise(resolve => this.$refs.cropper.getCroppedCanvas().toBlob(resolve, 'image/jpeg'))
      this.$emit('cropper-save', {
        cropBlob: imageBlob
      })
    },
    onNewPhoto () {
      this.$emit('new-photo')
    }
  }
}
</script> 
