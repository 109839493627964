<template>
  <v-app>
    <v-navigation-drawer
      v-model="leftDrawerHover"
      :expand-on-hover="$vuetify.breakpoint.mdAndUp"
      fixed
      :permanent="$vuetify.breakpoint.mdAndUp"
      class="side__drawer"
      :style="getSideBarColour"
    >
      <v-list class="side__drawer-menu" nav dense>
        <v-list-item-group class="side__drawer-color">
          <v-list-item
            v-for="(entry, i) in sideBarEntries"
            :key="i"
            link
            class="side__drawer-menu--items"
            :class="selectedMenu(entry.linkTo) ? 'v-list-item--active': ''"
            :to="!!entry.linkTo ? { name: entry.linkTo }:''"
          >
            <v-list-item-icon>
              <v-img
                :src="entry.imgUrl"
                class="side__drawer-icon"
              />
            </v-list-item-icon>
            <v-list-item-title :style="getMenuColour">{{ entry.title }}</v-list-item-title>
          </v-list-item>
          <helpModal/>
          <v-divider />
          <v-list-item class="side__drawer-menu--items" @click="logout">
            <v-list-item-icon>
              <v-img
                src="/img/networking/logout.svg"
                class="side__drawer-icon"
              />
            </v-list-item-icon>
            <v-list-item-title :style="getMenuColour">
              {{ logoutLabel }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="side__drawer-menu--profile" link :to="{ name: 'myAccount' }">
            <v-list-item-avatar>
              <RaMediaDisplay
                :is-profile-image="true"
                :media="userMedia"
              />
            </v-list-item-avatar>
            <v-list-item-title :style="getMenuColour" class="side__drawer-menu--items side__drawer-menu--myaccount">
              {{ $t("my_account.my_account_title") }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import RaMediaDisplay from '~/components/media/RaMediaDisplay'
import helpModal from '/resources/js/pages/helpModal.vue'
import isEmpty from 'lodash/isEmpty'
import { singleEventMixin } from '~/mixins/singleEventMixin'

export default {
  components: { RaMediaDisplay , helpModal },
  mixins: [singleEventMixin],
  data () {
    return {
      leftDrawerHover: false,
      sideBarEntries: [
        {
          linkTo: 'billing',
          imgUrl: '/img/dashboard/nav1.svg',
          title: 'My Billing'
        },
        {
          linkTo: 'medialibrary',
          imgUrl: '/img/dashboard/nav2.svg',
          title: 'Media Library'
        },
        {
          linkTo: 'teamMember',
          imgUrl: '/img/dashboard/nav3.svg',
          title: 'Team Members'
        },
        {
          linkTo: 'sales',
          imgUrl: '/img/dashboard/nav4.svg',
          title: 'My Sales'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('auth', ['user','userToken']),
    userMedia () {
      return isEmpty(this.user) ? {} : this.user.attachments
    },
    validRoute(){
      return this.$route.name === 'event.detail'
    },
    switchedUser() {
      return !!this.userToken
    },
    logoutLabel() {
      return this.switchedUser ? this.$t("switchOff") : this.$t("logout")
    },
    getSideBarColour () {
      // Commented the b3 logic, because it was creating the issue with left side bar opacity.
      // return { backgroundColor: this.singleEvent.unselected_colour + ((this.$vuetify.theme.dark) ? 'b3' : '') + '!important' }
      if(this.validRoute){
        return { backgroundColor: this.singleEvent?.advanced_graphics_choice?.sidebar_unselected_colour + '!important' }
      } else {
        return { }
      }
    },
    getMenuColour () {
      if(this.validRoute) {
        return {color: this.singleEvent?.advanced_graphics_choice?.sidebar_unselected_font_colour + '!important'}
      } else {
        return {}
      }
    },
  },
  created () {
    this.$root.$on('get-navigation', (payload) => {
      this.leftDrawerHover = payload
    })
  },
  methods: {
    async logout () {
      await this.$router.push({ name: 'pre-logout' })
    },
    selectedMenu(linkTo){
      if (this.$route.name == 'event-lobby.roulette' && linkTo == 'event-lobby.networking')
        return true
      if (linkTo == this.$route.name)
        return true
    },
  }
}
</script>

<style scoped>

</style>
