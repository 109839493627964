<template>
  <div>
    <div class="ra__label-block">
      <label v-if="label"
            class="ra__fields-label ra-fields--text-input__label"
            :class="{ 'ra__fields-label--required' : isRequired }"
            :for="labelFor"
      >
        {{ label }}
      </label>
      <v-icon v-if="hasHelpIcon" class="ra__label-icon" @click.stop="displayHelp">mdi-help-circle-outline</v-icon>
    </div>
    <v-text-field
      :id="id"
      :name="name"
      :value="value"
      :counter="counter"
      :maxlength="maxLength"
      :place-holder="placeHolder"
      flat
      solo
      class="ra__input"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </div>
</template>
<script>
import { formComponentMixin } from '~/mixins/formComponentMixin'

export default {
  name: 'RaTextField',
  mixins: [formComponentMixin],
  props: {
    // Props : are set in formComponentMixin
    isRequired: {
      type: Boolean,
      default: false
    },
    hasHelpIcon: {
      type: Boolean,
      default: false
    },
    helpIconForm: {
      type: String,
      default: ''
    },
    helpIconField: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showFieldHelpFlag: false
    }
  },
  methods: {
    updateValue (e) {
      this.$emit('input', e.target.value)
    },
    displayHelp () {
      this.showFieldHelpFlag = true
      this.$root.$emit('get-help-field-info', this.showFieldHelpFlag, this.helpIconForm, this.helpIconField)
    }
  }
}
</script>
