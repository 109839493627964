<template>
  <v-app v-if="show">
    <v-dialog
    v-if="show"
    v-model="show"
    persistent
    content-class="model__width"
  >
    <v-card>
      <div class="ra__form-heading">
        <v-card-title class="ra__modal-title">
          <h3 class="ra__modal-title--heading">
            Oops! This event hasn't started yet!
          </h3>
        </v-card-title>
        <ra-modal-close-button @close-modal="closePopup" />
      </div>
      <v-card-text class="ra__waiting-popup">
        <div class="confetti" v-if="!waiting">
          <div class="confetti-piece"></div>
          <div class="confetti-piece"></div>
          <div class="confetti-piece"></div>
          <div class="confetti-piece"></div>
          <div class="confetti-piece"></div>
          <div class="confetti-piece"></div>
          <div class="confetti-piece"></div>
          <div class="confetti-piece"></div>
          <div class="confetti-piece"></div>
          <div class="confetti-piece"></div>
          <div class="confetti-piece"></div>
          <div class="confetti-piece"></div>
          <div class="confetti-piece"></div>
        </div>
        <p class="ra__modal-title--heading">
          The event will start in:
        </p>
        <RaCountDown :different="event.diff_milliseconds" @onFinish="goToEvent"/>
      </v-card-text>

      <v-card-actions class="ra__action-btn--spacing justify-center" >
        <v-btn
          v-if="!waiting"
          :to="{ name: 'event-lobby', params: { event: event.id } }"
          large
          depressed
          class="btn-primary"
        >
          Go Go Go!
        </v-btn>
        <v-btn
          v-else
          @click="closePopup"
          large
          depressed
          class="btn-primary"
        >
          Continue
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </v-app>
</template>

<script>
import FlipCountdown from 'vue2-flip-countdown'
import RaModalCloseButton from '~/components/general/RaModalCloseButton'
import RaCountDown from "./general/RaCountDown";
export default {
  name: 'RaEventWaitingPopup',
  components: {RaCountDown, FlipCountdown, RaModalCloseButton },
  data () {
    return {
      event: {},
      show: false,
      waiting : true
    }
  },
  computed : {
    userCurrentTime () {
      return this.event.user_current_time ? Math.trunc((new Date(this.event.user_current_time)).getTime() / 1000) : Math.trunc((new Date()).getTime() / 1000)
    },
    eventStartDate () {
      return new Date(this.event.start_time)
    }
  },
  created () {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'permission/SHOW_WAITING_POPUP') {
        this.event = state.permission.event
        this.show = true
      }
    })
  },
  methods: {
    closePopup () {
      this.show = false
      this.$router.push({ name: 'dashboard' });
    },
    goToEvent () {
      this.waiting = false
    }
  }
}
</script>

<style scoped>

</style>
