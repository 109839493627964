<template>
  <div class="side__wrapper-mobile">
    <div class="side__panel-block">
      <router-link
        ref="ref-form-forgot-password-link"
        class="side__panel-back"
        :to="{ name: 'dashboard' }"
      >
        <v-icon class="side__panel-back--icon">mdi-chevron-left</v-icon> Dashboard
      </router-link>
      <div class="d-flex justify-space-between align-center">
        <h5 class="side__panel-title">
          {{ $t("events") }}
        </h5>
      </div>
    </div>
    <v-list class="side__panel">
      <v-list-group
        v-for="event in events"
        :key="event.title"
        :value="isFirstEvent(event.id)"
        :ref="getListGroupRef(event.id)"
        :prepend-icon="event.action"
        no-action
        class="side__panel-color"
      >
        <template #activator>
          <v-list-item
            exact
            link
            :to="{ name: 'event.dashboard', params: { event: event.id } }"
          >
            <v-list-item-content>
              <v-list-item-title
                class="side__panel-menus--items"
                :title="event.name"
              >
                {{ event.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list-item
          link
          :to="{ name: 'event.dashboard', params: { event: event.id } }" exact
          @click="collapseMenu"
        >
          <v-icon left>mdi-calendar-account</v-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("event_builder") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          :to="{ name: 'event.host', params: { event: event.id } }" exact
          @click="collapseMenu"
        >
          <v-icon left>mdi-clipboard-account</v-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("event_host_menu") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          :to="{ name: 'event.poll', params: { event: event.id } }" exact
          @click="collapseMenu"
        >
          <v-icon left>mdi-poll-box</v-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("event_polls") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          :to="{ name: 'event.survey', params: { event: event.id } }" exact
          @click="collapseMenu"
        >
          <v-icon left>mdi-account-group</v-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("event_survey") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          :to="{ name: 'event.email', params: { event: event.id } }" exact
          @click="collapseMenu"
        >
          <v-icon left>mdi-email-variant</v-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("event_email") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          :to="{ name: 'event.analytics', params: { event: event.id } }" exact
          @click="collapseMenu"
        >
          <v-icon left>mdi-chart-bar-stacked</v-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("event_analytics") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          :to="{ name: 'event.event-sales', params: { event: event.id } }" exact
          @click="collapseMenu"
        >
          <v-icon left>mdi-chart-areaspline</v-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("event_sales") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <PublicEventPageItem :from-mobile="true"/>
      </v-list-group>
    </v-list>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PublicEventPageItem from './publicEventPageItem'
export default {
  name: 'MobileNavEventList',
  components: { PublicEventPageItem },
  data () {
    return {
      eventListLoaded: true
    }
  },
  computed: {
    ...mapGetters('events', ['events'])
  },
  watch: {
    '$route' (to, from) {
      if (from.name === 'events') {
        this.eventListLoaded = false
        this.$refs['v_list_group_' + this.events[0].id][0].isActive = true
      }
    }
  },
  async created () {
    if (this.events && this.events.length === 0) {
      this.collapseMenu()
      this.showLoader(true)
      await this.getEvents().then(() => {
        this.eventListLoaded = false
        this.events.map((event) => {
          this.$refs['v_list_group_' + event.id][0].isActive = event.id === this.$route.params.event
        })
      })
      this.showLoader(false)
    }
    this.$root.$on('update-event-list', async () => {
      this.showLoader(true)
      await this.getEvents()
      this.showLoader(false)
    })
  },
  methods: {
    ...mapActions('events', [
      'getEvents'
    ]),
    isFirstEvent (id) {
      // console.log(' Event loaded ', this.eventListLoaded)
      return this.eventListLoaded && id === this.$route.params.event
    },
    getListGroupRef (id) {
      return 'v_list_group_' + id
    },
    collapseMenu () {
      this.$root.$emit('collapse-menu')
    }
  }
}
</script>

<style>
</style>
