import Vue from 'vue'
import axios from 'axios'

const state = {
    companyStaff: false
}

const getters = {
    companyStaff: state => state.companyStaff,
}

const mutations = {
  setCompanyStaffStatus: (state, data) => {
      state.companyStaff = data
  },   
}

const actions = {
  setCompanyStaffStatus: (context, data) => {
    context.commit('setCompanyStaffStatus', data)
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}