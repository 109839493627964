<template>
  <div class="d-flex mr-1 mr-md-3">
    <template v-if="enableChat == 1">
    <div>
      <v-btn
        icon
        type="button"
        aria-label="Chat Envelop"
      >
        <v-img
          id="v-step-14-chatenvelop"
          width="32"
          class="chat_envelope__pointer chat__messagegroup header__icon-button"
          :src="($vuetify.theme.dark) ? 'https://reattendance-video-public.s3.eu-west-2.amazonaws.com/Misc/chat-icon.svg' : 'https://reattendance-video-public.s3.eu-west-2.amazonaws.com/Misc/chat-icon.svg'"
          :class="{ chat_envelope__pulse: displayEnvelopeAnimation }"
          @animationend="removeEnvelopeAnimationClass()"
          @click="showChatList"
        />
      </v-btn>
      <!-- Message list -->
      <div
        class="chat__messagegroup-list"
        :class="{ chat_envelope__hide_list: !displayChatList }"
        v-click-outside="hideChatList"
      >
        <div class="chat__messagegroup-list--body">
          <v-list>
            <template v-if="chatRooms.length > 0">
              <v-list-item class="chat__messagegroup-attendee" v-for="(room, index) in chatRooms" :key="index">
                <v-list-item-title @click="displayChatConversation(room.room)">
                    <div class="d-flex align-center">

                      <template v-if="room.room.session_type == 'P2P'">
                        <figure class="chat__profile-1">
                          <RaMediaDisplay
                            :is-profile-image="true"
                            profile-image-size="small"
                            :media="getUserMedia(room)"
                          />
                        </figure>
                      </template>

                      <template v-if="room.room.session_type == 'event-chat'">
                        <figure class="chat__profile-1">
                        <v-img
                          src="https://reattendance-video-public.s3.eu-west-2.amazonaws.com/Misc/event-chat.png"
                          class="chat__popup-profileimg"
                        ></v-img>
                        </figure>
                      </template>

                      <template v-if="room.unread_messages > 0">
                        <span class="chat_envelope__pointer chat_envelope__unread">{{ room.title }}{{ room.unread_message_label }}</span>
                      </template>
                      <template v-else>
                        <span class="chat_envelope__pointer">{{ room.title }}{{ room.unread_message_label }}</span>
                      </template>

                    </div>
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-else>
              <v-list-item>
                {{ $t('chat_no_messages') }}
              </v-list-item>
            </template>
          </v-list>
        </div>
        <v-divider class="mt-0" />
        <div class="d-flex justify-space-between pl-4">
          <div class="chat_notifications_label">{{ $t('chat_audio_notifications') }}</div>
          <v-switch
            v-model="user.play_audio_notifications"
            inset
            color="#199ED9"
          ></v-switch>
        </div>
      </div>
    </div>

    <div class="d-block ml-4 ml-md-1" v-if="totalUnreadMessages > 0"><v-badge color="red" :content="totalUnreadMessages" class="d-block"></v-badge></div>
    </template>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import RaMediaDisplay from '~/components/media/RaMediaDisplay'

export default {
  name: 'RaWebrtcChatEnvelopeDisplay',
  components: { RaMediaDisplay },
  data () {
    return {
      audioNotification: null,
      audioNotificationBlock: false,
      audioNotificationTime: process.env.MIX_CHAT_AUDIO_DELAY || 10,
      chatRooms: [],
      cancelClickOutside: false,
      displayEnvelopeAnimation: false,
      displayChatList: false
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('events', ['singleEvent']),
    ...mapGetters('webrtc', ['attendees','getRoomUser']),

    enableChat () {
      return this.singleEvent.chat_is_on
    },
    totalUnreadMessages () {
      var unreadMessages = 0

      if (this.chatRooms && this.chatRooms.length) {
        for (var room of this.chatRooms) {
          unreadMessages += parseInt(room.unread_messages)
        }
      }

      return unreadMessages
    }
  },
  directives: {
    'click-outside': {
      bind: function(el, binding, vNode) {
        const bubble = binding.modifiers.bubble
        const handler = (e) => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
          	binding.value(e)
          }
        }
        el.__vueClickOutside__ = handler
        document.addEventListener('click', handler)
			},

      unbind: function(el, binding) {
        document.removeEventListener('click', el.__vueClickOutside__)
        el.__vueClickOutside__ = null
      }
    }
  },
  methods: {
    ...mapActions('webrtc', ['setAttendee','storeAttendee']),

    clearDisplayCount (updateRoom) {
      let unreadRoomList = []
      let readRoomList = []
      let fullRoomList = []
      let updatedRoom = null

      if (this.chatRooms && this.chatRooms.length) {
        for (var currentRoom of this.chatRooms) {
          if (currentRoom.room.room_id == updateRoom){
            currentRoom.unread_messages = 0
            currentRoom.unread_message_label = ''
            updatedRoom = currentRoom
          } else {
            if(currentRoom.unread_messages > 0){
              unreadRoomList.push(currentRoom)
            } else {
              readRoomList.push(currentRoom)
            }
          }
        }
        if (updatedRoom !== null){
          readRoomList.unshift(updatedRoom)
        }
        fullRoomList = unreadRoomList.concat(readRoomList)
        this.chatRooms = fullRoomList
      }
    },
    displayChatConversation (room) {
      this.hideChatList()
      this.$root.$emit('openChatPanel', room)
    },
    getUserMedia (currentRoom) {
      if(currentRoom.room.session_type == 'P2P'){
        let user = this.getRoomUser(currentRoom.room.receiver_id)
        if(user == undefined){
          try {
            axios.get('webrtc/user/' + currentRoom.room.receiver_id + '/chat-info-query').then(response => {
              user = response.data.user
              this.storeAttendee(user)
              return user.attachments
            })
          } catch (err) {
            console.log(err)
          }
          return {}
        } else {
          return user.attachments
        }
      }
    },
    hideChatList () {
      if(this.cancelClickOutside == false){
        this.displayChatList = false
      } else {
        this.cancelClickOutside = false
      }
    },
    playAudioNotification () {
      if(this.user.play_audio_notifications == 1 && this.audioNotificationBlock == false){
        this.audioNotificationBlock = true
        this.audioNotification.play()
        this.startAudioNotificationTimer()
      }
    },
    removeEnvelopeAnimationClass () {
      this.displayEnvelopeAnimation = false
    },
    resetAudioNotification () {
        this.audioNotificationBlock = false
    },
    showChatList () {
      if(this.displayChatList == false){
        this.cancelClickOutside = true
        this.displayChatList = true
      }
    },
    startAudioNotificationTimer () {
      setTimeout(this.resetAudioNotification, (this.audioNotificationTime * 1000));
    }
  },
  mounted () {
    this.audioNotification = new Audio('https://reattendance-video-public.s3.eu-west-2.amazonaws.com/Sounds/ra-incoming-chat-alert.mp3');

    this.$watch('user.play_audio_notifications', value => {
      if(this.user){
        axios.put(`user/${this.user.id}`, this.user).then(response => {
        }).catch(error => { return })
      }
    })

    this.$root.$on('chatEnvelope-updateDisplay', (chatRooms) => {
      this.chatRooms = chatRooms

      if(chatRooms.length > 0){
        let messageCount = 0;
        for(let room of chatRooms){
          messageCount += room.unread_messages
        }
        if(messageCount > 0){
          this.displayEnvelopeAnimation = true
          this.playAudioNotification()
        }
      }
    }),

    this.$root.$on('chatEnvelope-clearDisplayCount', (room) => {
      this.clearDisplayCount(room)
    })
  }
}
</script>
<style scoped>
.chat_envelope__unread{
  font-weight: bold;
}
.chat_envelope__pointer{
  cursor: pointer
}
.chat_envelope__pulse{
  width: 44px;
  height: 44px;
  animation: chat_envelope_animscale 0.3s alternate ease-in-out;
}
.chat__envelope-attendee{
  margin-bottom: 2px;
}
.chat_notifications_label{
  font-size: 14px;
  line-height: 25px;
  margin-right: 1rem;
}
.chat_envelope__hide_list{
  display: none;
}
@keyframes chat_envelope_animscale {
  0%   {transform: scale(1);}
  100% {transform: scale(1.2);}
}
</style>