import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import pick from 'lodash/pick'

// TODO : Process to retrieve api keys securely (not in global)
const API_KEY_BUGSNAG = process.env.MIX_API_KEY_BUGSNAG
const hasMemoryInfoSupport = window.performance && window.performance.memory
const releaseStage = process.env.MIX_APP_ENV
const enabledReleaseStages = ['development', 'production', 'staging']
const isLoggingDisabled = !enabledReleaseStages.includes(releaseStage)
const version = window.appVersion ?? 'none'
const versionNumber = version.split('-')[0]

Bugsnag.start({
  maxBreadcrumbs: 40,
  apiKey: API_KEY_BUGSNAG,
  enabledReleaseStages,
  releaseStage: releaseStage,
  collectUserIp: false,
  appVersion: versionNumber,
  ...(isLoggingDisabled && {
    logger: null
  }),
  plugins: [new BugsnagPluginVue()],
  onError (event) {
    if (!hasMemoryInfoSupport) {
      return
    }
    event.addMetadata(
      'memoryUsage',
      pick(window.performance.memory, ['totalJSHeapSize', 'usedJSHeapSize', 'jsHeapSizeLimit'])
    )
  }
})

export default Bugsnag
