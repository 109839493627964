<template>
      <v-dialog
        transition="dialog-bottom-transition"
        max-width="700"
        v-model="helpIconModal"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-list-item class="side__drawer-menu--items"
          v-bind="attrs"
          v-on="on"
          :value="isModalClosed"
          >
            <v-list-item-icon>
              <v-img
                src="/img/dashboard/nav5.svg"
                class="side__drawer-icon"
              />
            </v-list-item-icon>
            <v-list-item-title>
              Help Centre
          </v-list-item-title>
        </v-list-item>
        </template>
        <template v-slot:default="dialog">
          <v-card class="help__modal">
            <div class="help__modal-head">
              <div class="d-flex justify-space-between">
                <div>
                  <h2 class="help__modal-title">
                    Hi!
                  </h2>
                  <p>Need a little help?</p>
                </div>
                <ra-modal-close-button @close-modal="onCloseModal" />
              </div>
            </div>
            <v-card-text>
              <div class="help__modal-body">
                <!-- Resource center -->
                <h2 class="help__modal-title">
                  {{$t('event_help_modal.resource_center')}}
                </h2>
                <p>{{$t('event_help_modal.get_started_with_these_tools_below_or_see')}}<a href="#!">{{$t(`event_help_modal.what_s_new`)}}</a></p>
                <v-row>
                  <!-- <v-col cols="6" md="4">
                    <div class="help__modal-option">
                      <v-img
                        src="/img/help/onboarding.svg"
                        class="help__modal-option--img"
                      />
                      <p class="help__modal-option--title">{{$t('event_help_modal.onboarding')}}</p>
                    </div>
                  </v-col> -->
                  <!-- <v-col cols="6" md="4">
                    <div class="help__modal-option">
                      <v-img
                        src="/img/help/videos.svg"
                        class="help__modal-option--img"
                      />
                      <p class="help__modal-option--title">{{$t('event_help_modal.videos')}}</p>
                    </div>
                  </v-col> -->
                  <v-col cols="6" md="4">
                    <a  :href="url('kb')" target='_blank'>
                        <div class="help__modal-option ">
                          <v-img
                            src="/img/help/docs.svg"
                            class="help__modal-option--img"
                          />
                          <p class="help__modal-option--title">{{$t('event_help_modal.docs_/_faq')}}</p>
                        </div>
                      </a>
                  </v-col>
                  <v-col cols="6" md="4">
                    <a  :href="url('blogs')" target='_blank'>
                    <div class="help__modal-option">
                      <v-img
                        src="/img/help/templates.svg"
                        class="help__modal-option--img"
                      />
                      <p class="help__modal-option--title">Blog</p>
                    </div>
                  </a>
                  </v-col>
                  <!-- <v-col cols="6" md="4">
                    <div class="help__modal-option">
                      <v-img
                        src="/img/help/webinar.svg"
                        class="help__modal-option--img"
                      />
                      <p class="help__modal-option--title">{{$t('event_help_modal.webinar')}}</p>
                    </div>
                  </v-col> -->
                  <v-col cols="6" md="4">
                    <a  :href="url('book-a-demo')" target='_blank'>
                    <div class="help__modal-option">
                      <v-img
                        src="/img/help/demo.svg"
                        class="help__modal-option--img"
                      />
                      <p class="help__modal-option--title">{{$t('event_help_modal.demo')}}</p>
                    </div>
                  </a>
                  </v-col>
                  <!-- <v-col cols="6" md="4">
                    <div class="help__modal-option">
                      <v-img
                        src="/img/help/community.svg"
                        class="help__modal-option--img"
                      />
                      <p class="help__modal-option--title">{{$t('event_help_modal.community')}}</p>
                    </div>
                  </v-col> -->
                  <!-- <v-col cols="6" md="4">
                    <div class="help__modal-option">
                      <v-img
                        src="/img/help/glossary.svg"
                        class="help__modal-option--img"
                      />
                      <p class="help__modal-option--title">{{$t('event_help_modal.glossary')}}</p>
                    </div>
                  </v-col> -->
                  <!-- <v-col cols="6" md="4">
                    <div class="help__modal-option">
                      <v-img
                        src="/img/help/feature.svg"
                        class="help__modal-option--img"
                      />
                      <p class="help__modal-option--title">{{$t('event_help_modal.request_a_feature')}}</p>
                    </div>
                  </v-col> -->
                </v-row>

                <!-- Support -->
                <h2 class="help__modal-title help__modal-option--title2">
                  {{$t('event_help_modal.support')}}
                </h2>
                <v-row>
                  <v-col cols="6" md="4">
                    <a  :href="url('contact-us')" target='_blank'>
                    <div class="help__modal-option">
                      <v-img
                        src="/img/help/connect.svg"
                        class="help__modal-option--img"
                      />
                      <p class="help__modal-option--title">{{$t('event_help_modal.connect_with_support')}}</p>
                    </div>
                  </a>
                  </v-col>
                  <!-- <v-col cols="6" md="4">
                    <div class="help__modal-option">
                      <v-img
                        src="/img/help/book.svg"
                        class="help__modal-option--img"
                      />
                      <p class="help__modal-option--title">{{$t('event_help_modal.book_a_consultation')}}</p>
                    </div>
                  </v-col> -->
                  <v-col cols="6" md="4">
                    <a  :href="url('contact-us')" target='_blank'>
                    <div class="help__modal-option">
                      <v-img
                        src="/img/help/bug.svg"
                        class="help__modal-option--img"
                      />
                      <p class="help__modal-option--title">{{$t('event_help_modal.submit_a_bug')}}</p>
                    </div>
                    </a>
                  </v-col>
                </v-row>
              </div>

              <!-- Still need help? -->
              <!-- <div class="help__modal-action">
                <h2 class="help__modal-title">
                  {{$t('event_help_modal.still_need_help')}}<v-icon color="#199ED9">mdi-
                    -right</v-icon>
                </h2>
              </div> -->
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>
</template>

<script>
import NotificationContainer from '~/components/NotificationContainer'
import RaModalCloseButton from "~/components/general/RaModalCloseButton";

export default {
  components: {
    NotificationContainer,
    RaModalCloseButton,
  },
  data () {
    return {
      helpIconModal: false
    }
  },
  computed: {
    isModalClosed () {
      return this.helpIconModal
    },
  },
  methods: {

    onCloseModal() { 
      this.helpIconModal = false
    },
    url (path) {
      return window.config.appUrl + path
    }

  }
}
</script>
