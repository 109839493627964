<template />

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'RaWebrtcChatEnvelope',
  components: { },
  data () {
    return {
      chatRooms: []
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('webrtc', ['joined']),
  },
  methods: {
    addChatMessage (data) {
      let rooms = this.chatRooms
      let newRooms = []
      if (rooms && rooms.length) {
        for (var room of rooms) {
          if (room.room.room_id == data.room){
            room.unread_messages++
            room.unread_message_label = ' (' + room.unread_messages + ')'
            newRooms.unshift(room)
          } else {
            newRooms.push(room)
          }
        }
        this.chatRooms = newRooms
        this.updateDisplay(newRooms)
      }
    },
    addChatRoom (newRoom) {
      let rooms = this.chatRooms
      let roomExists = 0;
      if (rooms && rooms.length) {
        for (var room of rooms) {
          if (room.room.room_id == newRoom.id){
            roomExists = 1
          }
        }
      }
      if (roomExists == 0){
        let tempRoom = {
          room: newRoom,
          title: newRoom.chat_name,
          unread_message_label: '',
          unread_messages: 0
        }
        rooms.unshift(tempRoom)
        this.updateDisplay(rooms)
      }
    },
    getChatRoomsUnread () {
      const response = axios.get('webrtc/get-chat-rooms-unread/' + this.$route.params.event).then(response => {
        let rooms = response.data.rooms
        this.processChatRooms(rooms)
      }).catch((err) => {
          console.log(err)
      })
    },
    processChatRooms (rooms) {
      let roomList = []
      let unread = 0
      let unreadLabel = ''

      if (rooms && rooms.length) {
        for (var currentRoom of rooms) {
          if(currentRoom.session_type == 'P2P' || currentRoom.session_type == 'event-chat'){
            unread = 0
            unreadLabel = ''
            if(currentRoom.unread_messages > 0){
              unread = currentRoom.unread_messages
              unreadLabel = ' (' + currentRoom.unread_messages + ')'
            }

            switch(currentRoom.session_type){
              case 'P2P':
                currentRoom.chat_name = currentRoom.room_name
                currentRoom.title_class = 'rachat__p2p'
                break
              case 'event-chat':
                currentRoom.chat_name = currentRoom.room_name
                currentRoom.title_class = 'rachat__event-chat'
                break
            }

            var entry = {
              title: currentRoom.room_name,
              unread_messages: unread,
              unread_message_label: unreadLabel,
              room: currentRoom
            }
            roomList.push(entry)
            this.$root.$emit('webrtcJoinRoomSilent', currentRoom.room_id)
          }
        }
      }

      this.chatRooms = roomList
      this.updateDisplay(roomList)
    },
    updateDisplay (rooms) {
      this.$root.$emit('chatEnvelope-updateDisplay', rooms)
    }
  },
  mounted(){
    this.$root.$on('roomMessage-sendChatMessage', (data) => {
      this.addChatMessage(data)
    }),
    this.$root.$on('chatEnvelope-addChatRoom', (room) => {
      this.addChatRoom(room)
    }),
    this.$root.$on('userMessage-initiateChatRequest', (data) => {
      this.getChatRoomsUnread()
    })
  },
  watch: {
    joined: {
      immediate: true,
      deep: true,
      handler (newVal, oldVal) {
        if(newVal == true){
          this.getChatRoomsUnread()
        }
      }
    }
  }
}
</script>
