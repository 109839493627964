import axios from 'axios'
import mediaHandler from '~/plugins/media/mediaHandler'
const state = {
  sponsors: [],
  isBusy: false
}

const getters = {
  sponsors: state => state.sponsors,
  isBusy: state => state.isBusy
}

const mutations = {
  set: (state, sponsors) => {
    state.sponsors = sponsors
  },
  setBusy: (state, flag) => {
    state.isBusy = flag
  }
}

const actions = {
  getSponsors: async (context, { modelId, model }) => {
    context.commit('setBusy', true)
    await axios.post('event-sponsor-info', { model_id: modelId, model: model})
      .then(({ data }) => {
        const attachmentsKey = 'attachments'
        const mediaModel = 'Sponsor'
        data.forEach(sponsor => {
          // Get attachments for this sponsor
          const attachments = attachmentsKey in sponsor ? sponsor[attachmentsKey] : []
          // Transform attachments into media object, append to sponsor
          sponsor.media = mediaHandler.getAllMediaForModel(mediaModel, attachments)
        })
        context.commit('set', data)
      }).finally(() => {
        context.commit('setBusy', false)
      })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
