<template>
  <v-overlay
    :absolute="false"
    :value="isDataLoading"
    class ="overlay__global-main"
  >
    <v-flex class="text-center">
      <v-img class="mx-auto loader-img" src="/img/loader.gif" />
    </v-flex>
  </v-overlay>
</template>
<script>
export default {
  name: 'RaLoadingSpinner'
}
</script>
