import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue'
import Vuetify from 'vuetify'


Vue.use(Vuetify)

const opts = {
}

export default new Vuetify(opts)
