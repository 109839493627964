import axios from 'axios'

const state = {
  token: null,
  meetToken: null
}

const getters = {
  token: state => state.token,
  meetToken: state => state.meetToken
}

const mutations = {
  set: (state, token) => {
    state.token = token
  },
  meetSet: (state, meetToken) => {
    state.meetToken = meetToken
  },
}

const actions = {
  async getMeetToken (context, tokenType) {
    try {
      const resp = await  axios.get(`event-player/${tokenType}/get-meet-token`)
      .then(({ data }) => {
        context.commit('meetSet', data)
      })
    } catch (err) {
      console.log(err)
    }
  },
  getToken: async (context, playerId) => {
    return axios.get(`event-player/${playerId}/get-token`)
      .then(({ data }) => {
        context.commit('set', data)
      })
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}