/**
 * Accepts a media item and returns useable media e.g. url
 * TODO : Bucket path comes from config/env
 * dev bucket - 'https://reattendance-video-dev.s3.eu-west-2.amazonaws.com/';
 */
import awsParameters from '~/plugins/config/awsParameters'
/**
 * Configuration object containing default images sizes and properties, locations
 */
import imageSizes from '~/plugins/config/imageSizes'
import attachmentLocations from '~/plugins/config/attachmentLocations'
import mediaSettings from '~/plugins/config/mediaSettings'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import mediaSize from '~/plugins/media/mediaSize'

const bucketName = awsParameters.aws.bucket_name
const bucketRegion = awsParameters.aws.region
const bucketSuffix = 'amazonaws.com'
const cloudfrontPath = awsParameters.aws.cloudfront_root
const cloudfrontVideoRoot = awsParameters.aws.cloudfront_video_root
const originalImageSize = 'original'
const lazyLoadSourceSize = 'lls'
// Placeholder constants
const bucketRoot = awsParameters.aws.bucket_root // "file-store/dev"
const bucketSystemFolder = awsParameters.aws.system_folder // "/system"
const bucketPlaceholderFolder = awsParameters.aws.placeholder_folder // "/placeholder/"
const presignedUrl = awsParameters.aws.presigned_url
// Embedded video providers
const videoProviders = mediaSettings.external_video_providers
//
/**
 * Get properties for image size
 * See config/image-sizes.php
 *
 * @param size
 * @returns {*}
 */
function getSizes (size) {
  return imageSizes[size]
}

/**
 * Covert media request object to base64 string
 * @param obj
 * @returns {string}
 */
function getEncoded (obj) {
  return Buffer.from(JSON.stringify(obj)).toString('base64')
}

/**
 * Return extend object
 * Extend takes place after crop and resize, we will extend each image size by a percentage
 * The extend object can be empty (no extension), or will have a property for each side as 0 or an integer % value
 * @param top - crop start
 * @param left - crop start
 * @param height - crop height
 * @param width - crop width
 * @param naturalHeight
 * @param naturalWidth
 * @returns {{top: (number|number), left: (number|number), bottom: (number|number), right: (number|number)}|{}}
 */
function getExtend ({ top, left, height, width, naturalHeight = 0, naturalWidth = 0 }) {
  // Legacy images do not have naturalHeight or naturalWidth properties
  if (!naturalHeight || !naturalWidth) {
    return {}
  }
  const exTop = top // If -ve, image extends top
  const exBottom = (top + height) - naturalHeight // If +v, image extends bottom
  const exLeft = left // If -ve, image extends left
  const exRight = (left + width) - naturalWidth // If +v, image extends right
  // Does crop extend image in any direction?
  if ((exTop >= 0 && exBottom <= 0 && exLeft >= 0 && exRight <= 0)) {
    return {} // Image not extended
  }
  return {
    top: exTop < 0 ? Math.round((Math.abs(exTop) / naturalHeight) * 100) : 0,
    bottom: exBottom > 0 ? Math.round((exBottom / naturalHeight) * 100) : 0,
    left: exLeft < 0 ? Math.round((Math.abs(exLeft) / naturalWidth) * 100) : 0,
    right: exRight > 0 ? Math.round((exRight / naturalWidth) * 100) : 0
  }
}

/**
 * Crop (extract) the image
 * Ensure that if there is an image extend, the extraction is within image boundaries
 * @param top
 * @param left
 * @param height
 * @param width
 * @param naturalHeight
 * @param naturalWidth
 * @param extendObject
 */
function getExtract ({ top, left, height, width, naturalHeight = 0, naturalWidth = 0, extendObject }) {
  // If we are not extending the image, then process as per normal extract
  if (isEmpty(extendObject)) {
    return {
      left: left,
      top: top,
      height: height,
      width: width
    }
  }
  // If the crop exceeds the image size in all directions, then there is no extract action
  if (left <= 0 && top <= 0 && (height + top) >= naturalHeight && (width + left) >= naturalWidth) {
    return {}
  }
  let extractWidth = width
  let extractHeight = height
  // Don't allow extract dimensions to exceed image dimensions
  if (left < 0) {
    extractWidth = width + left // Reduce width by left extension
    left = 0
  }
  if ((width + left) >= naturalWidth) {
    extractWidth = naturalWidth - left // Reduce width by right extension
  }
  if (top < 0) {
    extractHeight = height + top // Reduce height by top extension
    top = 0
  }
  if ((height + top) >= naturalHeight) {
    extractHeight = naturalHeight - top // Reduce height by bottom extension
  }
  return {
    left: left,
    width: extractWidth,
    top: top,
    height: extractHeight
  }
}

/**
 * Create the resize dimensions for the cropped image.
 * If we are going extend the image, then reduce resize dimensions
 *
 * @param resizeWidth - target width (after extend)
 * @param resizeHeight - target height (after extend)
 * @param resizeFit
 * @param extendObject
 */
function getResize ({ resizeWidth, resizeHeight, resizeFit, extendObject }) {
  if (isEmpty(extendObject)) {
    // No extend - Return unadjusted resize object
    return {
      width: resizeWidth,
      height: resizeHeight,
      fit: resizeFit
    }
  }
  // Adjust size based on % extend (int)
  const totalWidthPercent = extendObject.left + extendObject.right + 100
  const totalHeightPercent = extendObject.top + extendObject.bottom + 100
  return {
    width: Math.round((resizeWidth / totalWidthPercent) * 100),
    height: Math.round((resizeHeight / totalHeightPercent) * 100),
    fit: resizeFit
  }
}

/**
 * Create extend object to pass to sharp, create pixel extension in each axis
 *
 *
 */
function getExtendingPixels ({ resizeWidth, resizeHeight, top, bottom, left, right }) {
  return {
    top: !top ? 0 : Math.round(resizeHeight * top / 100),
    bottom: !bottom ? 0 : Math.round(resizeHeight * bottom / 100),
    left: !left ? 0 : Math.round(resizeWidth * left / 100),
    right: !right ? 0 : Math.round(resizeWidth * right / 100),
    background: { r: 0, g: 0, b: 0, alpha: 0 }
  }
}
/**
 * Create crop, extend and resize information for image
 *
 * processImage = false - We will not crop or extend original or placeholder images
 *
 * naturalHeight: 1800  - legacy data may not include naturalHeight or naturalWidth
 * naturalWidth: 1440
 * height: 575  - crop height
 * width: 1916  - crop width
 * x: -254 - crop start
 * y: 699 - crop start
 */
function getImageTransformations (resizeObject, cropData, processImage) {
  let extendObject = {}
  const edits = {}
  //
  // Append extract object if set (crop)
  if (!isEmpty(cropData) && processImage) {
    extendObject = getExtend({ ...cropData })
    const extractObject = getExtract({ ...cropData, extendObject })
    if (!isEmpty(extractObject)) {
      edits.extract = extractObject
    }
  }
  //
  // Append resize object
  if (!isEmpty(resizeObject)) {
    edits.resize = getResize({ resizeWidth: resizeObject.width, resizeHeight: resizeObject.height, resizeFit: resizeObject.fit, extendObject })
    // If any resize dimension is < 1 then do not create an edit
    if (edits.resize.width < 1 || edits.resize.height < 1) {
      return {}
    }
  }
  //
  // Append extend object
  if (!isEmpty(extendObject) && processImage) {
    edits.png = {}
    edits.extend = getExtendingPixels({ resizeWidth: resizeObject.width, resizeHeight: resizeObject.height, ...extendObject })
  }
  return edits
}
/**
 *  Create URL for image
 *  1. Build mediaReguest json object
 *  2. Convert to base64string
 *  3. Append to cloudfront root address
 */
/**
 *
 * @param s3ObjectName
 * @param resizeObject
 * @param cropData
 * @param processImage - we do not extend or extract original or placeholder images
 * @returns {string}
 */
function getUrl (s3ObjectName, resizeObject = null, cropData = null, processImage = true) {
  const mediaRequest = {
    bucket: bucketName,
    key: s3ObjectName
  }
  const edits = getImageTransformations(resizeObject, cropData, processImage)
  // If we expect to have edits and we don't, then don't return a url
  if (isEmpty(edits) && processImage) {
    return ''
  }
  mediaRequest.edits = edits
  // URL = cloudfrontRoot + request object to base64
  return cloudfrontPath + getEncoded(mediaRequest)
}

/**
 * Image media transformation - Get url image set for image media
 * @param mediaItem
 * @param formats
 * @param aspectRatio
 * @returns [] array of media url object indexed by size
 */
function processImageOrPdf (mediaItem, formats, aspectRatio) {
  const urls = {}
  // for each size, create a cloudfront URL
  formats.forEach((outputFormat) => {
    const imageSize = getSizes(outputFormat.size)
    const resize = outputFormat.size === originalImageSize
      ? null
      : {
          width: imageSize.width,
          height: imageSize.width / aspectRatio,
          fit: Object.prototype.hasOwnProperty.call(outputFormat, 'fit') ? outputFormat.fit : imageSize.fit
        }
    // Create array of image urls indexed by size
    const s3ObjectName = mediaItem.type === 'pdf' ? mediaItem.pdf_s3_object_name : mediaItem.s3_object_name
    urls[outputFormat.size] = getUrl(s3ObjectName, resize, null, false)
  })
  return urls
}

/**
 * Image media transformation - Get url image set for image media
 * @param mediaItem
 * @returns string - url to video
 */
function processVideo (mediaItem) {
  let url = ''
  if (isEmpty(mediaItem.embed_object)) {
    // Uploaded video - create url for bucket
    url = awsS3BucketURL(mediaItem.s3_object_name)
  } else {
    // External video - link = baseUrl for provider (e.g. youtube) + embedded link
    url = videoProviders[mediaItem.embed_object_type].base_url + mediaItem.embed_object
  }
  return url
}

/**
 * Get link to AWS media
 * Object includes path and user id
 */
function awsS3BucketURL (object) {
  return cloudfrontVideoRoot + object
}

/**
 * @param location
 * @param model
 * @returns {{}|*}
 */
const attachmentLocation = ({ location, model }) => {
  if (!(model in attachmentLocations && location in attachmentLocations[model])) {
    console.log(`No attachment location found for ${model}/${location}`)
    return {}
  }
  return attachmentLocations[model][location]
}
/**
 * We process media items for display in the owner galleries
 * These will always use the contain fit method for these images
 *
 * @param mediaItems - array of mediaItems || single mediaItem object
 *
 * @param isProfile
 * @param outputFormats - array of sizes - library uses original and medium
 *      for media manager thumbnails will be displayed with contain
 *      for general use, images will be displayed with cover
 *      [{ size: 'thumb', fit: 'contain' }]
 * @returns array || single object - transformed mediaItems including urls
 *  urls are indexed by size e.g. small : 'https//:myurltosmallimage.jpg'
 *                                large : 'https//:myurltolargeimage.jpg'
 */
const transformMediaItems = (mediaItems, isProfile = false, outputFormats = [{ size: 'thumb', fit: 'cover' }]) => {
  // If we receive a single mediaItem object, convert to array for processing
  const aspectRatio = isProfile ? 1 : 16 / 9
  const isArray = Array.isArray(mediaItems)
  if (!isArray && isEmpty(mediaItems)) {
    // If we are passed a single empty object, then just return it
    return {}
  }
  mediaItems = isArray ? mediaItems : [mediaItems]
  // Process mediaItems array
  const mediaItemsTransformed = []
  mediaItems.forEach((mediaItem) => {
    const mediaItemTransformed = mediaItem
    // Process depending on media type
    switch (mediaItem.type) {
      case 'image':
      case 'pdf':
        mediaItemTransformed.urls = processImageOrPdf(mediaItem, outputFormats, aspectRatio)
        break
      case ('video'):
        mediaItemTransformed.url = processVideo(mediaItem)
        break
    }
    mediaItemsTransformed.push(mediaItemTransformed)
  })
  // Output object || array depending on input
  return isArray ? mediaItemsTransformed : mediaItemsTransformed[0]
}
/**
 * Get placeholder images for specified location
 * Returns an array of image urls for each of our defines size for the given model location
 * Model and location names must match our attachment-location config e.g.
 * model 'Event', 'EventExpo', 'User'
 * location 'primary', 'splashscreen', 'profile'
 *
 */
const getPlaceHolderForLocation = ({ model, location }) => {
  const media = {}
  const aspectRatio = isNaN(attachmentLocation({ model, location }).aspect_ratio) || attachmentLocation({ model, location }).aspect_ratio === 0 ? 1 : attachmentLocation({ model, location }).aspect_ratio
  media.isPlaceholder = true
  media.type = 'image'
  media.location = attachmentLocation({ location, model })
  media.altText = 'Reattendance placeholder image'
  media.aspectRatio = aspectRatio
  media.images = getMediaImages({
    s3Object: `${bucketRoot}${bucketSystemFolder}${bucketPlaceholderFolder}${attachmentLocation({ location, model }).placeholder}`,
    cropObject: {},
    isPlaceHolder: true
  })
  return media
}
/**
 * Get image urls for all sizes based on original image and crop
 *
 * @param mediaObject   -   s3Object: s3_object_name,
 *                          cropObject: crop_object
 * @returns {*}
 */

const getMediaImages = (mediaObject) => {
// Loop through our global sizes object
  const images = {}
  Object.keys(imageSizes).forEach((size) => {
    const resize = size === originalImageSize
      ? null
      : {
          width: imageSizes[size].width,
          height: Math.round(imageSizes[size].width / mediaObject.aspectRatio),
          fit: imageSizes[size].fit
        }

    const resize2x = size === originalImageSize
      ? null
      : {
          width: imageSizes[size]['2x'].width,
          height: Math.round(imageSizes[size]['2x'].width / mediaObject.aspectRatio),
          fit: imageSizes[size]['2x'].fit
        }
    // Do not process placeholder, original image size or lazy load source (too tiny)
    const processImage = !mediaObject.isPlaceHolder && size !== originalImageSize && size !== lazyLoadSourceSize
    const x1url = getUrl(mediaObject.s3Object, resize, mediaObject.cropObject, processImage)
    const x2url = getUrl(mediaObject.s3Object, resize2x, mediaObject.cropObject, processImage)
    if (x1url || x2url) {
      images[size] = {}
      if (x1url) {
        images[size].x1 = {
          url: x1url,
          width: size === originalImageSize ? 99999 : imageSizes[size].width // TODO : Hopefully we'll never use original images in srcsets
        }
      }
      if (x2url) {
        images[size].x2 = {
          url: x1url,
          width: size === originalImageSize ? 99999 : imageSizes[size]['2x'].width // TODO : Hopefully we'll never use original images in srcsets
        }
      }
    }
  }) // End : sizes loop
  return images
}
/**
 * takes an array of mediaAttachments and converts them into a collection of urls
  {
    id : 1,
    type: 'App/Event',
    location: 'primary',
    mediaType : 'image',
    altText : '',
    images : {
      thumb : {
        width : '480w',
        url : '',
        2x : {
           width : '960w',
           url : '',
        }
      }
    }
  }
 A pair of URLs (normal and 2x size) will be returned for each size.  For original image size, these urls will be identical.
 *
 * @param mediaAttachment - single mediaAttachment with mediaItem
 * @returns media object or empty object
 */
const transformMediaAttachment = ({ attachment, location, model }) => {
  // Get aspect ratio for location
  const aspectRatio = isNaN(attachmentLocation({ model, location }).aspect_ratio) || attachmentLocation({ model, location }).aspect_ratio === 0 ? 1 : attachmentLocation({ model, location }).aspect_ratio
  // Create media object
  const media = {
    type: attachment.media_item.type
  }
  switch (media.type) {
    case 'image':
    case 'pdf':
      media.images = getMediaImages({
        s3Object: media.type === 'pdf' ? attachment.media_item.pdf_s3_object_name : attachment.media_item.s3_object_name,
        cropObject: attachment.crop_object,
        isPlaceHolder: false,
        model: model,
        location: location,
        aspectRatio: aspectRatio
      })
      break
    case 'video':
      media.video = {
        isEmbedded: !isEmpty(attachment.media_item.embed_object),
        url: processVideo(attachment.media_item),
        provider: attachment.media_item.embed_object_type,
        // eslint-disable-next-line no-prototype-builtins
        autoplay: attachmentLocations[model][location].hasOwnProperty('autoplay') ? attachmentLocations[model][location].autoplay : false
      }
      break
    default:
      console.log(`Unknown media type ${media.type} `)
  }
  // Add link to downloadable PDF media
  if (media.type === 'pdf') {
    media.pdfUrl = awsS3BucketURL(attachment.media_item.s3_object_name)
  }
  // Add common attachment fields

  return Object.assign(media, {
    attachableType: attachment.attachable_type,
    location: attachment.attachment_location,
    altText: attachment.alt_text,
    aspectRatio: aspectRatio
  })
}
/**
 * Converts a cropObject output by cropper.js into a Extract object that can be consumed by the AWS Serverless image handler
 * Input crop object
 * {
 *     "x": -0.3555555555558,
 *     "y": 222.69999999999996,
 *     "width": 2381.8345431857633,
 *     "height": 1339.7819305419919
 *   }
 * Output extract object
 *
 * {
 *     "left": 0,                        // Remove negative values, change key names
 *     "top": 223,                       // Round to the nearest integer value
 *     "width": 2382,
 *     "height": 1340
 *   }
 *
 * @param cropObject
 * @return extractObject - sanitized or empty object if error
 */
const convertCropToExtract = (cropObject) => {
  return {
    left: cropObject.x < 0 && cropObject.x >= -1 ? 0 : cropObject.x,
    top: cropObject.y < 0 && cropObject.y >= -1 ? 0 : cropObject.y,
    width: cropObject.width,
    height: cropObject.height,
    // eslint-disable-next-line no-prototype-builtins
    naturalWidth: cropObject.hasOwnProperty('naturalWidth') ? cropObject.naturalWidth : 0,
    // eslint-disable-next-line no-prototype-builtins
    naturalHeight: cropObject.hasOwnProperty('naturalHeight') ? cropObject.naturalHeight : 0
  }
}

/**
 * Convert an Extract object (format from imageAttachment) to a cropper.js crop object
 * See above for details
 *
 * @param extractObject
 * @return cropObject or empty object if error
 */
const convertExtractToCrop = (extractObject) => {
  if (!('left' in extractObject && 'top' in extractObject && 'width' in extractObject && 'height' in extractObject)) {
    console.log('convertExtractToCrop : Invalid extract object')
    return {}
  }
  return {
    x: extractObject.left,
    y: extractObject.top,
    width: extractObject.width,
    height: extractObject.height
  }
}
/**
 * Create an object with all media for all locations for a given model
 * If an attachment is not set for the model then we will return a set of fallback images
 * @param model
 * @param attachments array
 * @return media object, indexed by all locations for model, with all urls / sizes
 */
const getAllMediaForModel = (model, attachments) => {
  const attachmentArray = Array.isArray(attachments) ? attachments : [attachments]
  const media = {}
  const locationsForModel = Object.keys(attachmentLocations[model])
  // TODO : Test to get placeholder image
  locationsForModel.forEach(location => {
    const attachment = find(attachmentArray, ['attachment_location', location])
    // If we have an attachment for the location, get media, if none, get placeholder
    media[location] = attachment ? transformMediaAttachment({ attachment, location, model }) : getPlaceHolderForLocation({ model, location })
    media['originalFileName'] = attachment ? attachment.media_item.original_file_name : ''
    media['fileSize'] = attachment ? mediaSize.getFormattedSize(attachment.media_item.byte_size) : ''
  })
  return media
}
/**
 * Generate presigned URL
 * Create a request object to generate a pre-signed upload url for our S3 bucket
 */
const getPresignedUrl = (ownerId, mimeType, fileName) => {
  const sanitizedFilename = (fileName) => {
    return Date.now() + '-' + fileName.replace(/[^a-zA-Z0-9.]/g, '-')
  }

  const objectName = `${bucketRoot}/${ownerId}/${sanitizedFilename(fileName)}` // Create unique filename prefixing with time
  return {
    url: presignedUrl,
    config: {
      method: 'get',
      mode: 'cors',
      headers: {
        bucket: bucketName,
        payloadkey: objectName,
        payloadmime: mimeType
      }
    },
    objectName: objectName
  }
}

/**
 * When images are uploaded to S3, we request the image from AWS cloudfront, but there is a delay in the image becoming available
 * this process checks the image url, and re-rechecks until the image becomes available.  This can take several seconds
 *
 * @param url - full path to the cloudfront image
 * @returns true - image available, false - image not resolved in time
 */
const wait = () => new Promise((resolve) => {
  setTimeout(resolve, 1000)
})

export const isImageAvailable = (url) => {
  let numberOfRetriesToDo = 20

  return new Promise((resolve, reject) => {
    const imageExists = () => {
      const img = new Image()

      img.src = url

      if (img.complete) {
        resolve()
        return true
      }

      img.onload = () => {
        resolve()
        return true
      }

      img.onerror = async () => {
        if (numberOfRetriesToDo === 0) {
          reject(new Error('broken'))
          return false
        }

        await wait()
        console.log(numberOfRetriesToDo)
        numberOfRetriesToDo -= 1
        imageExists(url)
      }
    }

    return imageExists(url)
  })
}

export const downloadAttachment = (attachmentArray, location) => {
  if(Array.isArray(attachmentArray)){
    var attachment = find(attachmentArray, ['attachment_location', location])
  }else{
    var attachment = attachmentArray
  }
  if (attachment) {
    var cloudRoot = cloudfrontVideoRoot
    if (attachment.media_item && attachment.media_item.type == 'image') {
      cloudRoot = cloudfrontPath
    }
    var fileURL = `${cloudRoot}${attachment.media_item.s3_object_name}`
    fileURL = fileURL.replace(/%/g, "%25")
    var link = document.createElement('a');
    link.href = fileURL;
    link.download = `${attachment.media_item.original_file_name}`;
    link.click();
    // var fileName = `${attachment.media_item.original_file_name}`
    // var xhr = new XMLHttpRequest();
    // xhr.open("GET", fileURL, true);
    // xhr.responseType = "blob";
    // xhr.onload = function(){
    //     var urlCreator = window.URL || window.webkitURL;
    //     var imageUrl = urlCreator.createObjectURL(this.response);
    //     var tag = document.createElement('a');
    //     tag.href = imageUrl;
    //     tag.download = fileName;
    //     document.body.appendChild(tag);
    //     tag.click();
    //     document.body.removeChild(tag);
    // }
    // xhr.send();
  }
}

export default {
  getPresignedUrl,
  transformMediaItems,
  convertCropToExtract,
  convertExtractToCrop,
  transformMediaAttachment,
  getPlaceHolderForLocation,
  getAllMediaForModel,
  isImageAvailable
}
