import mediaHandler from '~/plugins/media/mediaHandler'
const getMediaObject = (session) => {
  let mediaModel = 'EventExpo'
  const attachmentsKey = 'attachments'
  // Get attachments for this session
  const attachments = attachmentsKey in session ? session[attachmentsKey] : []
  // Transform attachments into media object, append to session
  // eslint-disable-next-line eqeqeq
  if (session.type === 'EventPlayer') {
    mediaModel = 'EventPlayer'
  } else if (session.type === 'EventNetworkingPlayer') {
    mediaModel = 'EventNetworkingPlayer'
  } else if (session.type === 'EventExpo') {
    mediaModel = 'EventExpo'
  } else if (session.type === 'EventPoster') {
    mediaModel = 'EventPoster'
  }
  return mediaHandler.getAllMediaForModel(mediaModel, attachments)
}

const exportToCsv = (filename, rows) => {
  const processRow = function (row) {
    let finalVal = ''
    for (let j = 0; j < row.length; j++) {
      let innerValue = row[j] === null ? '' : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      let result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0)
        result = '"' + result + '"';
      if (j > 0)
        finalVal += ',';
      finalVal += result;
    }
    return finalVal + '\n';
  }

  let csvFile = ''
  for (let i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  const blob = new Blob([csvFile], {type: 'text/csv;charset=utf-8;'});
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement("a")
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob)
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

const setOpacityToHexCode = (colour) => {
  if(colour.length == 7){
    colour += '30';
  } else if(colour.length == 9) {
    colour = colour.substring(0, colour.length - 2);
    colour += '30';
  } else {
    colour = '';
  }
  return colour;
}

export {
  getMediaObject,
  exportToCsv,
  setOpacityToHexCode
}
