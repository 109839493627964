<template>
  <v-col md="3" lg="2" class="side__wrapper">
    <div class="side__panel">
      <div class="side__panel-block">
        <router-link
          ref="ref-form-forgot-password-link"
          class="side__panel-back"
          :to="{ name: 'dashboard' }"
          aria-label="Back Button"
        >
          <div class="d-flex align-center"><v-icon class="side__panel-back--icon">mdi-chevron-left</v-icon> Back</div>
        </router-link>
        <div class="d-flex justify-space-between align-center">
          <h5 class="side__panel-title">
            {{ $t("events") }}
          </h5>
        </div>
      </div>
      <v-navigation-drawer permanent class="side__panel-menus">
        <v-list>
          <v-list-group
            v-for="event in events"
            :key="event.title"
            :ref="getListGroupRef(event.id)"
            :prepend-icon="event.action"
            no-action
            class="side__panel-color"
          >
            <template v-slot:activator>
              <v-list-item
                exact
                link
                :to="{ name: 'event.dashboard', params: { event: event.id } }"
              >
                <v-list-item-content>
                  <v-list-item-title
                    class="side__panel-menus--items"
                    :title="event.name"
                  >
                    {{ event.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item
              link
              :to="{ name: 'event.dashboard', params: { event: event.id } }" exact
            >
              <v-icon left>mdi-calendar-account</v-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("event_builder") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item id="v-step-7" link :to="{ name: 'event.host', params: { event: event.id } }" exact>
              <v-icon left>mdi-clipboard-account</v-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("event_host_menu") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item id="v-step-8" link :to="{ name: 'event.poll', params: { event: event.id } }" exact>
              <v-icon left>mdi-poll-box</v-icon>
              <v-list-item-content
                ><v-list-item-title>{{ $t("event_polls") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item id="v-step-9" link :to="{ name: 'event.survey', params: { event: event.id } }" exact>
              <v-icon left>mdi-account-group</v-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("event_survey") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item id="v-step-10" link :to="{ name: 'event.email', params: { event: event.id } }" exact>
              <v-icon left>mdi-email-variant</v-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("event_email") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item id="v-step-11" link :to="{ name: 'event.analytics', params: { event: event.id } }" exact>
              <v-icon left>mdi-chart-bar-stacked</v-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("event_analytics") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link :to="{ name: 'event.event-sales', params: { event: event.id } }" exact>
              <v-icon left>mdi-chart-areaspline</v-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("event_sales") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <PublicEventPageItem/>
          </v-list-group>
        </v-list>
      </v-navigation-drawer>
    </div>
  </v-col>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import PublicEventPageItem from './publicEventPageItem'
export default {
  components: { PublicEventPageItem },
  data () {
    return {
      eventListLoaded: true
    }
  },
  computed: {
    ...mapGetters('events', ['events'])
  },
  methods: {
    ...mapActions('events', [
      'getEvents'
    ]),
    isFirstEvent (id) {
      // console.log(' Event loaded ', this.eventListLoaded)
      return this.eventListLoaded && id === this.$route.params.event
    },
    getListGroupRef (id) {
      return 'v_list_group_' + id
    }
  },
  // eslint-disable-next-line vue/order-in-components
  async created () {
    if (this.events.length === 0) {
      this.showLoader(true)
      await this.getEvents()
      this.showLoader(false)
    }
    this.$nextTick(() => {
      this.eventListLoaded = false
      this.events.map(event => {
        this.$refs['v_list_group_' + event.id][0].isActive = event.id == this.$route.params.event
      })
    })
    this.$root.$on('update-event-list', async () => {
      this.showLoader(true)
      await this.getEvents()
      this.showLoader(false)
    })
  },
  watch: {
    '$route' (to, from) {
      if(from.name == 'events') {
        this.eventListLoaded = false
        this.$refs['v_list_group_' + this.events[0].id][0].isActive = true
      }
    }
  }
}
</script>
