<template>
  <div>
    <v-app-bar flat class="dashboard-header dashboard-header--login" fixed app>
      <div>
      <v-btn
          class="bg-primary-dark header__theme-switch"
          icon
          id="mode-switcher"
          type="button"
          aria-label="theme switcher"
          tabindex="1"
          @click="darkMode"
        >
          <v-img
            width="32"
            :src="($vuetify.theme.dark) ? '/img/dark_mode-black.svg' : '/img/dark_mode-white.svg'"
          />
      </v-btn>

        <v-app-bar-nav-icon class="hidden-md-and-up" @click.stop="drawer = !drawer">
          <v-icon large>
            mdi-menu
          </v-icon>
        </v-app-bar-nav-icon>
      </div>
      <div v-if="showWhiteLabelImage" tabindex="2" aria-label="Event logo">
        <router-link :to="{ name: 'welcome' }" aria-label="Event logo">
          <RaMediaDisplay
            class="eventdetails__whitelabel"
            :location="($vuetify.theme.dark) ? 'whitelabeldark' : 'whitelabel'"
            :media="lobbyImage"
            :sizes="sizes"
            :aspect-ratio="134 / 31"
          />
        </router-link>
      </div>
      <div v-else tabindex="3" aria-label="Reattendance logo">
        <a href="\" aria-label="Reattendance logo">
          <v-img class="order-3 order-md-1"
            contain
            max-width="220"
            :src="($vuetify.theme.dark) ? '/img/reattendance-logo--dark.png' : '/img/reattendance-logo.png'"
            :target="openInNewTabIfExternalPage"
          />
        </a>
      </div>
      <v-spacer class="order-2 order-md-2"/>
      <v-toolbar-items class="hidden-sm-and-down order-md-3 align-center">
        <div v-for="item in sideBarEntries" :key="item.title" tabindex="4">
          <v-menu v-if="item.is_external && item.have_submenu" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                class="header__nav-link--btn"
              >
                {{ $t(item.title) }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(sub_menu, index) in item.sub_menus"
                :key="index"
                :href="sub_menu.linkTo"
              >
                <v-list-item-title>{{ $t(sub_menu.title) }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <a v-else-if="item.is_external" class="nav-link header__nav-link" :href="item.linkTo">
            {{ $t(item.title) }}
          </a>
          <router-link v-else class="nav-link header__nav-link" :to="{ name: item.linkTo }">
            {{ $t(item.title) }}
          </router-link>
        </div>
        <div v-if="!authenticated" justify="space-between" tabindex="5">
          <router-link class="text-decoration-none" :to="{ name: 'login' }">
            <v-btn
              depressed
              class="btn-outline-primary header__login-btn"
              rounded
            >
              {{ $t('login') }}
            </v-btn>
          </router-link>
          <router-link class="text-decoration-none" :to="{ name: 'register' }">
            <v-btn
              depressed
              class="btn-primary"
              rounded
            >
              {{ $t('register') }}
            </v-btn>
          </router-link>
        </div>
        <div v-else justify="space-between">
          <router-link class="nav-link header__nav-link" :to="{ name: 'dashboard' }" tabindex="6">
            {{ $t('my_dashboard') }}
          </router-link>
          <v-menu ref="navbar-user-profile-menu" offset-y max-width="250" aria-label="my profile" tabindex="7">
            <template #activator="{ on, attrs }" tabindex="8">
              <v-avatar
                ref="user-profile-avatar"
                size="56"
                v-bind="attrs"
                v-on="on"
                aria-label="my profile"
                tabindex="9"
              >
                <RaMediaDisplay
                  class="header__profile"
                  :is-profile-image="true"
                  :media="userMedia"
                />
              </v-avatar>
            </template>
            <v-list>
              <v-list-item link>
                <v-list-item-avatar>
                  <RaMediaDisplay
                    :is-profile-image="true"
                    :media="userMedia"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="title" v-text="user.name" />
                  <v-list-item-subtitle v-text="user.email" />
                </v-list-item-content>
              </v-list-item>
              <v-list-item link :to="{ name: 'myAccount' }">
                <v-list-item-title> {{ $t('settings') }} </v-list-item-title>
                <v-list-item-icon>
                  <v-icon>mdi-cog-outline</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item ref="navbar-logout-link" link @click="logout">
                <v-list-item-title> {{ $t('logout') }} </v-list-item-title>
                <v-list-item-icon>
                  <v-icon>mdi-logout-variant</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-toolbar-items>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      app
      temporary
      class="side__drawer"
    >
      <v-list class="side__drawer-menu" nav dense>
        <v-list-item-group
          class="side__drawer-color"
        >
          <div
            v-for="(entry, i) in sideBarEntries"
            :key="entry.title"
          >
            <v-menu v-if="entry.is_external && entry.have_submenu" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  class="header__nav-link--btn"
                >
                  {{ $t(entry.title) }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(sub_menu, index) in entry.sub_menus"
                  :key="index"
                  :href="sub_menu.linkTo"
                >
                  <v-list-item-title>{{ $t(sub_menu.title) }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-list-item
              v-else-if="entry.is_external"
              link
              class="side__drawer-menu--items"
              :href="entry.linkTo"
            >
              <v-list-item-title class="sidebar-linktitile">{{ $t(entry.title) }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-else
              link
              class="side__drawer-menu--items"
              :to="!!entry.linkTo ? { name: entry.linkTo }: ''"
            >
              <v-list-item-title class="sidebar-linktitile">{{ $t(entry.title) }}</v-list-item-title>
            </v-list-item>
          </div>
          <v-list-item
            v-if="authenticated"
            link
            class="side__drawer-menu--items"
            :to="{ name: 'dashboard' }"
          >
            <v-list-item-title class="sidebar-linktitile">{{ $t('my_dashboard') }}</v-list-item-title>
          </v-list-item>
          <v-divider />
          <div v-if="!authenticated" justify="space-between" class="d-flex mt-3 mb-3">
            <v-list-item>
              <router-link class="text-decoration-none" :to="{ name: 'login' }">
                <v-btn
                  depressed
                  class="btn-outline-primary"
                  rounded
                >
                  {{ $t('login') }}
                </v-btn>
              </router-link>
            </v-list-item>
            <v-list-item>
              <router-link class="text-decoration-none" :to="{ name: 'register' }">
                <v-btn
                depressed
                rounded
                class="btn-primary"
                >
                  {{ $t('register') }}
                </v-btn>
              </router-link>
            </v-list-item>
          </div>
          <div v-else justify="space-between">
            <v-menu ref="navbar-user-profile-menu" offset-y max-width="250">
              <template #activator="{ on, attrs }">
                <v-row align="center" v-bind="attrs" v-on="on">
                  <v-col cols="3" align="center">
                    <v-avatar
                      ref="user-profile-avatar"
                      size="36"
                    >
                      <RaMediaDisplay
                        :is-profile-image="true"
                        :media="userMedia"
                      />
                    </v-avatar>
                  </v-col>
                  <v-col>
                    <span>{{ user.name }}</span>
                  </v-col>
                </v-row>
              </template>
              <v-list>
                <v-list-item link>
                  <v-list-item-avatar>
                    <RaMediaDisplay
                      :is-profile-image="true"
                      :media="userMedia"
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="title" v-text="user.name" />
                    <v-list-item-subtitle v-text="user.email" />
                  </v-list-item-content>
                </v-list-item>
                <v-list-item link>
                  <v-list-item-title> {{ $t('settings') }} </v-list-item-title>
                  <v-list-item-icon>
                    <v-icon>mdi-cog-outline</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item ref="navbar-logout-link" link @click="logout">
                  <v-list-item-title> {{ $t('logout') }} </v-list-item-title>
                  <v-list-item-icon>
                    <v-icon>mdi-logout-variant</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import RaMediaDisplay from '~/components/media/RaMediaDisplay'
import mediaHandler from '~/plugins/media/mediaHandler'
import { mapState } from 'vuex'
import isEmpty from 'lodash/isEmpty'

export default {
  components: {
    RaMediaDisplay
  },

  data: () => ({
    appName: window.config.appName,
    drawer: false,
    sideBarEntries: [
      {
        linkTo: 'pricing',
        title: 'price'
      },
      {
        linkTo: window.config.appUrl+'book-a-demo',
        title: 'book_a_demo',
        is_external: true
      },
      {
        title: 'blog',
        is_external: true,
        have_submenu: true,
        sub_menus: [
          {
            title: 'blog',
            linkTo: window.config.appUrl + 'blogs',
          },
          {
            title: 'knowledgebase',
            linkTo: window.config.appUrl + 'kb',
          }
        ]
      },
      {
        linkTo: window.config.appUrl + 'contact-us',
        title: 'contact_us',
        is_external: true
      },
      {
        linkTo: 'searchEvent',
        title: 'search_event.search_event'
      }
    ],
    sizes: '300px'
  }),
  computed: {
    ...mapGetters({
      user: 'auth/user',
      authenticated: 'auth/check'
    }),
    ...mapState('publicEvent', ['singlePublicEvent']),
    isPublicPage(){
      return this.$route && this.$route.name && (this.$route.name.includes('public-event-page') || this.$route.name == 'attendeeInviteLanding')
    },
    ticketPurchasePage(){
      return this.$route && this.$route.name && this.$route.name.includes('purchaseTicket')
    },
    lobbyImage () {
      return mediaHandler.getAllMediaForModel('Event', this.singlePublicEvent.attachments)
    },
    showWhiteLabelImage(){
      return this.singlePublicEvent && this.lobbyImage && (this.isPublicPage || this.ticketPurchasePage)
    },
    userMedia () {
      return isEmpty(this.user) ? {} : this.user.attachments
    },
    notExternalPublicPage(){
      return !!(this.$route.query && this.$route.query.isExternalPage && this.$route.query.isExternalPage === 'true')
    },
    openInNewTabIfExternalPage(){
      return this.notExternalPublicPage?'':'_blank'
    }
  },
  methods: {
    async logout () {
      await this.$router.push({ name: 'pre-logout' })
    },
    darkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      localStorage.setItem('theme', this.$vuetify.theme.dark)
      this.$root.$emit('global-overlay', true)
      setTimeout(() => {
        this.$root.$emit('global-overlay', false)
      }, 1000)
    }
  }
}
</script>

<style scoped>
.profile-photo {
  width: 2rem;
  height: 2rem;
  margin: -.375rem 0;
}
</style>
