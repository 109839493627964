import axios from 'axios'
import store from '~/store'
import router from '~/router'
import Swal from 'sweetalert2'
import i18n from '~/plugins/i18n'

const externalUrlPrefix = 'https://'
axios.defaults.baseURL = '/api'

// Request interceptor
axios.interceptors.request.use(request => {
  // TODO : Pros and cons of using this method to distinguish between internal and external requests
  if (request.url.startsWith(externalUrlPrefix)) {
    return request
  }
  // first we need to check for userToken, so admin can visit other user's view
  const userToken = store.getters['auth/userToken']
  if(userToken){
    request.headers.common.Authorization = `Bearer ${userToken}`
  } else {
    const token = store.getters['auth/token']
    if (token) {
      request.headers.common.Authorization = `Bearer ${token}`
    }
  }

  const locale = store.getters['lang/locale']
  if (locale) {
    request.headers.common['Accept-Language'] = locale
  }

  // request.headers['X-Socket-Id'] = Echo.socketId()

  return request
})

// Response interceptor
axios.interceptors.response.use(response => response, error => {
  const { status } = error.response
  // if (status >= 500) {
  //   store.dispatch('snackbar/showSnack', {
  //     text: error.response.data.message || i18n.t('error_alert_text'),
  //     color: 'error',
  //     timeout: 3500
  //   })
  // }

  if (status === 401 && store.getters['auth/check']) {
    store.dispatch('snackbar/showSnack', {
      text: 'Sorry! You are logged out',
      color: 'error',
      timeout: 3500
    })
    store.commit('auth/LOGOUT')
    router.push({ name: 'login' })
  }
  if (status === 401 || status === 422 || status === 429) {
    store.dispatch('snackbar/showSnack', {
      text: error.response.data.message,
      color: 'error',
      timeout: 3500
    })
  }

  // For Plan check, If user not have upgraded plan then we will found 403 error
  if (status === 403) {
    store.dispatch('permission/showPlanDialog', error.response.data.message)
  }

  return Promise.reject(error)
})
