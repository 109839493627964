import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters('axiosLoader', ['isDataLoading'])
  },
  methods: {
    ...mapActions('axiosLoader', ['showLoader'])
  }
}
