<template>
<v-col cols="12" class="side__wrapper-mobile">
    <v-bottom-navigation v-model="bottomNavigationHover" shift fixed class="side__panel-color">
        <v-btn id="v-step-19" @click="sheet = !sheet">
            <span>Menu</span>

            <v-icon>mdi-arrow-up-circle-outline</v-icon>
        </v-btn>
    </v-bottom-navigation>

    <v-bottom-sheet v-model="sheet">
        <v-sheet class="side__panel-mob--sheet">
            <v-btn icon large @click="sheet = !sheet">
                <v-icon large class="qr__scanner-close">mdi-arrow-down-circle-outline</v-icon>
            </v-btn>
            <div class="side__panel-mob--body">
                <MobileNavEventList v-if="eventPath" />
                <div class="side__panel-mob--innerbody" v-else>
                    <div class="side__panel-block">
                        <h5 class="side__panel-title">Menu</h5>
                    </div>
                    <v-list class="side__panel pa-0" nav dense>
                        <v-list-item-group class="side__panel-color">
                            <v-list-item v-for="item in items" :key="item.title" link :to="{ name: item.to }" @click="collapseMenu(item.to)" exact>
                                <v-list-item-content>
                                    <v-list-item-title class="side__panel-menus--items">{{ item.title }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="hasEvents" link :to="{ name: 'events' }">
                                <v-list-item-content>
                                    <v-list-item-title class="side__panel-menus--items">{{ $t('event_editor') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item exact @click="changeUserTimezone">
                                <v-list-item-content>
                                    <v-list-item-title class="side__panel-menus--items">{{ $t('my_timezone') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="isAdmin" link :to="{ name: 'adminUsersList' }" :class="{'v-item--active v-list-item--active':$route.path.includes('/admin/user')}" @click="collapseMenu('adminUsersList')">
                                <v-list-item-content>
                                    <v-list-item-title class="side__panel-menus--items">{{ $t('users_management') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="isAdmin" link :to="{ name: 'messageList' }" :class="{'v-item--active v-list-item--active':$route.path.includes('/admin/message')}" @click="collapseMenu('messageList')">
                                <v-list-item-content>
                                    <v-list-item-title class="side__panel-menus--items">What's News</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="isAdmin" link :to="{ name: 'upcomingUserEvents' }" :class="{'v-item--active v-list-item--active':$route.path.includes('/admin/event-details/')}" @click="collapseMenu('upcomingUserEvents')">
                                <v-list-item-content>
                                    <v-list-item-title class="side__panel-menus--items">{{ $t('upcoming_user_events') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="isAdmin" link :to="{ name: 'blogManagement' }" :class="{'v-item--active v-list-item--active':$route.path.includes('/admin/blog')}" @click="collapseMenu('blogManagement')">
                                <v-list-item-content>
                                    <v-list-item-title class="side__panel-menus--items">{{ $t('blogs_management') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="isAdmin" link :to="{ name: 'blogsCategories' }" :class="{'v-item--active v-list-item--active':$route.path.includes('/admin/add/blogs-categories')}" @click="collapseMenu('blogsCategories')">
                                <v-list-item-content>
                                    <v-list-item-title class="side__panel-menus--items">{{ $t('blog_categories.blog_categories') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="isAdmin" link :to="{ name: 'financials' }" @click="collapseMenu('financials')">
                                <v-list-item-content>
                                    <v-list-item-title class="side__panel-menus--items">{{ $t('financials') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="hasArchiveActivated" link :to="{ name: 'archive_events' }" @click="collapseMenu('archive_events')">
                                <v-list-item-content>
                                    <v-list-item-title class="side__panel-menus--items">{{ $t('archive_events') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="isAdmin" link :to="{ name: 'eventGraphicsTemplates' }" :class="{'v-item--active v-list-item--active':$route.path.includes('/admin/add/event-graphics-template')}" @click="collapseMenu('eventGraphicsTemplates')">
                                <v-list-item-content>
                                    <v-list-item-title class="side__panel-menus--items">{{ $t('event_graphics_template.event_graphics_template') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </div>
            </div>
        </v-sheet>
    </v-bottom-sheet>
</v-col>
</template>

<script>
import MobileNavEventList from './MobileNavEventList.vue'
import {
    mapGetters
} from 'vuex'
export default {
    components: {
        MobileNavEventList
    },
    data() {
        return {
            sheet: false,
            bottomNavigationHover: false,
            items: [
                // TODO:Once all page will be available need to replace the real URLS
                {
                    title: this.$t('main_dashboard'),
                    to: 'dashboard'
                },
                // { title: this.$t('my_event_profile'), to: 'welcome' },
                {
                    title: this.$t('my_business_card'),
                    to: 'business-card-detail'
                },
                {
                    title: this.$t('contact_i_have_made'),
                    to: 'collectedBusinessCards'
                },
                // { title: this.$t('my_timezone'), to: 'welcome' },
                // { title: this.$t('event_editor'), to: 'events' }
            ],
            validPaths: ['events', 'event.dashboard', 'event.host', 'event.poll', 'event.survey', 'event.email', 'event.analytics', 'email_delivery_report', 'email_details', 'event.event-sales','event.analytics','userInfoAnalytics']
        }
    },
    computed: {
        ...mapGetters('events', ['dashboard_events']),
        ...mapGetters('auth', ['user']),
        eventPath() {
            return this.validPaths.includes(this.$route.name)
        },
        isAdmin() {
            return this.user && this.user.is_admin
        },
        hasArchiveActivated() {
            return this.user && this.user.archive_activated
        },
        hasEvents() {
            if (this.user && this.user.event_count !== null) {
                return (this.user.event_count ? this.user.event_count : this.dashboard_events.eventsCount) + this.user.event_editor_count
            } else {
                return 0
            }
        }
    },
    created() {
        this.$root.$on('collapse-menu', () => {
            this.sheet = !this.sheet
        })
    },
    methods: {
        collapseMenu(item) {
            if (item != 'events') {
                this.sheet = !this.sheet
            }
        },
        changeUserTimezone() {
            this.$root.$emit('show-timezone-popup', {
                showTimezone: true,
                step: 2,
                openDialog: true
            })
        }
    }
}
</script>

<style scoped>
</style>
