import Vue from 'vue'
import store from '~/store'
import router from '~/router'
import i18n from '~/plugins/i18n'
import App from '~/components/App'
import vuetify from '~/plugins/vuetify'
import userPermission from '~/plugins/userPermission'
// import '../sass/main.scss'
import Vuelidate from 'vuelidate'
import '~/plugins'
import '~/components'
import resetTitles from '~/plugins/resetTitles'
import axiosLoaderGlobalMixin from '~/mixins/axiosLoaderGlobalMixin'
import VueTour from 'vue-tour'
import VueGtag from 'vue-gtag'
import { registerLicense } from '@syncfusion/ej2-base'

registerLicense('ORg4AjUWIQA/Gnt2V1hhQlJAfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5UdkNjUH5cdXxQRWRZ')
import Bugsnag from '~/plugins/bugsnag'
const bugsnagVue = Bugsnag.getPlugin('vue')
bugsnagVue.installVueErrorHandler(Vue)

// Set the Axios Loader Globally
Vue.mixin(axiosLoaderGlobalMixin)
/* WebRTC Support */
import adapter from 'webrtc-adapter';
import VueSocketIOExt from 'vue-socket.io-extended'
import io from 'socket.io-client'

import initIntercom from '~/plugins/intercom'
Vue.use(initIntercom)

Vue.use(VueGtag, {
  config: { id: 'G-VY7K151YNT' },
  appName: window.config.appName,
  pageTrackerScreenviewEnabled: true
},router)

export const socket = io('wss://signal.reattendance.com:' + process.env.MIX_WEBRTC_PORT + '/', { autoConnect: false } )
Vue.use(adapter)
Vue.use(VueSocketIOExt, socket, { store });

Vue.config.productionTip = false
Vue.use(userPermission)
Vue.use(resetTitles)
require('vue-tour/dist/vue-tour.css')

Vue.use(VueTour)
new Vue({
  i18n,
  store,
  vuetify,
  Vuelidate,
  router,
  ...App
})
