var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.label)?_c('label',{staticClass:"ra__fields-label ra-fields--text-input__label",class:{ 'ra__fields-label--required' : _vm.isRequired },attrs:{"for":_vm.labelFor}},[_vm._v("\n    "+_vm._s(_vm.label)),_c('span',[_vm._v(_vm._s(_vm.$t('event.event_select_city')))]),_vm._v(" "),(_vm.hasHelpIcon)?_c('v-icon',{staticClass:"ra__label-icon--title",on:{"click":function($event){$event.stopPropagation();return _vm.displayHelp($event)}}},[_vm._v("mdi-help-circle-outline")]):_vm._e()],1):_vm._e(),_vm._v(" "),_c('v-autocomplete',_vm._b({staticClass:"ra__input",attrs:{"items":_vm.timezones,"loading":_vm.isLoading,"search-input":_vm.search,"item-text":"location_name","item-value":"id","chips":"","filled":"","solo":"","flat":"","clearable":"","label":_vm.$t('search_for_your_nearest_city'),"no-data-text":_vm.$t('autocomplete_no_data_text')},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":_vm.valueChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"timezone__country-chip",attrs:{"input-value":selected}},'v-chip',attr,false),on),[_c('v-avatar',[_c('v-img',{attrs:{"src":_vm.getCountryFlag(item.timezone.country_code)}})],1),_vm._v(" "),_c('span',{staticClass:"timezone__country",domProps:{"textContent":_vm._s(item.location_name)}})],1)]}},{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v("\n          "+_vm._s(_vm.$t('search_for_your_nearest_city'))+"\n        ")])],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":_vm.getCountryFlag(item.timezone.country_code)}})],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.location_name + ', ' )}}),_vm._v(" "),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s((item.state ? item.state+', ': '') + item.timezone.country_name + ', GMT' + item.timezone.standard_offset+ ', ' + item.timezone.standard_timezone_name)}})],1)]}}]),model:{value:(_vm.timezone_id),callback:function ($$v) {_vm.timezone_id=$$v},expression:"timezone_id"}},'v-autocomplete',_vm.$attrs,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }