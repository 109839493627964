<template>
    <div>
      <div v-if="userDetails && isNetworkVisible">
        <v-menu open-on-hover>
          <template v-slot:activator="{ on, attrs }">
            <v-card v-bind="attrs" v-on="on" class="eventdetails__people-card">
              <v-list class="eventdetails__people">
                <v-list-item>
                  <v-list-item-avatar>
                    <RaMediaDisplay
                      :is-profile-image="true"
                      profile-image-size="small"
                      :media="userMedia"
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="eventdetails__people-title" v-bind:class="{'user-busy': userStatus}">{{userName}}</v-list-item-title>
                    <v-list-item-subtitle class="eventdetails__people-subtitle">{{userDetails.business_card.job_title}}&nbsp;</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </template>
          <v-list dark v-if="!isAnonymous">
            <div class="eventdetails__people-body">
              <h2 class="eventdetails__people-cardtitle">Networking with {{userName}}:</h2>
              <div class="d-flex flex-column align-start">
                <v-btn text v-if="userDetails.business_card.id" @click='btnSwapBusinessCard(userDetails.id)'>
                  <v-icon left>mdi-gesture-swipe</v-icon> Swap Business Cards
                </v-btn>
                <v-btn text v-if="userDetails.business_card.id" @click='modalDisplayBusinessCard = true'>
                  <v-icon left>mdi-eye</v-icon> View their Business Card
                </v-btn>
                <v-btn text @click='btnInitiateChat(userDetails.id)'>
                  <v-icon left>mdi-chat</v-icon> Start Chat
                </v-btn>
                <template v-if="displayVideoCall">
                  <v-btn text @click='btnStartVideoCall(userDetails.id)'>
                    <v-icon left>mdi-message-video</v-icon> <span>Start Video Call</span>
                  </v-btn>
                </template>
                <template v-if="userStatus">
                  <v-btn text>
                    <v-icon left>mdi-message-video</v-icon> <span>This user is currently on another call</span>
                  </v-btn>
                </template>
              </div>
            </div>
          </v-list>
        </v-menu>
      </div>
      <v-dialog
        v-model="modalDisplayBusinessCard"
        width="350"
      >
        <div v-if="userDetails">
          <v-card class="business__card-menu1">
            <v-list dark>
              <v-list-item>
                <v-list-item-avatar>
                  <RaMediaDisplay
                    :is-profile-image="true"
                    :media="userMedia"
                    profile-image-size="medium"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="business__card-title--1">
                    {{ userName }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ userDetails.business_card.job_title }}
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon @click="modalDisplayBusinessCard = false" class="mdi-close" aria-label="close preview">
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-list>
              <v-list-item v-if="userDetails.business_card.mobile_number" :href="`tel:${userDetails.business_card.mobile_number}`" target="_blank">
                <v-list-item-action>
                  <v-icon>mdi-phone-in-talk-outline</v-icon>
                </v-list-item-action>
                <v-list-item-subtitle class="business__card-desc1">
                  {{ userDetails.business_card.mobile_number }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item v-if="userDetails.business_card.email_address" :href="`mailto:${userDetails.business_card.email_address}`" target="_blank">
                <v-list-item-action>
                  <v-icon>mdi-email-outline</v-icon>
                </v-list-item-action>
                <v-list-item-subtitle class="business__card-desc1">
                  {{ userDetails.business_card.email_address }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item v-if="userDetails.business_card.company_website" :href="userDetails.business_card.company_website" target="_blank">
                <v-list-item-action>
                  <v-icon>mdi-earth</v-icon>
                </v-list-item-action>
                <v-list-item-subtitle class="business__card-desc1">
                  {{ userDetails.business_card.company_website }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item v-if="userDetails.business_card.company_name">
                <v-list-item-action>
                  <v-icon>mdi-office-building</v-icon>
                </v-list-item-action>
                <v-list-item-subtitle class="business__card-desc1">
                  {{ userDetails.business_card.company_name }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item v-if="userDetails.business_card.tagline">
                <v-list-item-action class="icon__align">
                  <v-icon>mdi-tag-multiple-outline</v-icon>
                </v-list-item-action>
                <v-list-item-subtitle class="business__card-desc1">
                  {{ userDetails.business_card.tagline }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item v-if="userDetails.business_card.bio">
                <v-list-item-action class="icon__align">
                  <v-icon>mdi-bio</v-icon>
                </v-list-item-action>
                <v-list-item-subtitle class="business__card-desc1">
                  {{ userDetails.business_card.bio }}
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </v-card>
        </div>
      </v-dialog>

    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios';
import RaMediaDisplay from '~/components/media/RaMediaDisplay'

export default {
  name: 'RaWebrtcPersonCard',
  components: { RaMediaDisplay},
  data () {
    return {
      modalDisplayBusinessCard: false,
      userDetails: null
    }
  },
  props: {
    userId: {
      type: Number
    },
    networkType: {
      type: String
    },
  },
  created (){
    this.fetchUserDetails()
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('events', ['singleEvent']),
    ...mapGetters('webrtc', ['getRoomUser']),
    displayVideoCall(){
      return this.userStatus == 0 && this.networkType != 'Roulette'
    },
    isAnonymous (){
      if(this.userDetails.business_card.first_name != undefined && this.userDetails.business_card.first_name.length > 0){
        return false
      } else {
        return true
      }

    },
    userName () {
      if(this.userDetails.business_card.first_name != undefined && this.userDetails.business_card.first_name.length > 0){
        return this.userDetails.business_card.first_name + ' ' + this.userDetails.business_card.last_name
      } else {
        return 'Anonymous'
      }      
    },
    userMedia () {
      return this.userDetails.attachments
    },
    userStatus () {
      return this.userDetails.user_status
    },
    isNetworkVisible (){
      return this.userDetails.is_network_visible
    }

  },
  methods: {
    ...mapActions('webrtc', ['storeAttendee']),

    btnInitiateChat (userId) {
      let user1 = this.user.id
      let user2 = userId
      var chatRoom = ''
      if(user1 > user2){
        chatRoom = 'chat-p2p-' + this.singleEvent.id + '-' + user2 + '-' + user1
      } else {
        chatRoom = 'chat-p2p-' + this.singleEvent.id + '-' + user1 + '-' + user2
      }
      this.$root.$emit('webrtcInitiateChat', userId, 'P2PChat', chatRoom)
      this.attendeeDialog = false
    },
    btnStartVideoCall (userId) {
      this.$root.$emit('webrtcStartVideoCall', this.networkType, userId)
      this.$root.$emit('closeModalDisplayUsers')
    },
    async btnSwapBusinessCard(userId){
      this.attendeeDialog = false    
      await axios.post('webrtc/swap-business-card', {cardReceiver: userId}).then(response => {
        this.$store.dispatch('snackbar/showSnack', {
          text: this.$t('business_card_swapped_text'),
          color: 'success',
          timeout: this.timeout
        })        
      }).catch(error => {
        console.log(error)
      });
    },   
    fetchUserDetails (){
      let user = this.getRoomUser(this.userId)
      if(user == undefined){
        axios.get('webrtc/user/' + this.userId + '/info-query').then((response) => {
          this.userDetails = response.data.user
          this.storeAttendee(response.data.user)
        })
      }
      this.userDetails = user
    }      
  }
}
</script>
<style scoped>
.user-busy{
  color: #e3342f;
}
</style>
