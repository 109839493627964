<template>
  <!-- Header -->
  <v-app>
    <v-main>
      <RaGlobalSnackbar />
      <RaUpgradePlan />
      <RaLoadingSpinner />
      <Webrtc />
      <v-container fluid class="py-0">
        <div class="overlay__global" ref="global_overlay" v-if="showOverlay">
          <v-skeleton-loader type="image" :dark="$vuetify.theme.dark" height="100vh" style="background:#000"></v-skeleton-loader>
        </div>
        <Header />
        <div class="ml-md-14">
          <router-view/>
        </div>
      </v-container>
    </v-main>
    <cookie-law ></cookie-law>
  </v-app>
</template>

<script>
import Webrtc from '~/components/webrtc/Webrtc.vue'
import Header from '~/components/Header.vue'
import { mapGetters } from 'vuex'
import RaLoadingSpinner from '~/components/general/RaLoadingSpinner'
import CookieLaw from 'vue-cookie-law'
export default {
  name: 'LobbyLayout',
  components: { RaLoadingSpinner, Header, Webrtc, CookieLaw },
  middleware: ['auth'],
  data() {
    return {
      showOverlay: false
    }
  },
  computed: {
    ...mapGetters('auth', ['user'])
  },
  // mounted() {
  //   window.Intercom('shutdown')
  // },
  created () {
    this.$root.$on('global-overlay', (status) => {
      this.showOverlay = status
    })
    window.Intercom('boot', {
      app_id: window.config.appId,
      email: this.user.email,
      name: this.user.name,
      user_id: this.user.id
      // Website visitor so may not have any user related info
    })
  }
}
</script>
