<template>
  <div class="text-end">
    <v-btn icon class="pa-2" @click="onClicked" aria-label="Close popup window">
      <v-icon class="icon-anim">
        mdi-close
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'RaModalCloseButton',
  methods: {
    onClicked () {
      this.$emit('close-modal')
    }
  }
}
</script>
