import * as types from '../mutation-types'

const { locale, locales } = window.config

// state
const state = {
  locale: getLocale(locales, locale),
  locales: locales
}

// getters
const getters = {
  locale: state => state.locale,
  locales: state => state.locales
}

// mutations
const mutations = {
  [types.SET_LOCALE] (state, { locale }) {
    state.locale = locale
  }
}

// actions
const actions = {
  setLocale ({ commit }, { locale }) {
    commit(types.SET_LOCALE, { locale })
    localStorage.locale = locale
  }
}

/**
 * @param  {String[]} locales
 * @param  {String} fallback
 * @return {String}
 */
function getLocale (locales, fallback) {
  const locale = localStorage.locale

  if (Object.prototype.hasOwnProperty.call(locales, locale)) {
    return locale
  } else if (locale) {
    delete localStorage.locale
  }

  return fallback
}

export default {
  state,
  getters,
  actions,
  mutations
}
