import store from '~/store'

/**
 * This is middleware to check the current session is valid or not.
 *
 * middleware: 'check-session-exist:EventPlayer',
 */

export default (to, from, next, sessionType) => {
  // Grab the user
  const currentSession = store.getters['schedules/currentSession']

  // Check if the user has one of the required roles...
  if (!currentSession || currentSession.event_id != to.params.event || currentSession.type !== sessionType) {
    next({ name: 'event-lobby.schedule', params: { event: to.params.event } })
  }
  next()
}
