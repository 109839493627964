import axios from 'axios'


const state = {
    refundRequestData: []
}
const getters = {
    refundRequestData: state => state.refundRequestData,
}
const mutations = {
    SET_REFUND_REQUEST_DATA: (state, data) => {
        state.refundRequestData = data;
    }
}
const actions = {
    getRefundRequestData: async (context) => {
        return axios.get('event-owner/get-pending-refund-requests').then(({ data }) => {
            context.commit('SET_REFUND_REQUEST_DATA', data.refundRequestData);
        }).catch((error) => {
            console.log(error);
        })
    }

}

export default {
    state,
    getters,
    mutations,
    actions

}