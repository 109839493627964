import camelCase from 'lodash/camelCase'

try {
  var data = window.localStorage;
} catch (error) {
  alert("This page is trying to access an event schedule from the Reattendance.com Events platform. However, you are either viewing this in a Private browser, or you have blocked your cookies, and this page needs access to cookies to function. Please unblock cookies or view in a non Private/Incognito browser to view.");
}

const requireModule = require.context('.', false, /\.js$/)
const modules = {}

requireModule.keys().forEach(fileName => {
  if (fileName === './index.js') return

  const moduleName = camelCase(
    fileName.replace(/(\.\/|\.js)/g, '')
  )

  modules[moduleName] = {
    namespaced: true,
    ...requireModule(fileName).default
  }
})

export default modules
