<template>
  <div>
    <!-- Start Call Modal -->
    <div v-if="callReceiver">
     <v-dialog
      v-model="modalStartVideoCall"
      width="700"
    >
      <v-card>
        <div class="ra__form-heading section__title-3">
          <v-card-title class="ra__modal-title">
            <h3 class="ra__modal-title--heading">
              Start Video Call...
            </h3>
          </v-card-title>
        </div>

        <v-card-text class="ra__modal-body">
          <div class="d-flex flex-column flex-md-row">
              <div class="pr-4">
                <RaMediaDisplay
                  :is-profile-image="true"
                  profile-image-size="medium"
                  :media="modalUserMedia"
                />
              </div>
              <div class="w-100">
                <h3 class="ra__modal-body--headline">{{callReceiver.first_name}} {{callReceiver.last_name}}</h3>
                <p class="ra__modal-body--tagline">
                  <span v-if="callReceiver.business_card.tagline">
                    {{callReceiver.business_card.tagline}}
                  </span>
                </p>
                <p>
                  <span v-if="callReceiver.business_card.job_title">
                    {{callReceiver.business_card.job_title}}
                  </span>
                  <span v-if="callReceiver.business_card.job_title && callReceiver.business_card.job_title">
                     -
                  </span>
                  <span v-if="callReceiver.business_card.job_title">
                    {{callReceiver.business_card.company_name}}
                  </span>
                </p>
                <v-textarea
                  v-model="callIcebreakerNote"
                  rows="2"
                  auto-grow
                  label="Type your icebreaker note here..."
                ></v-textarea>
              </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="ra__modal-body--btngroup">
          <v-btn
            depressed
            large
            class="btn-primary"
            @click="inviteToConnect(callReceiver.id, callIcebreakerNote)"
          >
            Invite to Connect
          </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
    <!-- End Start Call Modal -->

    <!-- Requesting Call Modal -->
    <div v-if="callReceiver">
     <v-dialog
      v-model="modalRequestingVideoCall"
      width="700"
      persistent
    >
      <v-card>
        <div class="ra__form-heading section__title-3">
          <v-card-title class="ra__modal-title">
            <h3 class="ra__modal-title--heading">
              Requesting Video Call...
            </h3>
          </v-card-title>
        </div>

        <v-card-text class="ra__modal-body">
          <div class="d-flex flex-column flex-md-row">
              <div class="pr-4">
                <RaMediaDisplay
                  :is-profile-image="true"
                  profile-image-size="medium"
                  :media="modalUserMedia"
                />
              </div>
              <div>
                <h3 class="ra__modal-body--headline">{{callReceiver.first_name}} {{callReceiver.last_name}}</h3>
                <p class="ra__modal-body--tagline">
                  <span v-if="callReceiver.business_card.tagline">
                    {{callReceiver.business_card.tagline}}
                  </span>
                </p>
                <p>
                  <span v-if="callReceiver.business_card.job_title">
                    {{callReceiver.business_card.job_title}}
                  </span>
                  <span v-if="callReceiver.business_card.job_title && callReceiver.business_card.job_title">
                     -
                  </span>
                  <span v-if="callReceiver.business_card.job_title">
                    {{callReceiver.business_card.company_name}}
                  </span>
                </p>
                <h4 class="ra__modal-body--subheadline">Your invitation to join your networking bubble is in progress...</h4>
              </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="ra__modal-body--btngroup">
          <v-btn
            depressed
            large
            class="btn-danger"
            @click="cancelCall(callReceiver.id)"
          >
            Cancel
          </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
    <!-- End Requesting Call Modal -->

    <!-- Incoming Call Modal -->
    <div v-if="callSender">
     <v-dialog
      v-model="modalIncomingCallRequest"
      width="700"
      persistent
    >
      <v-card>
        <div class="ra__form-heading section__title-1">
          <v-card-title class="ra__modal-title">
            <h3 v-if="this.callType=='expo'" class="ra__modal-title--heading">
               Expo Video Call Request 
            </h3>
            <h3 v-else-if="this.callType=='Help'" class="ra__modal-title--heading">
               Help Video Call Request 
            </h3>
            <h3 v-else class="ra__modal-title--heading">
              Incoming Networking Request 
            </h3>
          </v-card-title>
        </div>

        <v-card-text class="ra__modal-body">
          <div class="d-flex flex-column flex-md-row">
              <div class="pr-4">
                <RaMediaDisplay
                  :is-profile-image="true"
                  profile-image-size="medium"
                  :media="modalUserMedia"
                />
              </div>
              <div>
                <h3 class="ra__modal-body--headline">{{callSender.first_name}} {{callSender.last_name}}</h3>
                <p class="ra__modal-body--tagline">
                  <span v-if="callSender.business_card.tagline">
                    {{callSender.business_card.tagline}}
                  </span>
                </p>
                <p>
                  <span v-if="callSender.business_card.job_title">
                    {{callSender.business_card.job_title}}
                  </span>
                  <span v-if="callSender.business_card.job_title && callSender.business_card.job_title">
                     -
                  </span>
                  <span v-if="callSender.business_card.job_title">
                    {{callSender.business_card.company_name}}
                  </span></p>
                <h4 class="ra__modal-body--subheadline">
                  <span v-if="callIcebreakerNote">
                    {{callIcebreakerNote}}
                  </span>
                  <span v-else>
                    Would like to connect with you in a Video Call...
                  </span>
                </h4>
              </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="ra__modal-body--btngroup">
          <v-btn
            depressed
            large
            class="btn-danger btn-spacing"
            @click="declineCall()"
          >
            Decline
          </v-btn>
          <v-btn
            depressed
            large
            class="btn-primary"
            @click="acceptCall()"
          >
            Accept
          </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    </div>
    <!-- End Incoming Call Modal -->

    <!-- Call Declined / Unanswered Modal -->
    <div v-if="callReceiver">
     <v-dialog
      v-model="modalCallDeclined"
      width="700"
      persistent
    >
      <v-card>
        <div class="ra__form-heading section__title-4">
          <v-card-title class="ra__modal-title">
            <h3 v-if="this.callType=='expo'" class="ra__modal-title--heading" >
             Expo Request Declined / Unanswered 
            </h3>
            <h3 v-else-if="this.callType=='Help'" class="ra__modal-title--heading" >
             Help Request Declined / Unanswered 
            </h3>
            <h3 v-else class="ra__modal-title--heading">
             Networking Request Declined / Unanswered 
            </h3>
          </v-card-title>
        </div>

        <v-card-text class="ra__modal-body">
          <div class="d-flex flex-column flex-md-row">
              <div class="pr-4">
                <RaMediaDisplay
                  :is-profile-image="true"
                  profile-image-size="medium"
                  :media="modalUserMedia"
                />
              </div>
              <div>
                <h3 class="ra__modal-body--headline">{{callReceiver.first_name}} {{callReceiver.last_name}}</h3>
               <p class="ra__modal-body--tagline">
                  <span v-if="callReceiver.business_card.tagline">
                    {{callReceiver.business_card.tagline}}
                  </span>
                </p>
                <p>
                  <span v-if="callReceiver.business_card.job_title">
                    {{callReceiver.business_card.job_title}}
                  </span>
                  <span v-if="callReceiver.business_card.job_title && callReceiver.business_card.job_title">
                     -
                  </span>
                  <span v-if="callReceiver.business_card.job_title">
                    {{callReceiver.business_card.company_name}}
                  </span>
                </p>
                <h4 class="ra__modal-body--subheadline">{{callReceiver.first_name}} {{callReceiver.last_name}} is not available to connect with you right now...</h4>
              </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="ra__modal-body--btngroup">
          <v-btn
            depressed
            large
            class="btn-outline-primary"
            @click="modalCallDeclined = false"
          >
            Close
          </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    </div>
    <!-- End Call Declined / Unanswered Modal -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios';
import RaMediaDisplay from '~/components/media/RaMediaDisplay'
import RaWebrtcPersonCard from '~/components/webrtc/RaWebrtcPersonCard.vue'

export default {
  name: 'RaWebrtcModals',
  components: { RaMediaDisplay, RaWebrtcPersonCard },
  data () {
    return {
      modalStartVideoCall: false,
      modalRequestingVideoCall: false,
      modalIncomingCallRequest: false,
      modalCallDeclined: false,
      modalUserMedia: null,
      modalDisplayInviteVideoCallUsers: false,

      callType: null,
      callReceiver: null,
      callSender: null,
      callSenderSocket: null,
      callIcebreakerNote: null,
      callId: null,
      duringCall: false,

      ringer: null,
      ringerTimeout: null,

      receivedCallSignal: null,
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('schedules', ['currentSession']),
    ...mapGetters('webrtc', ['getRoomUser', 'getRoomUsers', 'getVideoCallParameters']),
  },
  mounted () {

    this.ringer = new Audio('/audio/ring.mp3');
    this.ringer.loop = true;

    this.$root.$on('userMessage-call-to-bubble', (data) => {
 	    this.duringCall = false
    })

    this.$root.$on('test-on-call-answer', (call) => {
      this.duringCall = call
    }),

    this.$root.$on('webrtcStartVideoCall', (type, userId) => {
      this.callType = type
      this.callReceiver = this.fetchUserDetails(userId)
      if(this.callReceiver.attachments == undefined){
        this.modalUserMedia = {}
      } else {
        this.modalUserMedia = this.callReceiver.attachments
      }
      this.callIcebreakerNote = null
      this.modalStartVideoCall = true
    }), 
    
    this.$root.$on('webrtcIncomingCallRequest', (data) => {
      if (!this.modalIncomingCallRequest && !this.modalRequestingVideoCall && !this.duringCall){
        this.receivedCallSignal = true
        this.callSender = this.fetchUserDetails(data.caller)
        this.callSenderSocket = data.socketId
        this.callType = data.callType
        this.callId = data.callId
        this.callIcebreakerNote = data.note
        if(this.callSender.attachments == undefined){
          this.modalUserMedia = {}
        } else {
          this.modalUserMedia = this.callSender.attachments
        }      
        this.modalIncomingCallRequest = true
        this.ringer.play()
        this.ringerTimeout = setTimeout(() => {
          this.modalIncomingCallRequest = false;
          this.ringer.pause();
          this.ringer.currentTime = 0.0;
          this.declineCall()
        }, 30 * 1000);
      } else {
        if (!this.receivedCallSignal){
          let tmpCallSender = this.fetchUserDetails(data.caller)
          this.busyDeclineCall(tmpCallSender)
        }
      }
    })  

    this.$root.$on('webrtcCallCancelled', (callerId) => {
      clearTimeout(this.ringerTimeout)
      this.modalIncomingCallRequest = false
      this.ringer.pause();
      this.ringer.currentTime = 0.0;

    })  

    this.$root.$on('webrtcCallDeclined', (receiverId) => {
      this.modalRequestingVideoCall = false
      this.modalCallDeclined = true
      this.$root.$emit('webrtcModalsCallDeclined', this.callType, receiverId)

    })  

    this.$root.$on('webrtcCallAccepted', (receiverId, socketId) => {
      this.modalRequestingVideoCall = false
      this.$root.$emit('webrtcModalsCallAccepted', this.callType, receiverId, socketId)
      if (this.callType=='P2P'){
          let link = `/event-lobby/${this.$route.params.event}/networking/callroom/p2p${this.user.id}`
          this.$router.push({ path: link }).catch(() => {});
      }
      if (this.callType=='bubbleSession'){
          const data = {
          command: 'jump-to-bubble-session',
          link: `/event-lobby/${this.$route.params.event}/networking/openroom/${this.$route.params.roomname}`,
          userId: receiverId,
          }
          this.$root.$emit('webrtcGeneralSendToUser', data );
        }
    }) 

    this.$root.$on('userMessage-closeModal', (data) => {
 	    this.closeModal()
    })
  },
  beforeDestroy() {
    this.$root.$off('webrtcIncomingCallRequest')  
  },
  methods: {
    ...mapActions('webrtc', ['setVideoCallBubbleId','setVideoCallBubbleOwner','setVideoCallSessionId','setVideoCallJoined','setVideoCallUser','storeAttendee']),
    fetchUserDetails(userId) {
      let user = this.getRoomUser(userId)
      if(user == undefined){
        axios.get('webrtc/user/' + userId + '/info-query').then((response) => {
          this.userDetails = response.data.user
          this.storeAttendee(response.data.user)
        })
      }
      this.userDetails = user
      return user

    },      

    close: () => {},
    closeModal(){
      clearTimeout(this.ringerTimeout)
      this.ringer.pause();
      this.ringer.currentTime = 0.0;
      this.modalIncomingCallRequest = false
      this.modalIncomingCallRequest = false
      this.modalRequestingVideoCall = false
      this.receivedCallSignal = false
    },
    closeModalSend(){
      const data = {
        command: 'closeModal',
        userId: this.user.id,
      }
      this.$root.$emit('webrtcGeneralSendToUser', data );
    },
    acceptCall() {
      clearTimeout(this.ringerTimeout)
      this.ringer.pause();
      this.ringer.currentTime = 0.0;
      this.modalIncomingCallRequest = false
      this.closeModalSend()
      this.receivedCallSignal = false
      if (!this.duringCall){
        this.$root.$emit('webrtcAcceptCallRequest', this.callSender.id, this.callSenderSocket)
        setTimeout(() => {
          if (this.callType=='P2P'){
            let link = `/event-lobby/${this.$route.params.event}/networking/callroom/p2p${this.callSender.id}`
            this.$router.push({ path: link }).catch(() => {});
          }
        },1500)
      }
      this.duringCall = true
    },    
    cancelCall(userId) {
      this.receivedCallSignal = false
      this.modalRequestingVideoCall = false
      this.$root.$emit('webrtcCancelCallRequest', userId)
      this.$root.$emit('webrtcModalsCallRequestCancelled', this.callType, userId)
    },
    busyDeclineCall(tmpCallSender) {
      this.closeModalSend()
      this.$root.$emit('webrtcDeclineCallRequest', tmpCallSender.id)
    }, 
    declineCall() {
      this.duringCall = false
      this.receivedCallSignal = false
      clearTimeout(this.ringerTimeout)
      this.ringer.pause();
      this.ringer.currentTime = 0.0;
      this.modalIncomingCallRequest = false
      this.closeModalSend()
      this.$root.$emit('webrtcDeclineCallRequest', this.callSender.id)
    },    
    inviteToConnect(userId, icebreakerNote) {
      this.modalStartVideoCall = false
      this.modalRequestingVideoCall = true
      this.$root.$emit('webrtcCallRequest', this.callType, userId, icebreakerNote, this.callId)
    }
  }
}
</script>
