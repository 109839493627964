<template>
  <v-layout>
    <v-row align="center">
      <v-col cols="12" :sm="!isHideDelete? 5 : 6">
        <h5 class="ra__fields-label">{{ startTimeLabel }}</h5>
        <v-datetime-picker
          ref="startDateTimePicker"
          v-model="startTime"
          :date-picker-props="configs.date.start"
          :time-picker-props="configs.time.start"
          date-format="eee, dd MMM yy,"
          time-format="h:mm a"
          :disabled="isDisabled"
        >
          <template slot="dateIcon">
            <v-icon>mdi-calendar</v-icon>
          </template>
          <template slot="timeIcon">
            <v-icon>mdi-clock</v-icon>
          </template>
        </v-datetime-picker>
      </v-col>
      <v-col cols="12" :sm="!isHideDelete? 5 : 6">
        <h5 class="ra__fields-label">{{ endTimeLabel }}</h5>
        <v-datetime-picker
          ref="endDateTimePicker"
          v-model="endTime"
          :date-picker-props="configs.date.end"
          :time-picker-props="configs.time.end"
          date-format="eee, dd MMM yy,"
          time-format="h:mm a"
          :disabled="!hasStartTime"
        >
          <template slot="dateIcon">
            <v-icon>mdi-calendar</v-icon>
          </template>
          <template slot="timeIcon">
            <v-icon>mdi-clock</v-icon>
          </template>
        </v-datetime-picker>
      </v-col>
      <v-col cols="12" sm="2" align="center" v-if="!isHideDelete">
        <v-btn depressed color="error" v-on:click.stop="deleteData">
          {{ $t("delete") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-layout>
</template>
<script>
export default {
  name: 'VSchedule',
  props: {
    labelPerfix: {
      type: String,
      default: 'Event'
    },
    startDateTime: {
      type: [Date, String],
      default: function () { return new Date() }
    },
    endDateTime: {
      type: [Date, String],
      default: function () { return new Date() }
    },
    startDateMin: {
      type: String,
      default: null
    },
    endDateMin: {
      type: String,
      default: null
    },
    isHideDelete: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      startTime: this.startDateTime,
      endTime: this.endDateTime,
      configs: {
        date: {
          start: {
            min: this.startDateMin,
            max: this.endDateMin
          },
          end: {
            min: this.startDateMin,
            max: this.endDateMin
          }
        },
        time: {
          start: {
            min: null,
            max: null
          },
          end: {
            min: null
          }
        }
      }

    }
  },
  computed: {
    startTimeLabel () {
      return this.labelPerfix + ' ' + this.$t('start_date_time')
    },
    endTimeLabel () {
      return this.labelPerfix + ' ' + this.$t('end_date_time')
    },
    hasStartTime () {
      return !!this.startTime
    }
  },
  watch: {
    startTime () {
      let startTime
      try {
        startTime = (this.startTime !== '') ? JSON.parse(this.startTime) : null
      } catch (e) {
        startTime = (this.startTime !== '') ? this.startTime : null
      }
      if (this.startTime !== null) {
        this.$emit('start-date-time', startTime)
        // this.endTime = startTime
        this.configs.date.end.min = startTime.toISOString() || startTime || null
        this.configs.time.end.min = this.startTime.toISOString().slice(0, 10) ? this.startTime.toTimeString() : null
      }
    },
    endTime () {
      let endTime
      try {
        endTime = (this.endTime !== '') ? JSON.parse(this.endTime) : null
      } catch (e) {
        endTime = (this.endTime !== '') ? this.endTime : null
      }
      if (this.endTime !== null) {
        this.$emit('end-date-time', endTime)
        this.configs.date.start.max = endTime.toISOString() || endTime
      }
    },
    startDateMin () {
      this.configs.date.start.min = this.startDateMin
    }
  },
  mounted () {
    this.$watch(
      () => {
        return this.$refs.endDateTimePicker.date
      },
      (val) => {
        this.configs.time.start.max = (this.startTime && this.endTime && val === this.startTime.toISOString().slice(0, 10)) ? this.endTime.toTimeString() : null
        this.configs.time.end.min = (this.startTime && this.endTime && val === this.startTime.toISOString().slice(0, 10)) ? this.endTime.toTimeString() : null
      }
    )
  },
  methods: {
    deleteData () {
      this.$emit('delete-date-time')
    }
  }
}
</script>
<style scoped>
</style>
