import axios from 'axios'
import mediaHandler from '~/plugins/media/mediaHandler'
import isEmpty from 'lodash/isEmpty'
import Bugsnag from '@bugsnag/js'
import { removeFromS3 } from '~/plugins/media/mediaDelete'

// State will be null if we have never retrieved items
// it will be empty if we have retrieve an empty set
const state = {
  ownerMediaItems: null,
  eventMediaItems: null
}

const getters = {
  eventMediaItems: state => state.eventMediaItems,
  ownerMediaItems: state => state.ownerMediaItems
}

const mutations = {
  SET_EVENT_MEDIA_ITEMS: (state, mediaItems) => {
    state.eventMediaItems = mediaItems
  },
  SET_OWNER_MEDIA_ITEMS: (state, mediaItems) => {
    state.ownerMediaItems = mediaItems
  },
  ADD_OWNER_MEDIA_ITEM: (state, mediaItem) => {
    state.ownerMediaItems.push(mediaItem)
  },
  ADD_EVENT_MEDIA_ITEM: (state, mediaItem) => {
    state.eventMediaItems.push(mediaItem)
  },
  DELETE_MEDIA_ITEM: (state, mediaItemId) => {
    // We cannot delete media items attached to an event - only update owners collection
    if (!isEmpty(state.ownerMediaItems)) {
      // eslint-disable-next-line eqeqeq
      const index = state.ownerMediaItems.findIndex(c => c.id == mediaItemId)
      state.ownerMediaItems.splice(index, 1)
    }
  }
}

const actions = {
  // Get all media items for a specific event
  async getEventMediaItems ({ commit, state, dispatch }, eventId) {
    if (state.eventMediaItems === null) {
      try {
        const resp = await axios.get(`/event/${eventId}/media-items`)
        commit('SET_EVENT_MEDIA_ITEMS', transformMediaItems(resp.data))
      } catch (err) {
        Bugsnag.notify(err)
        const notification = {
          type: 'error',
          message: `Error getting media-items for event : ${err.message}`
        }
        dispatch('notifications/addNotification', notification, { root: true })
      }
    }
  },
  // Get all media items for the current user (Event owner)
  async getAllMediaItems ({ commit, state, dispatch }) {
    if (state.ownerMediaItems === null) {
      try {
        const resp = await axios.get('/media-items')
        commit('SET_OWNER_MEDIA_ITEMS', transformMediaItems(resp.data))
      } catch (err) {
        Bugsnag.notify(err)
        const notification = {
          type: 'error',
          message: `Error getting media-items for owner : ${err.message}`
        }
        dispatch('notifications/addNotification', notification, { root: true })
      }
    }
  },
  // TODO: Some work todo here, if we don't create an attachment - then the item cannot belong to the event
  // TODO: if the current user is not the event owner, then this item does not belong to the owner media
  async addMediaItem ({ commit, dispatch }, mediaItem) {
    try {
      const resp = await axios.post('/media-items', mediaItem)
      const transformedRespData = transformMediaItems(resp.data)
      if (state.ownerMediaItems !== null) {
        commit('ADD_OWNER_MEDIA_ITEM', transformedRespData)
      }
      if (state.eventMediaItems !== null) {
        commit('ADD_EVENT_MEDIA_ITEM', transformedRespData)
      }
      return transformedRespData
    } catch (err) {
      Bugsnag.notify(err)
      const notification = {
        type: 'error',
        message: `Error creating media-item : ${err.message}`
      }
      dispatch('notifications/addNotification', notification, { root: true })
      return {}
    }
  },
  async deleteMediaItem ({ commit, dispatch }, mediaItem) {
    try {
      await removeFromS3(mediaItem.s3_object_name).then(async resp => {
          await axios.delete(`/media-items/${mediaItem.id}`)
          commit('DELETE_MEDIA_ITEM', mediaItem.id)
      }).catch(err => console.log(err))
      return true
    } catch (err) {
      Bugsnag.notify(err)
      const notification = {
        type: 'error',
        message: `Error deleting media-item : ${err.message}`
      }
      dispatch('notifications/addNotification', notification, { root: true })
      return false
    }
  }
}
// Transform media items, add media output for display
// We can accept a single mediaItem object, or an array of mediaItems
// Return a single object or an array depending on the original parameter
const transformMediaItems = (mediaItemsOrItem) => {
  return mediaHandler.transformMediaItems(mediaItemsOrItem, false, [
    // Sizes we'll create for each media item
    { size: 'medium', fit: 'contain' },
    { size: 'lls', fit: 'contain' },
    { size: 'original' }
  ])
}

export default {
  state,
  getters,
  actions,
  mutations
}
