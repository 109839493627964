import { mapActions, mapGetters } from 'vuex'
import { getDateOnly, getTimeOnly } from '~/plugins/dateTimeHelper'
export const singleEventMixin = {
  computed: {
    ...mapGetters('events', ['singleEvent']),
    eventMedia () {
      // TODO: Simon will provide the way to show the direct image to without any Fonm element
      return this.singleEvent.media
    },
    eventName () {
      return this.singleEvent.name
    },
    startDate () {
      return this.singleEvent.schedule ? getDateOnly(this.singleEvent.schedule.start_date_time) : '-'
    },
    startTime () {
      return this.singleEvent.schedule ? getTimeOnly(this.singleEvent.schedule.start_date_time) : '-'
    },
    endDate () {
      return this.singleEvent.schedule ? getDateOnly(this.singleEvent.schedule.end_date_time) : '-'
    },
    endTime () {
      return this.singleEvent.schedule ? getTimeOnly(this.singleEvent.schedule.end_date_time): '-'
    }
  },
  methods: {
    ...mapActions('events', ['getEvent'])
  },
  async created () {
    if (this.$route.params.event && this.singleEvent.id != this.$route.params.event) {
      await this.showLoader(true)
      await this.getEvent({ id: this.$route.params.event, callback: (data) => {
          this.$root.$emit('eventFetched', data)
        }})
      await this.showLoader(false)
    }
  },
  watch: {
    singleEvent: {
      handler: function () {
        localStorage.lobbyType = this.singleEvent.media.lobby.type;
        if(this.singleEvent && this.singleEvent.is_dark_mode){
          this.$vuetify.theme.dark = 1
        }
        localStorage.setItem('theme', this.$vuetify.theme.dark)
      }
    }
  }
}
