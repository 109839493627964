import axios from 'axios'

const state = {
  dragging: false,
  event_ticket: null,
  event_sponsor: null,
  ticket_help_popup: false,
  sponsor_help_popup: false,
  drag_type: null
}

const getters = {
  dragging: state => state.dragging,
  event_ticket: state => state.event_ticket,
  event_sponsor: state => state.event_sponsor,
  ticket_help_popup: state => state.ticket_help_popup,
  sponsor_help_popup: state => state.sponsor_help_popup,
  drag_type: state => state.drag_type
}

const mutations = {
  SET_DRAGGING (state, event) {
    state.dragging = event
  },
  SET_TICKET_POPUP (state, status) {
    state.ticket_help_popup = status
    state.drag_type = 'ticket'
  },
  SET_SPONSOR_POPUP (state, status) {
    state.sponsor_help_popup = status
    state.drag_type = 'sponsor'
  },
  SET_TICKET_DATA (state, eventTicket) {
    state.event_ticket = eventTicket
    state.ticket_help_popup = eventTicket !== null
    // setTimeout(() => {
    //   state.ticket_help_popup = false
    // }, 1000)
  },
  SET_SPONSOR_DATA (state, eventSponsor) {
    state.event_sponsor = eventSponsor
    state.sponsor_help_popup = eventSponsor !== null
    // setTimeout(() => {
    //   state.sponsor_help_popup = false
    // }, 1000)
  }
}

const actions = {
  dragStart: (context, ev) => {
    ev.dataTransfer.setData('Text', ev.target.id)
    ev.dataTransfer.dropEffect = 'move'
    ev.dataTransfer.effectAllowed = 'move'
    setTimeout(() => {
      ev.target.classList.add('opacity-0')
    }, 0)
    context.commit('SET_DRAGGING', true)
    if (ev.target.id === 'ticket') {
      context.commit('SET_TICKET_POPUP', false)
    } else if (ev.target.id === 'sponsor') {
      context.commit('SET_SPONSOR_POPUP', false)
    }
  },
  dragEnter: (context, ev) => {
    /*
    if (ev.clientY > ev.target.height / 2) {
      ev.target.style.marginBottom = '10px'
    } else {
      ev.target.style.marginTop = '10px'
    }
    */
  },
  dragLeave: (context, ev) => {
    console.log(' Drag Leave')
  },
  dragEnd: (context, ev) => {
    console.log(' Drag End')
    context.commit('SET_DRAGGING', false)

    const type = ev.dataTransfer.getData('Text')
    if (type === 'ticket' || context.state.drag_type === 'ticket') {
      context.commit('SET_TICKET_POPUP', true)
    } else if (type === 'sponsor' || context.state.drag_type === 'sponsor') {
      context.commit('SET_SPONSOR_POPUP', true)
    }
    setTimeout(() => {
      ev.target.classList.remove('opacity-0')
    }, 0)
  },
  getUnassignedTicket: (context, eventId) => {
    return axios.get(`event/${eventId}/unassigned-data`).then(({ data }) => {
      context.commit('SET_TICKET_DATA', data.event_ticket)
      context.commit('SET_SPONSOR_DATA', data.event_sponsor)
      return data
    })
  },
  setTicketData: (context, ticketData) => {
    context.commit('SET_TICKET_DATA', ticketData)
  },
  setSponsorData: (context, sponsorData) => {
    context.commit('SET_SPONSOR_DATA', sponsorData)
  },
  setSponsorPopup: (context, popup) => {
    context.commit('SET_SPONSOR_POPUP', popup)
  },
  setTicketPopup: (context, popup) => {
    context.commit('SET_TICKET_POPUP', popup)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
