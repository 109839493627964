import axios from 'axios'
import { getMediaObject } from '~/plugins/helpers'
import * as types from '../mutation-types'
const state = {
  schedules: { liveSessions: [], futureSessions: [], pastSessions: [] },
  filters: [],
  isBusy: false,
  currentSession: localStorage.currentSession ? JSON.parse(localStorage.currentSession) : null,
  userDateTime: new Date()
}

const getters = {
  schedules: state => state.schedules,
  filters: state => state.filters,
  isBusy: state => state.isBusy,
  currentSession: state => state.currentSession,
  userDateTime: state => state.userDateTime
}

const mutations = {
  set: (state, schedules) => {
    state.schedules = schedules
  },
  setFilters: (state, filters) => {
    state.filters = filters
  },
  setBusy: (state, flag) => {
    state.isBusy = flag
  },
  setUserDateTime: (state, dateTime) => {
    state.userDateTime = dateTime
  },
  [types.SAVE_CURRENT_SESSION] (state, currentSession) {
    state.currentSession = currentSession
    localStorage.currentSession = JSON.stringify(currentSession)
  }
}

const actions = {
  getSchedules: async (context, eventId) => {
    context.commit('setBusy', true)
    return axios.get(`/event/${eventId}/get-schedules`)
      .then(({ data }) => {
        data.schedules.liveSessions.forEach(session => {
          session.media = getMediaObject(session)
        })
        data.schedules.futureSessions.forEach(session => {
          session.media = getMediaObject(session)
        })
        data.schedules.pastSessions.forEach(session => {
          session.media = getMediaObject(session)
        })
        context.commit('set', data.schedules)
        context.commit('setFilters', data.filters)
        context.commit('setUserDateTime', data.userCurrentDate)
      }).finally(() => {
        context.commit('setBusy', false)
      })
  },
  addToMySchedule: async ({ context, dispatch }, { scheduleId, directModelName, eventId }) => {
    return axios.post('my-schedule', { schedule_id: scheduleId, direct_model_name: directModelName })
      .then(({ data }) => {
        dispatch('getSchedules', eventId)
      })
  },
  removeFromMySchedule: async ({ context, dispatch }, { myScheduleId, eventId }) => {
    return axios.delete(`my-schedule/${myScheduleId}`)
      .then(({ data }) => {
        dispatch('getSchedules', eventId)
      })
  },
  setCurrentSession ({ commit, dispatch }, session) {
    commit(types.SAVE_CURRENT_SESSION, session)
  },
  getSchedule: async (context, sessionId) => {
    context.commit('setBusy', true)
    return axios.get(`/event/get-schedule/${sessionId}`)
      .then(({ data }) => {
        return data
      }).finally(() => {
        context.commit('setBusy', false)
      })
  }
}


export default {
  state,
  getters,
  actions,
  mutations
}
