<template>
  <div>
    <label v-if="label"
           class="ra__fields-label ra-fields--text-input__label"
           :class="{ 'ra__fields-label--required' : isRequired }"
           :for="labelFor"
    >
      {{ label }}<span>{{ $t('event.event_select_city') }}</span>
      <v-icon v-if="hasHelpIcon" class="ra__label-icon--title" @click.stop="displayHelp">mdi-help-circle-outline</v-icon>
    </label>
    <v-autocomplete
      v-model="timezone_id"
      :items="timezones"
      :loading="isLoading"
      :search-input.sync="search"
      item-text="location_name"
      item-value="id"
      class="ra__input"
      chips
      filled
      solo
      flat
      clearable
      :label="$t('search_for_your_nearest_city')"
      v-bind="$attrs"
      :no-data-text="$t('autocomplete_no_data_text')"
      @change="valueChange"
    >
      <template #selection="{ attr, on, item, selected }">
        <v-chip
          v-bind="attr"
          :input-value="selected"
          class="timezone__country-chip"
          v-on="on"
        >
          <v-avatar>
            <v-img :src="getCountryFlag(item.timezone.country_code)" />
          </v-avatar>
          <span class="timezone__country" v-text="item.location_name" />
        </v-chip>
      </template>
      <template #no-data>
        <v-list-item>
          <v-list-item-title>
            {{ $t('search_for_your_nearest_city') }}
          </v-list-item-title>
        </v-list-item>
      </template>
      <template #item="{ item }">
        <v-list-item-avatar>
          <v-img :src="getCountryFlag(item.timezone.country_code)" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-text="item.location_name + ', ' " />
          <v-list-item-subtitle v-text="(item.state ? item.state+', ': '') + item.timezone.country_name + ', GMT' + item.timezone.standard_offset+ ', ' + item.timezone.standard_timezone_name" />
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import axios from 'axios'
import { formComponentMixin } from '~/mixins/formComponentMixin'
import { debounce } from 'lodash'

export default {
  name: 'TimezoneAutocomplete',
  mixins: [formComponentMixin],
  props: {
    // Props : are set in formComponentMixin
    value: {
      type: Number,
      default: 0
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    hasHelpIcon: {
      type: Boolean,
      default: false
    },
    helpIconForm: {
      type: String,
      default: ''
    },
    helpIconField: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      timezone_id: this.value,
      timezones: [],
      isLoading: false,
      search: null,
      cancelSource: null,
      showFieldHelpFlag: false
    }
  },
  watch: {
    value: {
      handler: function (val) {
        this.timezone_id = val
      },
      immediate: true
    },
    search: debounce(function (newVal) {
      if (newVal) {
        this.preApiCall(newVal)
      }
    }, 300)
  },
  created () {
    this.$nextTick(() => {
      if (this.value) {
        this.isLoading = true
        return axios.get(`timezone/${this.value}`).then(response => {
          this.timezones.push(response.data)
        }).catch(error => {
          console.log(error)
        }).finally(response => {
          this.isLoading = false
        })
      }
    })
  },
  methods: {
    preApiCall (val) {
      if (this.cancelSource) {
        this.cancelSource.cancel()
      }
      this.apiCall(val)
    },
    apiCall (query) {
      if (query !== '') {
        this.timezones = []
        this.isLoading = true
        this.cancelSource = axios.CancelToken.source()
        axios.get('/search_city_zone', { params: { query: query } }).then(response => {
          this.timezones = response.data
        }).finally(response => {
          this.isLoading = false
        })
      }
    },
    valueChange (newVal) {
      this.$emit('input', newVal)
    },
    getCountryFlag (countryCode) {
      if (countryCode) { return 'https://flagcdn.com/w320/' + countryCode + '.png' } else { return '' }
    },
    displayHelp () {
      this.showFieldHelpFlag = true
      this.$root.$emit('get-help-field-info', this.showFieldHelpFlag, this.helpIconForm, this.helpIconField)
    }
  }
}
</script>

<style scoped>

</style>
