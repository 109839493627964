<template>
  <div class="media-display">
    <div v-if="isProfileImage" class="media-display__profile-wrap" :style="{ height: profileSize }">
      <div v-if="hasMedia && !imageFailed">
        <v-overlay
          :absolute="true"
          :value="!isProfileImageLoaded"
        >
          <v-progress-circular
            indeterminate
            color="blue"
          />
        </v-overlay>
        <v-img
          v-if="isProfileImageLoaded"
          class="inverted media-display__profile-photo"
          :min-height="profileSize"
          :src="profileDisplayUrl"
          :alt="mediaText"
          :width="profileSize"
        />
      </div>
      <v-icon v-if="!hasMedia || imageFailed"
              :size="profileSize"
              color="blue darken-2"
              class="media-display__profile-icon"
      >
        mdi-emoticon-cool
      </v-icon>
    </div>
    <template v-else>
      <div v-if="displayAsImage">
        <v-img class="inverted"
               :src="image.src"
               :alt="mediaText"
               :srcset="image.srcSet"
               :sizes="sizes"
               :lazy-src="image.lazyLoadSrc"
               :aspect-ratio="aspectRatio"
        />
      </div>
      <div v-if="!displayAsImage && isPdf">
        <a :href="pdfUrl">
          <v-img class="inverted "
                 :src="image.src"
                 :alt="mediaText"
                 :srcset="image.srcSet"
                 :sizes="sizes"
                 :lazy-src="image.lazyLoadSrc"
                 :aspect-ratio="aspectRatio"
          />
        </a>
      </div>
      <div v-if="isVideo">
        <template v-if="video.isEmbedded">
          <v-responsive :aspect-ratio="aspectRatio">
            <iframe frameborder="0"
                    :src="video.url"
                    class="ra__media-display media-display__iframe-border"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
            />
          </v-responsive>
        </template>
        <template v-else>
          <video
            :autoplay="autoplay"
            controls
            class="ra__media-display"
          >
            <source :src="video.url"
                    type="video/mp4"
            >
            Sorry, your browser doesn't support embedded videos.
          </video>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import mediaDisplay from '~/plugins/media/mediaDisplay'
import mediaHandler from '~/plugins/media/mediaHandler'
import isEmpty from 'lodash/isEmpty'

export default {
  name: 'RaMediaDisplay',
  props: {
    isProfileImage: { // True = user profile image or host image - anything displayed in user profile format
      type: Boolean,
      default: false
    },
    location: {
      type: String,
      default: 'primary'
    },
    media: {
      type: Object,
      default: () => {
      }
    },
    sizes: {
      type: String,
      default: ''
    },
    aspectRatio: {
      type: Number,
      default: 0
    },
    noPdfDownload: { // True = Display PDF image only, no download link (for event modal)
      type: Boolean,
      default: false
    },
    profileImageSize: {
      type: String,
      default: 'small'
    },
    frontDisplay: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isProfileImageLoaded: false,
    imageFailed: false,
    aspectRationFallback: 16 / 9,
    profileSizes: {
      extraLarge: 200,
      large: 120,
      medium: 70,
      small: 40
    }
  }),

  computed: {
    locationAspectRatio () {
      // if aspect ration property is set, then use it, otherwise use aspect ratio for the location
      return this.aspectRatio
        // Use override aspect ratio if set (unlikely)
        ? this.aspectRatio
        // eslint-disable-next-line no-prototype-builtins
        : (this.hasMedia && this.media.hasOwnProperty('aspectRatio') && this.media.aspectRatio) // Use aspect ratio for the attachment location (set on media)
            ? this.media.aspectRatio
            : this.aspectRationFallback // Good old 16 : 9
    },
    profileSize () {
      return !this.isProfileImage
        ? ''
        // eslint-disable-next-line no-prototype-builtins
        : `${(this.profileSizes.hasOwnProperty(this.profileImageSize) ? this.profileSizes[this.profileImageSize] : this.profileSizes.small)}px`
    },
    profileUrl () {
      return this.hasMedia && this.isProfileImage
        ? mediaHandler.transformMediaItems(this.media.media_item, true).urls.thumb
        : ''
    },
    profileDisplayUrl () {
      return this.hasMedia && this.isProfileImage && this.isProfileImageLoaded ? this.profileUrl : ''
    },
    hasMedia () {
      return !isEmpty(this.media)
    },
    locationMedia () {
      return this.hasMedia && !this.isProfileImage ? this.media[this.location] : {}
    },
    mediaType () {
      return this.hasMedia && !this.isProfileImage ? this.locationMedia.type : ''
    },
    pdfUrl () {
      return this.isPdf ? this.locationMedia.pdfUrl : ''
    },
    mediaText () {
      return this.hasMedia && !this.isProfileImage ? mediaDisplay.getAltText(this.locationMedia) : ''
    },
    image () {
      return (this.isImage && !this.isProfileImage) || this.isPdf
        ? {
            src: mediaDisplay.getSource(this.locationMedia),
            srcSet: mediaDisplay.getSourceSet(this.locationMedia),
            lazyLoadSrc: mediaDisplay.getLazyLoadSource(this.locationMedia)
          }
        : {}
    },
    autoplay () {
      return !!this.frontDisplay
    },
    video () {
      if (!this.frontDisplay) { this.locationMedia.video.autoplay = false }
      return this.isVideo
        ? {
            isEmbedded: mediaDisplay.isEmbedded(this.locationMedia),
            url: mediaDisplay.getVideoUrl(this.locationMedia),
            provider: mediaDisplay.getProvider(this.locationMedia)
          }
        : {}
    },
    isImage () {
      return this.hasMedia && !this.isProfileImage ? this.mediaType === 'image' : ''
    },
    isVideo () {
      return this.hasMedia && !this.isProfileImage ? this.mediaType === 'video' : ''
    },
    isPdf () {
      return this.hasMedia && !this.isProfileImage ? this.mediaType === 'pdf' : ''
    },
    displayAsImage () {
      return (this.isImage && !this.isProfileImage) || (this.isPdf && this.noPdfDownload)
    }
  },
  watch: {
    media: function () {
      if (this.isProfileImage && this.hasMedia) {
        this.isProfileImageLoaded = false
        this.checkImageStatus(this.profileUrl)
      }
    }
  },
  created () {
    if (this.isProfileImage && this.hasMedia) {
      this.checkImageStatus(this.profileUrl)
    }
  },
  methods: {
    checkImageStatus (url) {
      (async () => {
        try {
          await mediaHandler.isImageAvailable(url)
          this.isProfileImageLoaded = true
        } catch (e) {
          this.imageFailed = true
        }
      })()
    }
  }
}
</script>

<style lang="scss" scoped>
.media-display {
  &__profile-icon {

  }
  &__profile-photo {
    border-radius: 50%;
  }
  &__iframe-border{
    border: none;
  }
}
</style>
