<template>
  <v-row class="profile-image businesscard__profilebox" justify="center">
    <v-col cols="12">
      <!-- Display existing user profile image / fallback image -->
      <div v-if="!changeImage"
           class="profile-image__image-wrap"
      >
        <RaMediaDisplay
          :v-if="isS3UploadComplete"
          :is-profile-image="true"
          :media="userMedia"
          profile-image-size="large"
        />
        <div class="profile-image__image-wrap-buttons profile__btngroup">
          <v-btn
            outlined
            class="btn-outline-primary"
            @click="onChangeImage"
          >
            {{ changeImageText }}
          </v-btn>
          <v-btn v-if="hasAttachment"
            outlined
            class="btn-outline-danger"
            @click="onDeleteImage"
          >
            Delete
          </v-btn>
        </div>
        <!-- Display photo/upload dialog -->
      </div>
      <div v-else>
        <div class="profile-image__container">
          <v-overlay
            :absolute="true"
            :value="isLoading"
          >
            <v-progress-circular
              indeterminate
              color="blue"
            />
          </v-overlay>
          <div v-if="displayMainDialog"
               class="profile-image__dialog-button-wrap"
          >
            <v-btn
              class="profile-image__dialog-button profile-image__dialog-button--photo btn-outline-primary"
              depressed
              @click="onOpenCamera"
            >
              <v-icon left medium>
                mdi-camera
              </v-icon>
              Take photo
            </v-btn>
            <v-btn
              class="profile-image__dialog-button profile-image__dialog-button--upload btn-outline-primary"
              large
              depressed
              @click="onUploadFile"
            >
              <v-icon left medium>
                mdi-image
              </v-icon>
              Upload file
            </v-btn>
          </div>
          <RaUserProfileUpload v-if="uploadOpen"
                               @uploaded-media-save="processUploadedMedia"
          />
          <!-- Capture user image from camera -->
          <RaCameraCapture
            v-if="cameraOpen"
            @image-captured="onCapturedImage"
            @cancel-capture="onCancelCapture"
          />
          <RaProfileCropper
            v-if="!!cropUrl"
            :img-src="cropUrl"
            @cropper-save="onCropSave"
            @new-photo="onNewPhoto"
          />
        </div>
        <div class="profile__btngroup text-center">
          <v-btn
            large
            depressed
            class="btn-filled"
            @click="onCancelChange"
          >
            cancel
          </v-btn>
        </div>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { uploadToS3 } from '~/plugins/media/mediaUploader'
import RaCameraCapture from '~/components/media/RaCameraCapture'
import RaProfileCropper from '~/components/media/RaProfileCropper'
import RaMediaDisplay from '~/components/media/RaMediaDisplay'
import RaUserProfileUpload from '~/components/media/RaUserProfileUpload'
import { mapActions, mapGetters } from 'vuex'
import isEmpty from 'lodash/isEmpty'

export default {
  components: { RaCameraCapture, RaProfileCropper, RaMediaDisplay, RaUserProfileUpload },
  data () {
    return {
      mimeType: 'image/png',
      cameraImage: '', // Base64 image
      croppedImage: '',
      changeImage: false,
      cameraOpen: false,
      uploadOpen: false,
      sizes: '200px',
      isLoading: false,
      uploadedFile: '',
      uploadedFileType: '',
      isS3UploadComplete: false
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    userMedia () {
      return this.hasAttachment ? this.user.attachments : null
    },
    hasAttachment () {
      return !isEmpty(this.user.attachments)
    },
    cropUrl () {
      return this.cameraImage
        ? this.cameraImage
        : this.uploadedFile ? URL.createObjectURL(this.uploadedFile) : ''
    },
    changeImageText () {
      return this.hasAttachment ? 'change' : 'add'
    },
    displayMainDialog () {
      return !(this.cameraOpen || this.uploadOpen || this.cropUrl)
    }
  },
  methods: {
    ...mapActions('mediaItems', [
      'addMediaItem', 'deleteMediaItem'
    ]),
    ...mapActions('auth', [
      'userAttachment', 'deleteAttachment'
    ]),
    onNewPhoto () {
      if (this.cameraImage) {
        this.cameraImage = ''
        this.cameraOpen = true
      } else {
        this.uploadedFile = ''
        this.uploadOpen = true
      }
    },
    onChangeImage () {
      this.changeImage = true
    },
    onCapturedImage (base64) {
      this.cameraImage = base64
      this.cameraOpen = false
    },
    onCancelCapture () {
      this.reset()
    },
    processUploadedMedia ({ file, type }) {
      this.uploadOpen = false
      this.uploadedFile = file
      this.uploadedFileType = type
    },
    onCropSave ({ cropBlob }) {
      this.croppedImage = cropBlob
      this.saveMedia()
    },
    onOpenCamera () {
      this.cameraOpen = true
    },
    onUploadFile () {
      this.uploadOpen = true
    },
    async saveMedia () {
      this.isLoading = true
      //
      // If we have existing media, then delete the mediaItem and S3 asset
      if (this.hasAttachment) {
        await this.deleteMediaItem(this.user.attachments.media_item)
      }
      //
      const fileName = this.user.name ? `${this.user.name}.jpg` : 'user-profile.jpg'
      const fileFromBlob = new File([this.croppedImage], fileName)
      //
      // Upload media to S3
      const { success, s3ObjectName, resp } = await uploadToS3({
        ownerId: this.user.id,
        mimeType: fileFromBlob.type,
        fileName: fileFromBlob.name,
        fileObject: fileFromBlob
      })
      if(success) {
        this.isS3UploadComplete = true
      }
      else{
        return
      }
      // We'll wait for the S3 upload to complete before we allow the profile component to request the cloudfront image
      // resp.then(() => {
      //   this.isS3UploadComplete = true
      // })
      //
      // Create media item
      const newMediaItem = await this.addMediaItem({
        s3_object_name: s3ObjectName,
        original_file_name: fileName,
        byte_size: fileFromBlob.size,
        type: 'image',
        user_id: this.user.id,
        is_profile: true
      })
      if (isEmpty(newMediaItem)) {
        return {} // Something went wrong - just exit
      }
      //
      // Create media attachment
      await this.userAttachment({
        attachment: {
          id: this.hasAttachment ? this.user.attachments.id : null, // Update attachment if one exists
          attachment_location: 'primary',
          crop_object: null,
          alt_text: this.user.name ? this.user.name : 'user profile',
          media_item_id: newMediaItem.id,
          media_item: newMediaItem
        }
      })
      this.isLoading = false
      this.reset()
    },
    async onDeleteImage () {
      this.isLoading = true
      const mediaItem = this.user.attachments.media_item
      await this.deleteAttachment()
      await this.deleteMediaItem(mediaItem)
      this.isLoading = false
    },
    onCancelChange () {
      this.reset()
    },
    reset () {
      clearTimeout(this.timeout)
      this.changeImage = false
      this.cameraOpen = false
      this.uploadOpen = false
      this.cameraImage = ''
      this.croppedImage = ''
      this.uploadedFile = ''
    }
  }
}
</script>
<style lang="scss" scoped>
.profile-image {
  min-width: 200px;
  &__container {
    background-color: #f2f4f9;
    border: 1px dashed #c8c8c8;
    min-width: 200px;
    min-height: 200px;
    margin: auto;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    &--solid {
      border: solid;
    }
  }
  &__image-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  &__image-wrap-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__dialog {
    border-radius: 15px;
    min-width: 200px;
    min-height: 200px;
    border: 1px dashed #c8c8c8;
    flex-direction: column;
    display: flex;
    justify-content: space-around;
    text-align: center;
  }

  &__dialog-button-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__dialog-button {
    width: 80%;

    &--photo {
      margin-bottom: 20px;
    }

    &--upload {
    }
  }

  &__drop-area {
    border-radius: 15px;
    min-width: 200px;
    min-height: 200px;
    border: 1px dashed #c8c8c8;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;

    &--hover {
    }
  }
}
</style>
