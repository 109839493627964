export default {
  install: (app, options) => {
    app.prototype.applyTitleColor = function () {
    // Reset for titles
    // const prefix = 'section__title-'
    // const regx = new RegExp('\\b' + prefix + '[^ ]*[ ]?\\b', 'g')
      const temp = document.getElementsByClassName('event-modal__section-title')
      temp.forEach((element, index) => {
        if (element.className.indexOf('section__title-') > -1) {
          const sectionClass = element.className.match('\\bsection__title-[^ ]*[ ]?\\b')
          if (sectionClass && sectionClass[0]) element.classList.remove(sectionClass[0])
        }
        element.classList.add('section__title-' + (index + 1))
      })
    }
  }
}
