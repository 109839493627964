import store from '~/store'

export default async (to, from, next, vm) => {
  if (to.params.event) {
    try {
      let data = { event: to.params.event }
      if (to.name == 'event-lobby.session-stage' && localStorage.getItem('currentSession')) {
        const currentSession = JSON.parse(localStorage.getItem('currentSession'))
        data = { event: to.params.event, session_id: currentSession.id, type: currentSession.type }
      }
      if (to.name == 'event-lobby.poster-presentation') {
        data = { event: to.params.event, poster_id: to.params.poster, type: 'EventPoster' }
      }

      if (to.name == 'event-lobby.expo-company') {
        data = { event: to.params.event, company_id: to.params.company, type: 'EventExpoCompany' }
      }

      store.dispatch('permission/fetchPermission', data).then(() => {
        if (JSON.parse(sessionStorage.authorization).permissions.length == 0) {
          next({ name: 'dashboard' })
        } else {
          next()
        }
      }).catch(error => {
        next({ name: 'login' })
      })
    } catch (e) {
      next({ name: 'login' })
    }
  } else {
    next()
  }
}
