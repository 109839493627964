<template>
  <div v-if="startTour">
    <div class="onboarding__backdrop"></div>
    <div class="onboarding">
      <v-tour v-if="showTour" name="tour-guide-1" :steps="steps" :callbacks="callbacks" :options="{ highlight: true }">
        <template slot-scope="tour">
          <v-step
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :finish="tour.finish"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
          >
            <template>
              <div slot="content">
                <p  v-html="tour.steps[tour.currentStep].body">
                  {{ tour.steps[tour.currentStep].body }}
                </p>
                <div v-if="hasEventBuilder">
                  <input id="checkBox" type="checkbox" name="checkBox" value="checkBox" @click="doNotShowTour">
                  <label for="checkBox">Do not Show This Again!!</label>
                </div>
              </div>
            </template>
            <template v-if="hasEventTicket || hasEventSponsor">
              <div slot="actions">
                <button @click="tour.stop" class="v-step__button v-step__button-skip">Skip Tour</button>
                <button @click="tour.stop" class="v-step__button v-step__button-next">Finish </button>
              </div>
            </template>
          </v-step>
        </template>
      </v-tour>
    </div>
  </div>
</template>

<script>
import tourGuideSteps from '~/collections/onBoardingTourSteps.json'
import axios from 'axios'
import { mapGetters } from 'vuex'
export default {
  name: 'OnBoardingTour',
  data () {
    return {
      steps: [],
      callbacks: {
        onStop: this.finishTour,
        onSkip: this.skipTour
      },
      checked: false,
      tourName: '',
      startTour: false,
      isMobileLiveLayout: false
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    showTour () {
      return this.steps && this.steps.length > 0
    },
    hasLiveLayout () {
      return this.tourName && this.tourName === 'live_layout'
    },
    hasEventTicket () {
      return this.tourName && this.tourName === 'event_tickets'
    },
    hasEventSponsor () {
      return this.tourName && this.tourName === 'event_sponsors'
    },
    hasEventBuilder () {
      return this.tourName && this.tourName === 'event_builder'
    }
  },
  mounted () {
    this.$root.$on('start-on-boarding-event-builder', (tourName) => {
      this.tourName = tourName
      this.steps = tourGuideSteps.event_builder
      this.isShowTour(tourName)
    })
    this.$root.$on('start-on-boarding-tour-live-layout', (tourName) => {
      this.tourName = tourName
      this.steps = tourGuideSteps.live_layout
      this.isShowTour(tourName)
    })
    this.$root.$on('restart-on-boarding-tour-live-layout', (tourName) => {
      this.tourName = tourName
      this.steps = tourGuideSteps.live_layout
      this.startTour = true
      this.$nextTick(() => {
        this.$tours['tour-guide-1'].start()
      })
    })    
    this.$root.$on('start-on-boarding-event-ticket', (tourName) => {
      this.tourName = tourName
      this.steps = tourGuideSteps.event_tickets
      this.isShowTour(tourName)
    })
    this.$root.$on('start-on-boarding-event-sponsor', (tourName) => {
      this.tourName = tourName
      this.steps = tourGuideSteps.event_sponsors
      this.isShowTour(tourName)
    })
    this.$root.$on('start-on-boarding-live-layout-mobile', (tourName) => {
      this.tourName = tourName
      this.isMobileLiveLayout = true
      this.steps = tourGuideSteps.mobile_live_layout
      this.isShowTour(tourName)
    })
  },
  methods: {
    isShowTour (tourName) {
      const isShowTour = this.user.cannot_display_on_boarding_tours.includes(tourName)
      if (!isShowTour) {
        this.startTour = true
        this.$nextTick(() => {
          this.$tours['tour-guide-1'].start()
        })
      } else {
        this.startTour = false
      }
    },
    doNotShowTour () {
      this.checked = document.querySelector('#checkBox').checked
      if (this.checked) {
        axios
          .post('/user/do-not-show-tour', { tour_name: this.tourName })
          .then((response) => {
            this.$store.dispatch('auth/fetchUser')
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    finishTour () {
      const isFinish = document.querySelector('.v-step__button-stop')
      if (isFinish && (this.hasLiveLayout || this.isMobileLiveLayout)) {
        axios
          .post('/user/do-not-show-tour', { tour_name: this.tourName })
          .then((response) => {
            this.startTour = false
            this.$store.dispatch('auth/fetchUser')
          })
          .catch((error) => {
            console.log(error)
          })
      } else if (isFinish || this.hasEventTicket || this.hasEventSponsor) {
        axios
          .post('/user/finish-tour', { tour_name: this.tourName })
          .then((response) => {
            this.startTour = false
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    skipTour () {
      if (this.hasLiveLayout || this.isMobileLiveLayout) {
        axios
          .post('/user/do-not-show-tour', { tour_name: this.tourName })
          .then((response) => {
            this.startTour = false
            this.$store.dispatch('auth/fetchUser')
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        this.startTour = false
      }
    }
  }
}
</script>
