import axios from 'axios'
import mediaHandler from '~/plugins/media/mediaHandler'
const state = {
  posterEvent: [],
  posterPresentation: []
}

const getters = {
  posterEvent: state => state.posterEvent,
  posterPresentation: state => state.posterPresentation, 
}
const mutations = {
  posterEvent: (state, posterEvent) => {
    state.posterEvent = posterEvent
  },
  posterPresentation: (state, posterPresentation) => {
    state.posterPresentation = posterPresentation
  },
}

const actions = {
  getPosterEvent: async (context, eventId) => {
    return axios.get(`/event/${eventId}/get-poster-event`)
      .then(({ data }) => {
        const attachmentsKey = 'attachments'
        let mediaModel = 'EventPoster'
        const posterAttachments = attachmentsKey in data ? data[attachmentsKey] : []
        data.media = mediaHandler.getAllMediaForModel(mediaModel, posterAttachments)
        data.poster_presentation.forEach(presentation => {
          // Get attachments for the this presentation
          const attachments = attachmentsKey in presentation ? presentation[attachmentsKey] : []
          mediaModel = 'EventPosterPresentation'
          presentation.media = mediaHandler.getAllMediaForModel(mediaModel, attachments)
        })
        context.commit('posterEvent', data)
      }).catch((error) => {
        console.log(error)
      })
  },
  getPosterPresentation: async (context, poster) => {
    return axios.get(`/event-poster-presentation/${poster}`)
      .then(({ data }) => {
        const attachmentsKey = 'attachments'
        const attachmentsKeyForDownload = 'attachment'
        let mediaModel = 'EventPosterPresentation'
        const posterAttachments = attachmentsKey in data ? data[attachmentsKey] : []
        data.media = mediaHandler.getAllMediaForModel(mediaModel, posterAttachments)
        data.presentation_download.forEach(download => {
          // Get attachments for this downloads
          const attachments = attachmentsKeyForDownload in download ? download[attachmentsKeyForDownload] : []
          mediaModel = 'EventPosterPresentationDownload'
          download.media = mediaHandler.getAllMediaForModel(mediaModel, attachments)
        })
        context.commit('posterPresentation', data)
      }).catch((error) => {
        console.log(error)
      })
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
