import axios from 'axios'
import mediaHandler from '~/plugins/media/mediaHandler'
import {isEmpty} from "lodash";

const state = {
  eventAdvancedGraphics: {},
  predefinedTemplates: {}
}

const getters = {
  eventAdvancedGraphics : state => state.eventAdvancedGraphics,
  predefinedTemplates : state => state.predefinedTemplates
}

const mutations = {
  SET_EVENT_GRAPHICS: (state, advancedGraphics) => {
    state.eventAdvancedGraphics = advancedGraphics
  },
  SET_PREDEFINED_TEMPLATES: (state, templates) => {
    state.predefinedTemplates = templates;
  }
}

const actions = {
  getEventTemplate : async(context, eventId) => {
    return axios.get(`/admin/event-graphics-templates/event/${eventId}`)
      .then(({ data }) => {
        if(data){
          const attachmentsKey = 'attachments'
          let mediaModel = 'EventGraphicChoice'
          const backgroundImages = attachmentsKey in data && data[attachmentsKey] != null ? data[attachmentsKey] : []
          if(isEmpty(backgroundImages)){
            data.background_image = {}
          } else {
            data.background_image = backgroundImages //mediaHandler.getAllMediaForModel(mediaModel, backgroundImages)
          }
          context.commit('SET_EVENT_GRAPHICS', data)
        }
      }).catch((error) => {
        console.log(error)
      })
  },
  getPredefinedTemplates: async (context) => {
    return axios.get(`/admin/event-graphics-choice/get-active-templates`)
      .then(({ data }) => {
        if(data && data.length){
          data.forEach((template)=> {
            const attachmentsKey = 'attachments'
            let mediaModel = 'EventGraphicsTemplate'
            const backgroundImages = attachmentsKey in template && template[attachmentsKey] != null ? template[attachmentsKey] : []
            if(isEmpty(backgroundImages)){
              template.background_image = {}
            } else {
              template.background_image = backgroundImages //mediaHandler.getAllMediaForModel(mediaModel, backgroundImages)
            }
          })
        }
        context.commit('SET_PREDEFINED_TEMPLATES', data)
      }).catch((error) => {
        console.log(error)
      })
  },
  getTemplate : async(context, templateId) => {
    return axios.get(`/admin/event-graphics-templates/${templateId}`)
      .then(({ data }) => {
        return data
      }).catch((error) => {
        console.log(error)
      })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
