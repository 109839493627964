<template>
  <v-app-bar flat class="dashboard-header" fixed app>
    <v-row class="align-center justify-space-between ">
      <div class="d-flex">
        <v-btn
          class="bg-primary-dark header__theme-switch"
          icon
          id="mode-switcher"
          type="button"
          aria-label="theme switcher"
          @click="darkMode"
        >
          <v-img
            id="v-step-14"
            width="32"
            class="header__icon-button"
            :src="($vuetify.theme.dark) ? 'https://reattendance-video-public.s3.eu-west-2.amazonaws.com/Misc/theme-icon-dark.svg' : 'https://reattendance-video-public.s3.eu-west-2.amazonaws.com/Misc/theme-icon-light.svg'"
          />
        </v-btn>
        <v-btn v-if="!isLobbyPage" icon class="hidden-md-and-up" @click.stop="getNavigationBar">
          <v-icon large>
            mdi-menu
          </v-icon>
        </v-btn>
        <div>
          <div class="d-flex networking__header">
            <div v-if="isLobbyPage">
              <div class="d-flex">
                <template v-if="!isDetailPage">
                  <RaWebrtcChatEnvelope />
                  <RaWebrtcChatEnvelopeDisplay />
                </template>
                <!-- Networking Switch -->
                <div class="d-md-none">
                  <v-btn icon aria-label="Networking Switch" id="v-step-21" @click="showNetworkingSwitch">
                    <v-img
                      width="32"
                      class="header__icon-button"
                      :src="($vuetify.theme.dark) ? 'https://reattendance-video-public.s3.eu-west-2.amazonaws.com/Misc/network-icon.svg' : 'https://reattendance-video-public.s3.eu-west-2.amazonaws.com/Misc/network-icon.svg'"
                    />
                  </v-btn>
                  <div class="chat__messagegroup-list networking__switch-mob" :class="{ networking__switch_hide: !displayNetworkSwitch }" v-click-outside="hideNetworkingSwitch">
                    <div class="d-flex pt-5">
                      <p class="networking__side-panel--switchlabel pl-4">{{ $t('networking') }}</p>
                      <v-switch v-model="user.is_network_visible" inset color="#199ED9" />
                    </div>
                  </div>
                </div>    
                <div class="d-none d-md-block">
                  <div class="d-flex">
                    <div class="d-flex align-center pt-1">
                      <p class="text-grey1 pr-3">{{ $t('networking') }}</p>
                      <v-switch id="v-step-15" v-model="user.is_network_visible" inset color="#199ED9" class="mt-2" aria-label="networking switch" />
                    </div>
                    <v-tooltip bottom>
                      <template v-if="!isDetailPage" v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          aria-label="Restart tour"
                          v-bind="attrs"
                          v-on="on"
                          @click="restartTour"
                        >
                          <v-icon color="#199ED9">mdi-help-circle-outline</v-icon>
                        </v-btn>
                        
                      </template>
                      <span>Re-Start tour</span>
                    </v-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <v-spacer />
      <div v-if="isLobbyPage">
        <a  href="/"  aria-label="Event logo">
          <RaMediaDisplay
            class="eventdetails__whitelabel"
            :location="($vuetify.theme.dark) ? 'whitelabeldark' : 'whitelabel'"
            :media="lobbyImage"
            :sizes="sizes"
            :aspect-ratio="134 / 31"
          />
         </a>
      </div>
      <div v-else>
        <a  href="/" aria-label="Reattendance logo">
          <v-img
            contain
            max-width="220"
            :src="($vuetify.theme.dark) ? '/img/reattendance-logo--dark.png' : '/img/reattendance-logo.png'"
          />
        </a>
      </div>
    </v-row>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import RaMediaDisplay from '~/components/media/RaMediaDisplay'
import RaWebrtcChatEnvelope from '~/components/chat/RaWebrtcChatEnvelope'
import RaWebrtcChatEnvelopeDisplay from '~/components/chat/RaWebrtcChatEnvelopeDisplay'
import mediaHandler from '~/plugins/media/mediaHandler'


export default {
  components: {
    RaMediaDisplay, RaWebrtcChatEnvelope, RaWebrtcChatEnvelopeDisplay
  },
  data () {
    return {
      sdrawer: false,
      sizes: '300px',
      cancelClickOutside: false,
      displayNetworkSwitch: false
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('events', ['singleEvent']),
    isDetailPage () {
      return this.$route && this.$route.name && (this.$route.name == 'event.detail')
    },
    isLobbyPage () {
      return this.$route && this.$route.name && (this.$route.name.includes('event-lobby') || this.$route.name == 'event.detail' || this.$route.name == 'event.event-steps')
    },
    lobbyImage () {
      return mediaHandler.getAllMediaForModel('Event', this.singleEvent.attachments)
    }
  },
  mounted () {
    this.$watch('user.is_network_visible', value => {
      if(this.user){
        axios.put(`user/${this.user.id}`, this.user).then(response => {
        }).catch(error => { return })
        this.$root.$emit('webrtcUserNetworkStatusChange', this.user.id, value)
      }
    })
    this.$vuetify.theme.dark = JSON.parse(window.localStorage.getItem('theme'))

    this.$root.$on('turnOnNetworkingSwitch', () => {
      this.user.is_network_visible = true
    })
  },
  async created () {
    if (this.$route.params && this.$route.params.event) {
      await this.getEvent({ id: this.$route.params.event })
    }
  },
  methods: {
    ...mapActions('events', ['getEvent']),
    getNavigationBar () {
      this.sdrawer = !this.sdrawer
      this.$root.$emit('get-navigation', this.sdrawer)
    },
    darkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      localStorage.setItem('theme', this.$vuetify.theme.dark)
      this.$root.$emit('global-overlay', true)
      setTimeout(() => {
        this.$root.$emit('update-event-builder', this.$route.params.event)
        this.$root.$emit('global-overlay', false)
      }, 1000)
      this.$root.$emit('darkMode')
    },
    hideNetworkingSwitch() {
      if(this.cancelClickOutside == false){
        this.displayNetworkSwitch = false
      } else {
        this.cancelClickOutside = false
      }      
    },
    restartTour() {
      this.$root.$emit('restart-on-boarding-tour-live-layout', 'live_layout')
    },
    showNetworkingSwitch() {
      if(this.displayNetworkSwitch == false){
        this.cancelClickOutside = true
        this.displayNetworkSwitch = true
      }      
    }
  }
}
</script>
<style scoped>
.networking__switch_hide{
  display: none;  
}
</style>