<template>
  <v-app>
    <v-main>
      <RaGlobalSnackbar />
      <RaUpgradePlan />
      <RaLoadingSpinner />
      <div class="overlay__global" ref="global_overlay" v-if="showOverlay">
        <v-skeleton-loader type="image" :dark="$vuetify.theme.dark" height="100vh" style="background:#000"></v-skeleton-loader>
      </div>
      <v-container fluid class="py-0">
        <Header />
        <div class="ml-md-14">
          <v-row>
            <LeftNavigation />
            <EventList v-if="eventPath" />
            <DashboardMenu v-else-if="$route.name != 'event.detail'" />
            <router-view />
            <MobileNavigation />
          </v-row>
        </div>
        <OnBoardingTour />
      </v-container>
    </v-main>
    <cookie-law ></cookie-law>
  </v-app>
</template>
<script>
import Header from '../components/Header.vue'
import LeftNavigation from '../components/LeftNavigation.vue'
import EventList from '../components/EventList.vue'
import MobileNavigation from '../components/MobileNavigation.vue'
import OnBoardingTour from '../components/OnBoardingTour'
import DashboardMenu from '../components/DashboardMenu'
import { mapGetters } from 'vuex'
import RaLoadingSpinner from '~/components/general/RaLoadingSpinner'
import CookieLaw from 'vue-cookie-law'
export default {
  name: 'BasicLayout',
  components: { RaLoadingSpinner, DashboardMenu, Header, LeftNavigation, MobileNavigation, EventList, OnBoardingTour, CookieLaw },
  computed: {
    ...mapGetters('auth', ['user']),
    eventPath () {
      return this.validPaths.includes(this.$route.name)
    }
  },
  data () {
    return {
      showOverlay: false,
      validPaths: ['events', 'event.dashboard', 'event.host', 'event.poll', 'event.survey', 'event.email','event.analytics','email_delivery_report','email_details','event.event-sales','event.analytics','userInfoAnalytics']
    }
  },
  created () {
    this.$root.$on('global-overlay', (status) => {
      this.showOverlay = status
    })

    // Set your APP_ID

    window.Intercom('boot', {
      app_id: window.config.appId,
      email: this.user.email,
      name: this.user.name,
      user_id: this.user.id
    });
  }
}
</script>
