import axios from 'axios'
import Bugsnag from '@bugsnag/js'

const state = {
  currencies: []
}

const getters = {
  currencies: state => state.currencies
}

const mutations = {
  set: (state, currencies) => {
    state.currencies = currencies
  }
}

const actions = {
  getCurrencies: async ({ commit, dispatch }) => {
    try {
      const resp = await axios.get('currency')
      commit('set', resp.data)
    } catch (err) {
      Bugsnag.notify(err)
      const notification = {
        type: 'error',
        message: `Error getting media-items for event : ${err.message}`
      }
      dispatch('notifications/addNotification', notification, { root: true })
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
