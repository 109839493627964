<template>
  <v-snackbar
    v-model="snackbar"
    :multi-line="multiLine"
    :color="color"
    bottom
  >
    {{ notification.message }}

    <template #action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'NotificationBar',
  props: {
    notification: {
      type: Object,
      required: true
    },
    timeoutDelay: {
      type: Number,
      default: 2500
    }
  },
  data () {
    return {
      colors: {
        success: 'green darken-1',
        warning: 'orange lighten-1',
        error: 'deep-orange darken-4'
      },
      multiLine: true,
      timeout: null,
      snackbar: true
    }
  },
  computed: {
    color () {
      return this.colors[this.notification.type]
    }
  },
  mounted () {
    this.timeout = setTimeout(() => this.remove(this.notification), this.timeoutDelay)
  },
  beforeDestroy () {
    clearTimeout(this.timeout)
  },
  methods: {
    ...mapActions({ remove: 'notifications/removeNotification' })
  }
}
</script>
