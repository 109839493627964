export const formComponentMixin = {
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number, Object, Array],
      default: ''
    },
    counter: {
      type: String,
      default: undefined
    },
    maxLength: {
      type: [Number, String],
      default: undefined
    },
    placeHolder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    }
  },
  computed: {
    labelFor () {
      return this.id || this.name
    }
  }
}
