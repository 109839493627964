<template>
  <div id="app">
    <loading ref="loading" />
    <RaEventWaitingPopup/>
    <transition name="page" mode="out-in">
      <component :is="layout" v-if="layout" />
    </transition>
  </div>
</template>

<script>
import Loading from './Loading'
import { mapGetters } from 'vuex'
import RaEventWaitingPopup from '~/components/RaEventWaitingPopup'

// Load layout components dynamically.
const requireContext = require.context('~/layouts', false, /.*\.vue$/)

const layouts = requireContext.keys()
  .map(file =>
    [file.replace(/(^.\/)|(\.vue$)/g, ''), requireContext(file)]
  )
  .reduce((components, [name, component]) => {
    components[name] = component.default || component
    return components
  }, {})

export default {
  el: '#app',

  components: {
    Loading,
    RaEventWaitingPopup
  },

  data: () => ({
    layout: null,
    defaultLayout: 'basic'
  }),

  metaInfo () {
    const { appName } = window.config

    return {
      title: appName,
      titleTemplate: `${appName} · %s`
    }
  },
  computed: mapGetters({
    user: 'auth/user',
    authenticated: 'auth/check'
  }),
  mounted () {
    this.$loading = this.$refs.loading
  },

  methods: {
    /**
     * Set the application layout.
     *
     * @param {String} layout
     */
    setLayout (layout) {
      if(typeof layout == 'function'){
        layout = layout(this)
      }
      if (!layout || !layouts[layout]) {
        layout = this.defaultLayout
      }

      this.layout = layouts[layout]
    }
  }
}
</script>
