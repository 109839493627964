import awsParameters from '~/plugins/config/awsParameters'
import axios from 'axios'

const bucketName = awsParameters.aws.bucket_name
const bucketRoot = awsParameters.aws.bucket_root // "file-store/dev"
const s3DeleteUrl = awsParameters.aws.s3_delete_url

const deleteItem = async (fileName)=>{
  const deleteObject = {
    url: s3DeleteUrl,
    config: {
      method: 'get',
      headers: {
        'bucket': bucketName,
        'objectKey': fileName
      },
      params: {
        objectKey: fileName
      }
    }
  }

  try {
    const resp = await axios(deleteObject.url, deleteObject.config)
    return {
      success: true,
    }
  } catch (error) {
    return {
      success: false,
      msg: 'upload url request failed',
      error: error
    }
  }
}

export const removeFromS3 = async (s3_object_name) => {
  const deleteResponse = await deleteItem(s3_object_name)
}
