import axios from 'axios'
import mediaHandler from '~/plugins/media/mediaHandler'
const state = {
  publicSchedules: [],
}

const getters = {
  publicSchedules: state => state.publicSchedules,
}

const mutations = {
  set: (state, publicSchedules) => {
    state.publicSchedules = publicSchedules
  }
}

const actions = {
  getPublicSchedules: async (context, eventId) => {
    return axios.get(`/public-event/${eventId}/get-public-schedules`)
      .then(({ data }) => {
        const attachmentsKey = 'attachments'
        let mediaModel = 'EventExpo'
        data.schedules.forEach(session => {
          // Get attachments for this session
          const attachments = attachmentsKey in session ? session[attachmentsKey] : []
          // Transform attachments into media object, append to session
          // eslint-disable-next-line eqeqeq
          if (session.type === 'EventPlayer') {
            mediaModel = 'EventPlayer'
          } else if (session.type === 'EventNetworkingPlayer') {
            mediaModel = 'EventNetworkingPlayer'
          } else if (session.type === 'EventExpo') {
            mediaModel = 'EventExpo'
          } else if (session.type === 'EventPoster') {
            mediaModel = 'EventPoster'
          }
          session.media = mediaHandler.getAllMediaForModel(mediaModel, attachments)
        })
        context.commit('set', data.schedules)
      })
      .catch((error) => {
        console.log(error)
      })
  },
}


export default {
  state,
  getters,
  actions,
  mutations
}
