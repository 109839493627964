import axios from 'axios'
import Bugsnag from '@bugsnag/js'

const state = {
  timezones: []
}

const getters = {
  timezones: state => state.timezones
}

const mutations = {
  set: (state, timezones) => {
    state.timezones = timezones
  }
}

const actions = {
  getTimezones: async ({ commit, dispatch }) => {
    try {
      const { data } = await axios.get('/timezone')
      commit('set', data)
    } catch (err) {
      Bugsnag.notify(err)
      const notification = {
        type: 'error',
        message: `Error loading timezones : ${err.message}`
      }
      dispatch('notifications/addNotification', notification, { root: true })
      return {}
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
