import axios from 'axios'
const state = {
  currentEventPlayer: {},
  is_event_player_calling: false,
  event_player_promise: {}
}

const getters = {
  currentEventPlayer: state => state.currentEventPlayer,
}

const mutations = {
  SET_CURRENT_EVENT_PLAYER (state, data) {
    state.currentEventPlayer = data
  },
  SET_EVENT_PLAYER_PROMISE: (state, data) => {
    state.event_player_promise = data
  },
  SET_EVENT_PLAYER_LOADING: (state, status) => {
    state.is_event_player_calling = status
  },
}

const actions = {
  getCurrentEventPlayer: (context, { id }) => {
    if (state.is_event_player_calling) {
      return state.event_player_promise
    } else {
      context.commit('SET_EVENT_PLAYER_LOADING', true)
      const promise = axios.get(`/event-player/${id}`)
      .then(({ data }) => {
        context.commit('SET_CURRENT_EVENT_PLAYER', data)
        context.commit('SET_EVENT_PLAYER_LOADING', false)
        return data
      })
      context.commit('SET_EVENT_PLAYER_PROMISE', promise)
      return promise
    }
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
