// auth.js
export const LOGOUT = 'LOGOUT'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const SAVE_USER_TOKEN = 'SAVE_USER_TOKEN'
export const SWITCHED_USER_LOGOUT = 'SWITCHED_USER_LOGOUT'
export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export const UPDATE_USER = 'UPDATE_USER'

// lang.js
export const SET_LOCALE = 'SET_LOCALE'

// Schedule
export const SAVE_CURRENT_SESSION = 'SAVE_CURRENT_SESSION'
