const state = {
  notifications: []
}

let nextId = 1

const mutations = {
  PUSH_NOTIFICATION: (state, notification) => {
    state.notifications.push({
      ...notification, id: nextId++
    })
  },
  DELETE_NOTIFICATION: (state, notificationToRemove) => {
    state.notifications = state.notifications.filter(
      notification => notification.id !== notificationToRemove.id
    )
  }
}

const actions = {
  addNotification ({ commit }, notification) {
    commit('PUSH_NOTIFICATION', notification)
  },
  removeNotification ({ commit }, notificationToRemove) {
    commit('DELETE_NOTIFICATION', notificationToRemove)
  }
}

export default {
  state,
  actions,
  mutations
}
