<template>
  <v-col md="3" lg="2" class="side__wrapper">
    <div class="side__panel">
      <div class="side__panel-block">
        <h5 class="side__panel-title">Menu</h5>
      </div>
      <v-navigation-drawer permanent class="side__panel-menus">
        <v-list class="pt-0">
          <v-list-item-group class="side__panel-color">
            <v-list-item v-for="item in items" :key="item.title" link :to="{ name: item.to }" exact>
              <v-list-item-content>
                <v-list-item-title class="side__panel-menus--items">{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="hasEvents" link :to="redirectToEvent">
              <v-list-item-content>
                <v-list-item-title class="side__panel-menus--items">{{ $t('event_editor') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="changeUserTimezone">
              <v-list-item-content>
                <v-list-item-title class="side__panel-menus--items">{{ $t('my_timezone') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="isAdmin" link :to="{ name: 'adminUsersList' }"
                         :class="{'v-item--active v-list-item--active':$route.path.includes('/admin/user')}"
            >
              <v-list-item-content>
                <v-list-item-title class="side__panel-menus--items">{{ $t('users_management') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="isAdmin" link :to="{ name: 'messageList' }"
                         :class="{'v-item--active v-list-item--active':$route.path.includes('/admin/message')}"
            >
              <v-list-item-content>
                <v-list-item-title class="side__panel-menus--items">What's News</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="isAdmin" link :to="{ name: 'upcomingUserEvents' }"
                         :class="{'v-item--active v-list-item--active':$route.path.includes('/admin/event-details/')}"
            >
              <v-list-item-content>
                <v-list-item-title class="side__panel-menus--items">{{ $t('upcoming_user_events') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="isAdmin" link :to="{ name: 'blogManagement' }"
            :class="{'v-item--active v-list-item--active':$route.path.includes('/admin/blog')}"
            >
              <v-list-item-content>
                <v-list-item-title class="side__panel-menus--items">{{ $t('blogs_management') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="isAdmin" link :to="{ name: 'blogsCategories' }"
            :class="{'v-item--active v-list-item--active':$route.path.includes('/admin/add/blogs-categories')}"
            >
              <v-list-item-content>
                <v-list-item-title class="side__panel-menus--items">{{ $t('blog_categories.blog_categories') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="isAdmin" link :to="{ name: 'financials' }">
              <v-list-item-content>
                <v-list-item-title class="side__panel-menus--items">{{ $t('financials') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="hasArchiveActivated" link :to="{ name: 'archive_events' }">
              <v-list-item-content>
                <v-list-item-title class="side__panel-menus--items">{{ $t('archive_events') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="isAdmin" link :to="{ name: 'eventGraphicsTemplates' }"
                         :class="{'v-item--active v-list-item--active':$route.path.includes('/admin/add/event-graphics-template')}">
              <v-list-item-content>
                <v-list-item-title class="side__panel-menus--items">{{ $t('event_graphics_template.event_graphics_template') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="isAdmin" link :to="{ name: 'timezoneUpdate' }">
              <v-list-item-content>
                <v-list-item-title class="side__panel-menus--items">{{ $t('timezone_update.timezone_update') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </div>
    <WelcomeModal ref="welcomeModal" />
  </v-col>
</template>

<script>
import WelcomeModal from '~/components/WelcomeModal'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'DashboardMenu',
  components: { WelcomeModal },
  data () {
    return {
      items: [
        // TODO:Once all page will be available need to replace the real URLS
        { title: this.$t('main_dashboard'), to: 'dashboard' },
        // { title: this.$t('my_event_profile'), to: 'welcome' },
        { title: this.$t('my_business_card'), to: 'business-card-detail' },
        { title: this.$t('contact_i_have_made'), to: 'collectedBusinessCards' },
        // { title: this.$t('my_timezone'), to: 'welcome' },
        // { title: this.$t('event_editor'), to: 'events' },
        // { title: this.$t('user'), to: 'users' }
      ]
    }
  },
  computed: {
    ...mapGetters('events', ['dashboard_events']),
    ...mapGetters('auth', ['user']),
    hasEvents () {
      if (this.user && this.user.event_count !== null) {
        return (this.user.event_count ? this.user.event_count : this.dashboard_events.eventsCount) + this.user.event_editor_count
      } else {
        return 0
      }
    },
    isAdmin () {
      return this.user && this.user.is_admin
    },
    hasArchiveActivated (){
      return this.user && this.user.archive_activated
    },
    redirectToEvent(){
      if(this.$route.params.event){
        return { name: 'event.dashboard', params: { event: this.$route.params.event } }
      }
      else {
        return { name: 'events' }
      }
    },
  },
  methods: {
    ...mapActions('events', ['getDashboardEvents']),
    ...mapActions('auth', ['fetchUser']),
    changeUserTimezone () {
      this.$root.$emit('show-timezone-popup', { showTimezone: true, step: 2, openDialog: true })
    },
    welcomeModalClose () {
      this.fetchUser()
      if(this.$route.name == 'dashboard'){
        this.getDashboardEvents()
      }
    }
  },
  created () {
    this.$root.$on('welcome-modal-close', () => {
      this.welcomeModalClose()
    })
  }
}
</script>

<style scoped>

</style>
