<template>
<RaWebrtcModals />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import RaWebrtcModals from '~/components/webrtc/RaWebrtcModals.vue'

export default {
  name: 'Webrtc',
  components: { RaWebrtcModals },
  data () {
    return {
      isReady: false,
    }
  },
  props: {
    eventId: {
      type: Number
    },
  },
  computed: {
    ...mapGetters('auth', ['user','token']),
    ...mapGetters('events', ['singleEvent']),
    ...mapGetters('schedules', ['currentSession']),
    ...mapGetters('webrtc', ['webrtcToken','attendees','rooms','getVideoCallLocalMediaStream','getVideoCallBubbleId','getVideoCallBubbleOwner','getVideoCallUsers']),

    currentSessionId (){
      if(this.currentSession != null){
        return 'networking-' + this.currentSession.id
      } else {
        return ''
      }
    },
  },
  methods: {
    ...mapActions('events', ['getEvent']),
    ...mapActions('webrtc', ['setJoined','setWebrtcToken','setAttendee','setRoomAttendee','setVideoCallUser','setAttendeeStatus','removeVideoCallUser','removeUser','removeAttendeeFromRoom']),

    closeWebrtc(){
      this.$socket.client.close() 
    },

    setupWebrtc(){
      this.$socket.client.nsp = '/' + this.singleEvent.id
      this.$socket.client.open()

      // External WebRTC Message Listeners
      this.$socket.client.on('answer-made', (data) => {
        this.$root.$emit('webrtcAnswerMade', data)
      })

      this.$socket.client.on('bubble-add-user', (data) => {
        this.$root.$emit('webrtcBubbleAddUser', data)
      })

      this.$socket.client.on('bubbles-list', (bubblesList) => {
        this.$root.$emit('webrtcBubblesList', bubblesList)
      })

      this.$socket.client.on('call-accepted', (receiver, socketId) => {
        this.$root.$emit('webrtcCallAccepted', receiver, socketId)
      })

      this.$socket.client.on('call-cancelled', (caller) => {
        this.$root.$emit('webrtcCallCancelled', caller)
      })

      this.$socket.client.on('call-declined', (receiver) => {
        this.$root.$emit('webrtcCallDeclined', receiver)
      })

      this.$socket.client.on('call-requested', (data) => {
        this.$root.$emit('webrtcIncomingCallRequest', data)
      })

      this.$socket.client.on('check-user-online-event-result', (userId, userOnline) => {
        this.$root.$emit('webrtcCheckUserOnlineEventResult', userId, userOnline)
      })

      this.$socket.client.on('check-user-online-room-result', (room, userId, userOnline) => {
        this.$root.$emit('webrtcCheckUserOnlineRoomResult', room, userId, userOnline)
      })      
      
      this.$socket.client.on('connected', () => {
        this.joinServer()
      })

      this.$socket.client.on('general-event-message', (payload) => {
        this.$root.$emit('eventMessage-' + payload.command, payload)
      })

      this.$socket.client.on('general-room-message', (payload) => {
        let command = 'roomMessage-' + payload.command
        this.$root.$emit(command, payload)
      })

      this.$socket.client.on('general-user-message', (payload) => {
        let command = 'userMessage-' + payload.command
        this.$root.$emit(command, payload)
      })

      this.$socket.client.on('offer-made', (data) => {
        this.$root.$emit('webrtcOfferMade', data)
      })

      this.$socket.client.on('new-ice-candidate', (payload) => {
        this.$root.$emit('webrtcNewICECandidate', payload)
      })

      this.$socket.client.on('room-add-user', (userObj) => {
        this.setAttendee(userObj)
        this.setRoomAttendee(userObj)
        this.$root.$emit('webrtcRoomUserUpdate', userObj.room)
      })

      this.$socket.client.on('room-leave-room', (roomId, userId) => {
        let userObj = {}
        userObj.user = userId
        userObj.room = roomId
        if(this.getVideoCallBubbleId == roomId){
          this.removeVideoCallUser(userId)
        }
        this.removeAttendeeFromRoom(userObj)
        this.$root.$emit('webrtcRoomUserUpdate', roomId)
      })

      this.$socket.client.on('room-user-list', (room, users) => {
        users = JSON.parse(users)
        if(users.length > 0){
          for(let userId of users) {
            let userObj = {}
            userObj.user = userId
            userObj.room = room

            this.setAttendee(userObj)
            this.setRoomAttendee(userObj)
          }
          this.$root.$emit('webrtcRoomUserUpdate', room)
        }
      })

      this.$socket.client.on('room-attendee-list', (room, users) => {
        let attendees = JSON.parse(users)
        this.$root.$emit('webrtcAttendeeUpdate', attendees)
      })

      this.$socket.client.on('room-attendee-count', (id, room, userCount) => {
        this.$root.$emit('webrtcAttendeeCountUpdate', id, room, userCount)
      })

      this.$socket.client.on('set-user-status', (data) => {
        this.webrtcSetUserStatus(data)
      })

      this.$socket.client.on('token', (token) => {
        this.setJoined(true)
        this.setWebrtcToken(token)
        this.$root.$emit('webrtcTokenAlreadySet', true)
      })

      this.$socket.client.on('user-disconnected', (userObj) => {
        this.$root.$emit('userHardDisconnected', userObj.user)
        let userRooms = []
        for (const room in this.rooms) {
          if(this.rooms[room].includes(userObj.user)){
            userObj.room=room
            this.removeAttendeeFromRoom(userObj)
            this.$root.$emit('webrtcRoomUserUpdate', room)
          }
          if(userObj.user in this.rooms[room]){
            userRooms.push(room)
            this.$root.$emit('webrtcRoomUserUpdate', room)
          }
        }
        this.removeUser(userObj)
      })
    },

    // WebRTC Listener Functions

    checkUserOnlineEvent(userId) {
      this.$socket.client.emit('check-user-online-event', {
        id: userId,
        token: this.webrtcToken
      })      
    },

    checkUserOnlineRoom(room, userId) {
      this.$socket.client.emit('check-user-online-room', {
        room: room,
        id: userId,
        token: this.webrtcToken
      })      
    },    

    generalJoinRoom(id) {
      this.$socket.client.emit('general-join-room', {
        room: id,
        token: this.webrtcToken
      })
    },

    generalLeaveRoom(id) {
      this.$socket.client.emit('general-leave-room', {
        room: id,
        token: this.webrtcToken
      })
    },

    generalSendToEvent(data) {
      this.$socket.client.emit('general-event-send', {
        payload: data,
        token: this.webrtcToken
      })
    },

    generalSendToRoom(data) {
      this.$socket.client.emit('general-room-send', {
        payload: data,
        token: this.webrtcToken
      })
    },

    generalSendToUser(data) {
      this.$socket.client.emit('general-user-send', {
        payload: data,
        token: this.webrtcToken
      })
    },

    getBubblesList() {
      this.$socket.client.emit('get-bubbles-list', {
        session: this.currentSessionId,
        token: this.webrtcToken
      })
    },

    getRoomList(room) {
      this.$socket.client.emit('get-room-list', {
        token: this.webrtcToken,
        room: room
      })
    },

    getRoomCount(data) {
      this.$socket.client.emit('get-room-count', {
        token: this.webrtcToken,
        id: data.id,
        room: data.room
      })
    },

    joinRoom(id) {
      this.$socket.client.emit('join-room', {
        room: id,
        token: this.webrtcToken
      })
    },

    joinRoomSilent(id) {
      this.$socket.client.emit('join-room-silent', {
        room: id,
        token: this.webrtcToken
      })
    },

    joinServer() {
      this.$socket.client.emit('join', {
        id: this.user.id,
        token: this.token
      })
    },

    receiverJoinBubble(){
      this.$socket.client.emit('join-bubble', {
        bubbleId: this.getVideoCallBubbleId,
        broadcast: 1,
        sessionId: this.currentSessionId,
        token: this.webrtcToken
      })
    },

    sendAcceptCallRequest(senderId) {
      this.$socket.client.emit('accept-call', {
        sender: senderId,
        receiver: this.user.id,
        token: this.webrtcToken
      })
    },

    sendCallRequest(callType, userId, icebreakerNote, callId) {
      this.$socket.client.emit('request-call', {
        callType: callType,
        caller: this.user.id,
        receiver: userId,
        note: icebreakerNote,
        callId: callId,
        token: this.webrtcToken
      })
    },

    sendCancelCallRequest(userId) {
      this.$socket.client.emit('cancel-call', {
        sender: this.user.id,
        receiver: userId,
        token: this.webrtcToken
      })
    },

    sendChatRequest(chatData){
      this.$socket.client.emit('decline-call', {
        sender: senderId,
        receiver: this.user.id,
        token: this.webrtcToken
      })
    },

    sendDeclineCallRequest(senderId) {
      this.$socket.client.emit('decline-call', {
        sender: senderId,
        receiver: this.user.id,
        token: this.webrtcToken
      })
    },

    setUserStatus(status) {
      this.$socket.client.emit('set-user-status', {
        userId: this.user.id,
        status: status,
        token: this.webrtcToken
      })
    },

    webrtcSetUserStatus(data) {
      this.setAttendeeStatus(data)
    },

    roomLeaveRoom (roomId, userId)  {
        let userObj = {}
        userObj.user = userId
        userObj.room = roomId
        this.removeAttendeeFromRoom(userObj)
        this.$root.$emit('webrtcRoomUserUpdate', roomId)
    }

  },
  mounted () {
    // Internal Vue Message Listeners
    this.$root.$on('webrtcAcceptCallRequest', (senderId, socketId) => {
      this.sendAcceptCallRequest(senderId)
      this.setVideoCallUser({userId: senderId, socketId: socketId})
    }),

    this.$root.$on('webrtcCallRequest', (callType, userId, icebreakerNote, callId) => {
      this.sendCallRequest(callType, userId, icebreakerNote, callId)
    }),

    this.$root.$on('webrtcCancelCallRequest', (userId) => {
      this.sendCancelCallRequest(userId)
    }),

    this.$root.$on('webrtcChatConversationInitiated', (chatData) => {
      this.generalSendToUser(chatData)
    }),

    this.$root.$on('webrtcCheckUserOnlineEvent', (userId) => {
      this.checkUserOnlineEvent(userId)
    }),   
    
    this.$root.$on('webrtcCheckUserOnlineRoom', (room, userId) => {
      this.checkUserOnlineRoom(room, userId)
    }),        

    this.$root.$on('webrtcDeclineCallRequest', (senderId) => {
      this.sendDeclineCallRequest(senderId)
    }),

    this.$root.$on('webrtcGeneralJoinRoom', (id) => {
      this.generalJoinRoom(id)
    }),

    this.$root.$on('webrtcGeneralLeaveRoom', (id) => {
      this.generalLeaveRoom(id)
    }),

    this.$root.$on('eventMessage-userDisconnected', (data) => {
      this.roomLeaveRoom(data.roomId,data.userId)
    }),

    this.$root.$on('webrtcGeneralSendToEvent', (data) => {
      this.generalSendToEvent(data)
    }),

    this.$root.$on('webrtcGeneralSendToRoom', (data) => {
      this.generalSendToRoom(data)
    }),

    this.$root.$on('webrtcGeneralSendToUser', (data) => {
      this.generalSendToUser(data)
    }),

    this.$root.$on('webrtcGetBubblesList', () => {
      this.getBubblesList()
    }),

    this.$root.$on('webrtcJoinRoom', (id) => {
      this.joinRoom(id)
    }),

    this.$root.$on('webrtcJoinRoomSilent', (id) => {
      this.joinRoomSilent(id)
    }),

    this.$root.$on('webrtcGetRoomList', (data) => {
      this.getRoomList(data)
    }),

    this.$root.$on('webrtcGetRoomCount', (data) => {
      this.getRoomCount(data)
    }),

    this.$root.$on('webrtcUserNetworkStatusChange', (userId, value) => {
      if(value == false){
        this.$socket.client.emit('leave-room', {
          user: userId,
          room: this.currentSessionId
        })
      } else {
        this.joinRoom(this.currentSessionId)
      }
    }),

    this.$root.$on('webrtcSetUserStatus', (status) => {
      this.setUserStatus(status)
    })
  },
  watch: {
    singleEvent: {
      immediate: true,
      handler(newSingleEvent, oldSingleEvent) {
        if(newSingleEvent.id !== undefined && !this.isReady){
          this.isReady = true
          this.setupWebrtc()
        }
      },
    },
  },
  beforeDestroy() {
    this.$root.$off("eventMessage-userDisconnected")
    this.closeWebrtc()
  },
}
</script>


<style>

</style>
