<template>
  <div class="profile-uploader">
    <div class="profile-uploader__drop-area"
         :class="{ 'profile-uploader__drop-area--hover': dragover || hover }"
         @drop.prevent="onDrop($event)"
         @mouseover="onHover(true)"
         @mouseleave="onHover(false)"
         @dragover.prevent="onDragOver(true)"
         @dragenter.prevent="onDragOver(true)"
         @dragleave.prevent="onDragOver(false)"
         @click="triggerFileUpload"
    >
      <input id="media-upload"
             ref="fileInput"
             type="file"
             class="d-none"
             :accept="acceptMimeTypes"
             @change="fileInput"
      >
      <div class="profile-uploader__drop-text">
        <p class="profile-uploader__drop-text--line1">
          Drag single image here
        </p>
        <p class="profile-uploader__drop-text--line2">
          or
        </p>
        <p class="profile-uploader__drop-text--line3">
          Click here to browse your computer
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import find from 'lodash/find'
import mediaTypes from '~/plugins/config/mediaTypes'

export default {
  name: 'RaUserProfileUpload',
  data () {
    return {
      dragover: false,
      hover: false,
      typesAllowed: ['image']
    }
  },
  computed: {
    acceptMimeTypes () {
      // Images only
      return this.typesAllowed.map((type) => mediaTypes[type].types).flat()
    }
  },
  methods: {
    /**
     *     File upload processing
     */
    onDrop (e) {
      // File dropped on upload page
      this.dragover = false
      if (e.dataTransfer.files.length > 1) {
        this.$store.dispatch('notifications/addNotification', {
          type: 'warning',
          message: 'Only one file can be uploaded at a time..'
        },
        { root: true })
        return
      }
      this.validateFile(e.dataTransfer.files[0])
    },
    triggerFileUpload () {
      // Clicking on the upload area triggers a manual file selection
      this.$refs.fileInput.click()
    },
    fileInput (e) {
      // File input from dialog
      this.validateFile(e.target.files[0])
    },
    validateFile (file) {
      // Validate type of file for this location, and size for type
      if (this.isValidType(file.type) && this.isValidSize(file)) {
        this.uploadFile(file)
      }
    },
    isValidType (mimeType) {
      if (!this.acceptMimeTypes.includes(mimeType)) {
        this.$store.dispatch('notifications/addNotification', {
          type: 'warning',
          message: `Not a valid file type : (${mimeType})`
        },
        { root: true })
        return false
      }
      return true
    },
    isValidSize (file) {
      const maxSize = find(mediaTypes, { types: [file.type] }).sizeKb
      if (maxSize !== 0 && maxSize * 1024 < file.size) {
        this.$store.dispatch('notifications/addNotification', {
          type: 'warning',
          message: `File too large, maximum size allowed (${maxSize}Kb)`
        }, { root: true })
        return false
      }
      return true
    },
    uploadFile (file) {
      this.$emit('uploaded-media-save', {
        file: file
      })
    },
    onHover (state) {
      this.hover = state
    },
    onDragOver (state) {
      this.dragover = state
    }
  }
}
</script>
<style lang="scss" scoped>
.profile-uploader {
  &__drop-area {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 1.5rem 0 2rem;
    &--hover {
      opacity: 0.5;
    }
  }
  &__drop-text {
    margin-bottom: 0.5rem !important;
    font-size: 1.125rem;
    text-align: center;
  }
}
</style>
