import store from '~/store'

export default (to, from, next) => {
  if (window.location.href.indexOf("/admin/") > -1) {
    if (store.getters['auth/user'].is_admin !== 1) {
      next({ name: 'dashboard' })
    } else {
      next()
    }
  } else {
    next()
  }
}
