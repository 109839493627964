import Vue from 'vue'
import Card from './Card'
import Child from './Child'
import Button from './Button'
import Checkbox from './Checkbox'
import Schedule from './Schedule'
import { HasError, AlertError, AlertSuccess } from 'vform'
import DatetimePicker from 'vuetify-datetime-picker'
import VueQrcodeReader from 'vue-qrcode-reader'
import RaGlobalSnackbar from '~/components/RaGlobalSnackbar.vue'
import RaUpgradePlan from '~/components/RaUpgradePlan.vue'
// import 'material-design-icons-iconfont/dist/material-design-icons.css'
// Components that are registered globaly.
// Vue.component('RaGlobalSnackbar', RaGlobalSnackbar)
// Vue.component('RaUpgradePlan', RaUpgradePlan)
const globalComponents = [
  Card,
  Child,
  Button,
  Schedule,
  Checkbox,
  HasError,
  AlertError,
  AlertSuccess,
  RaGlobalSnackbar,
  RaUpgradePlan
];

for (const Component of globalComponents) {
  Vue.component(Component.name, Component)
}

//   globalComponents.forEach(Component => {
//   Vue.component(Component.name, Component)
// })
Vue.use(DatetimePicker)
Vue.use(VueQrcodeReader)
